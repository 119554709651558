<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 96px; height: 96px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 96 96"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <path class="text-blue-500" d="M29.07,43.06a8.5,8.5,0,1,1,8.5-8.5,8.36,8.36,0,0,1-.44,2.71,1,1,0,1,1-1.89-.64,6.64,6.64,0,0,0,.33-2.07A6.54,6.54,0,1,0,29.84,41a1,1,0,1,1,.27,2A7.27,7.27,0,0,1,29.07,43.06Z"></path>
  <path d="M33.85,47.12a.5.5,0,0,1-.39-.18L29.33,42a.49.49,0,0,1,.06-.7.51.51,0,0,1,.71.06l4.13,4.92a.51.51,0,0,1-.06.71A.51.51,0,0,1,33.85,47.12Z"></path>
  <path d="M41.46,56.68a1,1,0,0,1-.77-.35L25.26,37.94a5,5,0,0,1,4.26-8.19,4.91,4.91,0,0,1,3.4,1.77L47.71,49.14a1,1,0,0,1-1.53,1.28L31.39,32.8a3,3,0,0,0-2-1.06,3,3,0,0,0-2.18.69,3,3,0,0,0-.37,4.23L42.22,55a1,1,0,0,1-.12,1.41A1,1,0,0,1,41.46,56.68Z"></path>
  <path d="M46.94,50.77a1,1,0,0,1-.76-.35L43,46.59a5,5,0,0,1,4.26-8.2,5,5,0,0,1,3.4,1.77l4.5,5.36a1,1,0,0,1-1.53,1.29l-4.5-5.37A3,3,0,1,0,44.5,45.3l3.21,3.83a1,1,0,0,1-.12,1.41A1,1,0,0,1,46.94,50.77Z"></path>
  <path d="M54.36,47.16a1,1,0,0,1-.77-.35L50.38,43a5,5,0,0,1,.61-7,5,5,0,0,1,7,.62l1.28,1.53a1,1,0,1,1-1.53,1.29l-1.28-1.54a3,3,0,0,0-5.29,1.67,3,3,0,0,0,.69,2.19l3.21,3.83A1,1,0,0,1,55,46.93,1,1,0,0,1,54.36,47.16Z"></path>
  <path d="M58.56,39.72a1,1,0,0,1-.77-.35,5,5,0,0,1-1.15-3.65,5,5,0,0,1,5.41-4.55,5,5,0,0,1,3.4,1.77,1,1,0,1,1-1.53,1.28,3,3,0,0,0-4.23-.37,3.05,3.05,0,0,0-1.06,2,3,3,0,0,0,.69,2.18,1,1,0,0,1-.12,1.41A1,1,0,0,1,58.56,39.72Z"></path>
  <path d="M33.85,47.12a.5.5,0,0,1-.39-.18L29.33,42a.49.49,0,0,1,.06-.7.51.51,0,0,1,.71.06l4.13,4.92a.51.51,0,0,1-.06.71A.51.51,0,0,1,33.85,47.12Z"></path>
  <path d="M61.13,42.79a1,1,0,0,1-.77-.36L57.15,38.6a1,1,0,0,1,1.53-1.29l3.21,3.84a1,1,0,0,1-.76,1.64Z"></path>
  <path d="M56.48,74.2c-.66,0-1.33,0-2-.08l-19-1.67a3,3,0,0,1-2.73-3.27,7,7,0,0,1,7.58-6.34l6.31.55-7.17-8.54A1,1,0,1,1,41,53.56l7.17,8.55a2,2,0,0,1,.24,2.2,2,2,0,0,1-1.94,1.07l-6.32-.55a5,5,0,0,0-5.41,4.53,1,1,0,0,0,.23.74,1,1,0,0,0,.68.36l19,1.66a20.28,20.28,0,0,0,14.92-4.7,17,17,0,0,0,2.1-23.94L64,34.28A1,1,0,0,1,65.5,33l7.72,9.2A19,19,0,0,1,70.87,69,22.29,22.29,0,0,1,56.48,74.2Z"></path>
</svg>