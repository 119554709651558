<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path d="M8 9H16"/>
    <path d="M8 13H12"/>
    <path
      d="M17 19L20.2445 20.929C20.3204 20.9741 20.4069 20.9984 20.4952 20.9992C20.5835 21.0001 20.6704 20.9775 20.7472 20.9338C20.824 20.8902 20.8878 20.827 20.9322 20.7506C20.9766 20.6743 21 20.5876 21 20.4993V6C21 5.20435 20.6839 4.44129 20.1213 3.87868C19.5587 3.31607 18.7956 3 18 3H6C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V16C3 16.7956 3.31607 17.5587 3.87868 18.1213C4.44129 18.6839 5.20435 19 6 19H17Z"/>
  </g>
</svg>
