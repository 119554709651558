<script>
  import {createEventDispatcher} from "svelte";
  import IconChevronLeft from "../Icons/IconChevronLeft.svelte";
  import IconCross from "../Icons/IconCross.svelte";

  export let title;
  export let back = false;

  const dispatch = createEventDispatcher();

  const methods = {
    emit: {
      back: () => dispatch("back"),
      close: () => dispatch("close"),
    },
  };
</script>

<style global>
    .context-menu-header {
        position: sticky;
        top: 0;
        z-index: 100;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        padding: 0.25rem 0.75rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        width: 100%;
        height: auto;

        @apply border-b border-gray-300 bg-white;
    }

    .context-menu-header h3 {
        margin: 0;

        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 500;
        text-align: center;

        @apply text-gray-500;
    }

    .context-menu-header svg {
        cursor: pointer;

        @apply text-gray-500;
    }

    .context-menu-header svg:hover {
        @apply text-gray-700;
    }
</style>

<div class="context-menu-header">
  {#if back}
    <IconChevronLeft on:click={methods.emit.back} />
  {/if}
  <h3>{title}</h3>
  <IconCross on:click={methods.emit.close} />
</div>