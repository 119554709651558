<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path
      d="M12.419 12.4L14.286 12.026C14.556 11.9729 14.8043 11.8414 15 11.648L20.452 6.2C20.803 5.84878 21.0002 5.37255 21.0002 4.876C21.0002 4.37945 20.803 3.90322 20.452 3.552C20.1008 3.201 19.6246 3.00382 19.128 3.00382C18.6315 3.00382 18.1552 3.201 17.804 3.552L12.352 9C12.1571 9.19518 12.0242 9.44357 11.97 9.714L11.6 11.581C11.5786 11.6932 11.5849 11.809 11.6185 11.9181C11.652 12.0273 11.7118 12.1267 11.7926 12.2074C11.8734 12.2882 11.9727 12.348 12.0819 12.3816C12.1911 12.4151 12.3068 12.4215 12.419 12.4Z"/>
    <path d="M9.36499 14.424C12.489 17.548 16.765 18.34 18.91 16.192C20.718 14.384 20.437 11.072 18.442 8.206"/>
    <path
      d="M15.84 5.51201C12.083 2.62201 7.38501 2.26101 4.76801 4.87801C1.83901 7.80701 2.63101 13.347 6.53601 17.252C8.24585 18.99 10.3594 20.2777 12.688 21"/>
  </g>
</svg>
