<script>
  import {createEventDispatcher} from "svelte";

  export let title;
  export let showBackButton = false;

  const dispatch = createEventDispatcher();

  function handleBack() {
    dispatch('back');
  }

  function handleClose() {
    dispatch('close');
  }
</script>

<style global>
    .context-menu-header {
        position: sticky;
        top: 0;
        z-index: 100;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        padding: 0.25rem 0.75rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        width: 100%;
        height: auto;

        @apply border-b border-gray-300 bg-white;
    }

    .context-menu-header h3 {
        margin: 0;

        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 500;
        text-align: center;

        @apply text-gray-500;
    }

    .context-menu-header svg {
        cursor: pointer;

        @apply text-gray-500;
    }

    .context-menu-header svg:hover {
        @apply text-gray-700;
    }
</style>

<div class="context-menu-header">
  <div class="flex items-center">
    {#if showBackButton}
      <button 
        on:click={handleBack}
        class="mr-2 p-1 hover:bg-gray-200 rounded-full transition-colors duration-150"
      >
        <svg class="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
        </svg>
      </button>
    {/if}
    <h3>{title}</h3>
  </div>
  <button 
    on:click={handleClose}
    class="p-1 hover:bg-gray-200 rounded-full transition-colors duration-150"
  >
    <svg class="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
    </svg>
  </button>
</div>