<!-- Notes -->
<!-- mode === 'cls' is an acronym for 'customer live session' (RA, Dec 2022)-->
<!-- Make sure to test changes on mobile devices, especially Android devices. Sam's Android tends to find bugs that even Miguel's Android does not. (RA, Dec 2022) -->

<script>
  // ----------------
  // - Dependencies -
  // ----------------

  import ActionableAnnotation from "./ActionableAnnotation.svelte";
  import ActionGroupAnnotation from "./ActionGroupAnnotation.svelte";
  import { capitalizeFirstLetter, ggID, pascalCase, throttle } from './utils/helper.js';
	import ChooseNewPageTypeModal from "./ChooseNewPageTypeModal.svelte";
  import ContextMenu from "./ContextMenu.svelte";
  import Drawing from "./Drawing.svelte";
  import DrawingCanvas from "./DrawingCanvas.svelte";
  import FloatingEditorDialog from "./FloatingEditorDialog.svelte";
  import { fly } from "svelte/transition";
  import GestureFingerTouch from "./Icons/Gestures/GestureFingerTouch.svelte";
  import IconZoomIn from "./Icons/IconZoomIn.svelte";
  import IconZoomOut from "./Icons/IconZoomOut.svelte";
  import Image from "./Image.svelte";
  import { inview } from 'svelte-inview';
  import {isMode} from "./utils/helper";
  import { onMount, tick } from "svelte";
  import PageNumber from "./Page/PageNumber.svelte";  
  import PDFPage from "./PDFPage.svelte";
  import prepareAssets, { fetchFont, getAsset } from "./utils/prepareAssets.js";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL
  } from "./utils/asyncReader.js";
  import RejectParticipantsListModal from './RejectParticipantsListModal.svelte';
  import { save } from "./utils/PDF.js";
	import SimpleModal from "./SimpleModal.svelte";
  import Tailwind from "./Tailwind.svelte";
  import Text from "./Text.svelte";
	import TwoChoicesModal from "./TwoChoicesModal.svelte";

  // -------------
  // - Constants -
  // -------------

  const genID = ggID();
  const zoomStepFactor = 0.5;

  // -----------------------------------------------------
  // - Variables that have to be bound to window to work -
  // -----------------------------------------------------

  window.ES_panning_object = false;
  window.ES_zoomLevel = 1;

  // ----------------------
  // - Reactive Variables -
  // ----------------------

  let addingCustomerSignatureOrInitials = 'signature';
  let addingDrawing = false;
  let actionableAnnotationLastClickedAt = 0;
  let allObjects = [];
  let annotationsOverride = '';
  let chooseNewPageTypeModalVisible = false;
  let clickedXPosition = 0;
  let clickedYPosition = 0;
  let currentFont = "Helvetica";
  // INFO: Using email as the participant identifier since notarization preorders
  //       don't currently assign ids to signers before submission.
  let currentParticipants = [];
  let currentParticipantEmail = null;
  let isRemoteParticipantSinger = false;
  let debug = false;
  let debugAnnotations = false;
  let debugAddDraggables = false;
  let debugAnonParticipants = false;
  let debugAutoPlaceElements = false;
  let debugCurrentParticipant = false;
  let debugCurrentParticipants = false;
  let debugNotaryData = false;
  let debugParticipants = false;
  let debugShowClickDots = false;
  let debugShowSaveButton = false;
  let debugSignatures = false;
  let debugShowInteractionInstructions = false;
  let defaultInitialsWidth = 180; // changed to be the same as defaultSignatureWidth
  let defaultSignatureWidth = 180;
  let docPanningEnabled = false;
  let documentId = null;
  let editingText = false;
  let editorToolsVisible = false;
  let editorToolsXPosition = 0;
  let editorToolsYPosition = 0;
  let firstDocumentLoaded = false;
  let flattenEdits = false;
  let greenSaveSvgPopoverText = "Save";
  let isMobile = false;
  let justAddedPages = false;
  let lastMouseX = null;
  let lastMouseY = null;
  let lastTapTimestamp = 0;
  let loadingPdf = false;
  let lockEditing = false;
  let mouseIsDown = false;
  let notaryData = {city: null, state: null, fname: null, lname: null, cert_exp: null};
  let notarySignatureUrl = "";
  let notaryStampUrl = "";
  let originalPagesRemaining = [];
  let pages = [];
  let pagesScale = [];
  let pageTagStyle = 'align-items: center';
  let participantSignatures = [];
  let oneTimeSignature = null;
  let participants = [];
  let pdfFile;
  let pdfName = "";
  let placeObjectAfterWait = false;
  let pointerDownPos = { x: 0, y: 0 };
  let pointerMoveThreshold = 4; // in pixels (Rob switched this off of '4' for right now to address an Android issue)
  let rotationDirection = 1;
  let rotationKeys = []; // Useful to trigger a re-render when the rotation should change
  let saving = false;
  let selectedPageIndex = 0;
  let showGreenSaveSvg = false;
  let showInteractionInstructions = false;
  let showPanningInstructions = false;
  let simpleModalButtonText = '';
  let simpleModalMessage = '';
  let simpleModalTitle = '';
  let simpleModalVisible = false;
  let tapoutLastTick = false;
  let twoChoicesModalCtaAction = () => {};
  let twoChoicesModalCtaText = '';
  let twoChoicesModalMessage = '';
  let twoChoicesModalOtherBtnText = '';
  let twoChoicesModalTitle = '';
  let twoChoicesModalVisible = false;
  let urlParams = {};
  let userShownIncompleteActionableAnnotationsWarning = false;
  let shouldLockDocumentEditing = false;
  let canUseAllNotaryTools = false;
  let userHasFullEditingPermissions = false;
  let showRejectParticipantsListModal = false;

  // ----------------------------
  // - Functions (Alphabetized) -
  // ----------------------------

  window._debug = function(command) {
    if (command === false || command === null || command === undefined) {
      handleDebugMode({
        detail: {debug: false, command: '',}
      });
      return 'Debug mode disabled';
    }

    handleDebugMode({
      detail: {debug: true, command: command,}
    });

    return 'Debug mode enabled';
  }

  // ** Note: If we change the structure of this object, we also need to change the structure of NotaryLive's FoxitAnnotationsConverter (RA, Oct 2024)
  // --
  function addActionableAnnotation(options) {
    const object = {
      actionPerformed: false,
      annotationType: options.type,
      associatedObjectId: null,
      frozen: options.frozen || false,
      height: options.height || 60,
      hidden: options.hidden || false,
      id: genID(),
      participant: options.participant,
      participantSignatureData: getParticipantSignatureDataByEmail(options.participant.email),
      type: 'actionable-annotation',
      width: options.width || 190,
      x: options.x,
      y: options.y,
    };

    emitDocumentModified({
      action: 'add',
      target: 'actionable-annotation',
      objectId: object.id,
      pageIndex: options.pageIndex,
    });

    allObjects = allObjects.map((objects, pIndex) => {
      if (pIndex === options.pageIndex) {
        return [...objects, object];
      } else {
        return objects;
      }
    });
  }

  function addActionableAnnotationFromPageObject(
    creationParams, pageObject
  ) {
    if (pageObject.type === 'action-group-annotation') {
      return addActionGroupAnnotation({
        ...creationParams,
        ...pageObject,
        hidden: shouldHideAnnotation(pageObject),
      });
    }

    return addActionableAnnotation({
      ...creationParams,
      id: pageObject.id,
      type: pageObject.annotationType,
      participant: pageObject.participant,
      hidden: shouldHideAnnotation(pageObject),
      height: pageObject.height,
      width: pageObject.width,
      participantSignatureData: pageObject.participantSignatureData,
    });
  }

  // ** Note: If we change the structure of this object, we also need to change the structure of NotaryLive's FoxitAnnotationsConverter (RA, Oct 2024)
  // --
  function addActionGroupAnnotation(options) {
    if (isMode('cls', 'notary')) {
      options.frozen = true;
    }

    let object = {
      actionGroupType: options.actionGroupType || options.type, // weird, should fix it (RA, Dec 2024)
      actionElements: setActionElementsForNewActionGroupAnnotation(
        options
      ),
      actionPerformed: false,
      // annotationType: options.type, // remove this? (RA, Dec 2024)
      associatedObjectId: null,
      elementSize: options.elementSize || 25,
      frozen: options.frozen || isMode('ron_order') || false,
      height: 30,
      hidden: options.hidden || false,
      id: genID(),
      participant: options.participant,
      type: 'action-group-annotation',
      width: 130,
      x: options.x,
      y: options.y,
    };

    if (options.filledElements) {
      object.filledElements = options.filledElements;
    } else {
      object.filledElements = [];
    }

    emitDocumentModified({
      action: 'add',
      target: 'action-group-annotation',
      objectId: object.id,
      pageIndex: options.pageIndex,
    });

    allObjects = allObjects.map((objects, pIndex) => {
      if (pIndex === options.pageIndex) {
        return [...objects, object];
      } else {
        return objects;
      }
    });
  }

  function addDebugAnnotations() {
    let annotations = [
      {
        text: 'Jonathan Rice Sign',
        annotationType: 'signature',
        x: 50,
        y: 250,
      },
      {
        text: 'Mary Bartels Sign',
        annotationType: 'signature',
        x: 325,
        y: 250,
      },
      {
        text: 'Jonathan Rice Initial',
        annotationType: 'initials',
        x: 50,
        y: 450,
      },
      {
        text: 'Mary Bartels Initial',
        annotationType: 'initials',
        x: 325,
        y: 450,
      },
    ];

    // Add debug annotations to every page
    for (let i = 0; i < pages.length; i++) {
      for (let annotation of annotations) {
        addTextField(annotation.text, {
          pageIndex: i,
          isAnnotation: true,
          isFocused: false,
          subtype: null,
          disableEditing: true,
          lockedInPlace: true,
          createdFromContextMenu: false,
          annotationType: annotation.annotationType,
          x: annotation.x,
          y: annotation.y,
        });
      }
    }
  }

  function addDebugAnonParticipants() {
    participants = [
      {
        email: 'jonathan@example.com',
        participant_type: 'signer',
      },
      {
        email: 'mary@example.com',
        participant_type: 'signer',
      },
    ];

    participantSignatures = [
      {
        email: 'jonathan@example.com',
        signature: './customer-signature-01.png',
        initials: './customer-initials-01.png',
      },
      {
        email: 'mary@example.com',
        signature: './customer-signature-02.png',
        initials: './customer-initials-02.png',
      },
    ];
  }

  function addDebugDot(x, y, color = 'red') {
    if (!debugShowClickDots) {
      return;
    }

    let element = document.createElement('div');
    element.style.position = 'absolute';
    element.style.left = x + 'px';
    element.style.top = y + 'px';
    element.style.width = '10px';
    element.style.height = '10px';
    element.style.backgroundColor = color;
    element.style.borderRadius = '50%';
    element.style.zIndex = 1000;
    element.style.transform = 'translate(-50%, -50%)';

    let page = document.getElementById('page-' + selectedPageIndex);

    page.appendChild(element);

    let fadeEffect = setInterval(function () {
      if (!element.style.opacity) {
        element.style.opacity = '1';
      }
      if (element.style.opacity > 0) {
        element.style.opacity -= 0.05;
      } else {
        clearInterval(fadeEffect);
        page.removeChild(element);
      }
    }, 50);
  }

  async function addDebugDraggables(pages) {
    if (!debugAddDraggables) {
      return;
    }

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const pdfPage = await pages[i];

      const positions = {
        annotation: debugDraggablePositioning(2, 1, page, pdfPage),
        text: {
          topLeft: debugDraggablePositioning(2, 4, page, pdfPage),
          topRight: debugDraggablePositioning(88, 4, page, pdfPage),
          bottomLeft: debugDraggablePositioning(2, 96, page, pdfPage),
          bottomRight: debugDraggablePositioning(88, 96, page, pdfPage),
        },
        image: debugDraggablePositioning(2, 10, page, pdfPage),
        drawing: debugDraggablePositioning(2, 30, page, pdfPage),
      }

      selectedPageIndex = i;

      addTextField("Annotation", {
        pageIndex: i,
        isAnnotation: true,
        x: positions.annotation.x,
        y: positions.annotation.y,
        subtype: 'debug_text',
      });

      addTextField("Text: TL", {
        pageIndex: i,
        x: positions.text.topLeft.x,
        y: positions.text.topLeft.y,
        subtype: 'debug_text',
      });

      addTextField("Text: TR", {
        pageIndex: i,
        x: positions.text.topRight.x,
        y: positions.text.topRight.y,
        subtype: 'debug_text',
      });

      addTextField("Text: BL", {
        pageIndex: i,
        x: positions.text.bottomLeft.x,
        y: positions.text.bottomLeft.y,
        subtype: 'debug_text',
      });

      addTextField("Text: BR", {
        pageIndex: i,
        x: positions.text.bottomRight.x,
        y: positions.text.bottomRight.y,
        subtype: 'debug_text',
      });

      fetch('150x150.png').then(response => {
        return response.blob();
      }).then(async (blob) => {
        await addImage(blob, {
          imgSrc: '/150x150.png',
          initialWidth: 150,
          pageIndex: i,
          x: positions.image.x,
          y: positions.image.y,
          subtype: 'debug_image',
        });
      }).then(() => {
        selectedPageIndex = 0;
      });

      addDrawing(
        241, 86,
        'M10,71L11,72L14,73L18,74L23,75L25,76L43,76L53,75L72,69L93,58L111,45L116,40L122,29L123,28L126,18L127,14L127,10L127,10L127,10L127,10L122,11L118,14L114,18L108,26L98,41L95,49L96,52L97,53L101,54L107,55L116,54L122,52L132,49L140,46L144,43L148,40L149,39L149,39L149,39L148,40L143,46L140,52L135,60L134,66L135,67L138,67L142,65L162,57L186,44L207,34L224,25L229,23L231,22L231,22L231,22L231,22',
        1, {
        x: positions.drawing.x,
        y: positions.drawing.y,
        subtype: 'debug_drawing',
      });
    }
  }

  function addDebugNotaryData() {
    let now = new Date();

    notaryData = {
      city: 'Belle Glade',
      state: 'Florida',
      fname: 'Jamie',
      lname: 'Thompson',
      cert_exp: now.setFullYear(now.getFullYear() + 1)
    }
  }

  function addDebugSignatures() {
    notarySignatureUrl = './notary-signature.png';
    notaryStampUrl = './notary-stamp.png';

    participants = [
      {
        email: 'jonathan@example.com',
        full_name: 'Jonathan Rice',
        participant_type: 'signer',
      },
    ];

    participantSignatures = [
      {
        email: 'jonathan@example.com',
        signature: './customer-signature-01.png',
        initials: './customer-initials-01.png',
      }
    ];
  }

  function addDebugCurrentParticipant() {
    currentParticipants = [
      {
        email: 'jonathan@example.com',
        full_name: 'Jonathan Rice',
        participant_type: 'signer',
      },
    ];
  }

  function addDebugCurrentParticipants() {
    currentParticipants = [
      {
        email: 'jonathan@example.com',
        full_name: 'Jonathan Rice',
        participant_type: 'signer',
      },
      {
        email: 'mary@example.com',
        full_name: 'Mary Bartels',
        participant_type: 'signer',
      },
    ];
  }

  function addDebugParticipants() {
    participants = [
      {
        email: 'jonathan@example.com',
        full_name: 'Jonathan Rice',
        participant_type: 'signer',
      },
      {
        email: 'mary@example.com',
        full_name: 'Mary Bartels',
        participant_type: 'signer',
      },
    ];

    participantSignatures = [
      {
        email: 'jonathan@example.com',
        signature: './customer-signature-01.png',
        initials: './customer-initials-01.png',
      },
      {
        email: 'mary@example.com',
        signature: './customer-signature-02.png',
        initials: './customer-initials-02.png',
      },
    ];
  }

  // Example:       
  // addDrawing(200, 100, "M30,30 L100,50 L50,70", 0.5);
  // --
  function addDrawing(originWidth, originHeight, path, scale = 1, options = {}) {
    const object = {
      id: genID(),
      originWidth: originWidth,
      originHeight: originHeight,
      path: path,
      scale: scale,
      type: "drawing",
      subtype: options.subtype ? options.subtype : null,
      width: originWidth * scale,
      x: options.x ? options.x : 0,
      y: options.y ? options.y : 0,
    };

    emitDocumentModified({
      action: 'add',
      target: 'drawing',
      objectId: object.id,
      pageIndex: selectedPageIndex,
    });

    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }

  // We don't want to edit 'height' and 'width' for the object this function creates, because it can cutoff the image on the right and bottom sides. (RA, Aug 2022)
  // --
  async function addImage(file, options = {}) {
    if (!['image/jpeg', 'image/png', 'image'].includes(file.type)) {
      return showMessageToUser("Invalid image type. Please upload a JPEG or PNG.", 'invalid-image-type'); // need to convert to a modal or emit to the parent when iframed. (RA, Aug 2022)
    }

    if (!options.x) {options.x = 0;}
    if (!options.y) {options.y = 0;}

    try {
      let url = file.file;

      if (file.type === 'image' && options.imgSrc) {
        url = options.imgSrc;
      }

      if (file.type !== 'image') {
        // get dataURL to prevent canvas from tainted
        url = await readAsDataURL(file);
      }

      const img = await readAsImage(url);

      const object = {
        addingCustomerSignatureOrInitials: options.addingCustomerSignatureOrInitials,
        autoPlaced: !!options.autoPlaced,
        participant_email: file.participant_email,
        disableEditing: !!options.disableEditing,
        file:file,
        detail: file.detail || options.signatureOrInitialsDetails,       // we have plan to remove the detail property from the file object in the future Aneudy A Nov 2024
        height: img.height,
        id: genID(),
        imgName: options.imgName,
        imgSrc: options.imgSrc,
        initialHeight: options.initialHeight,
        initialWidth: options.initialWidth,
        isFocused: !options.autoPlaced,
        pageIndex: options.pageIndex,
        payload: img,
        type: "image",
        subtype: options.subtype ? options.subtype : null,
        width: img.width,
        x: options.x,
        y: options.y,
      };

      selectedPageIndex = object.pageIndex;

      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );

      emitDocumentModified({
        action: 'add',
        target: 'image',
        objectId: object.id,
        pageIndex: selectedPageIndex,
      });

      return object;
    } catch (error) {
      console.log(`Fail to add image.`, error);
      return null;
    }
  }

  // We should figure out the correct abstraction boundaries to distinguish the purporse of 'loadPdf()' and 'addPDF()' (RA, Feb 2023)
  // --
  async function addPDF(file) {
    await testIfPdfCanBeSaved(file).then(async () => {
      await loadPdf(file);
    }).then(() => {
      if (debugAddDraggables) {
        addDebugDraggables(pages);
      }
      if (debugAnnotations) {
        addDebugAnnotations();
      }
    }).catch(e => {
      // The pdf-loading should have aborted here. (RA, July 2022)
    });
  }

  function addPresaveImageToEditor(imgName,pageObject){
    const imgObj = {
      isPresaved: true,
      ...pageObject,
    }
    var imgNameArr = imgName.split('_');
    var dynamicMethodName = imgNameArr.map((name,index) => {
      if (index === 0) {
        return name;
      }
      return capitalizeFirstLetter(name);
    }).join('');

    emitDocumentModified({
      action: 'add',
      target: 'image',
      objectId: imgObj.id,
      pageIndex: selectedPageIndex,
    });

    return draggableObjectFactory[dynamicMethodName](imgObj);
  }

  // ** Note: If we change the structure of this object, we also need to change the structure of NotaryLive's FoxitAnnotationsConverter (RA, Oct 2024)
  // --
  // Example of how to snap fields to place on top of other fields
  // --
  // if (allObjects[0].length > 0) {
  //   let tempObj = allObjects[allObjects.length - 1][0];
  //   options.x = tempObj.x;
  //   options.y = tempObj.y;
  // }
  // --
  function addTextField(
    text = "New Text Field",
    options = {}
  ) {
    options = setTextFieldOptionDefaults(text, options);

    const object = {
      autoPlacedAssociatedObject: options.autoPlacedAssociatedObject,
      annotationType: options.annotationType,
      createdFromContextMenu: !!options.createdFromContextMenu,
      dateSegment: options.dateSegment || null,
      disableEditing: !!options.disableEditing,
      fontFamily: options.fontFamily,
      id: genID(),
      groupId: options.groupId,
      isAnnotation: !!options.isAnnotation,
      isFocused: !options.autoPlaced,
      isGrouped: options.isGrouped,
      lineHeight: 1.4,
      lines: options.lines,
      lockedInPlace: options.lockedInPlace,
      size: options.textSize,
      text: text,
      type: "text",
      subtype: options.subtype ? options.subtype : null,
      width: options.width, // recalculates after editing
      x: !!options.autoPlaced ? options.detail.x : options.x,
      y: !!options.autoPlaced ? options.detail.y : options.y,
    };

    emitDocumentModified({
      action: 'add',
      target: 'text',
      objectId: object.id,
      pageIndex: selectedPageIndex,
    });

    // allObjects starts with an array of pages, then an array of elements on the page (RA, Aug 2023)
    // --
    allObjects = allObjects.map((objects, pIndex) => {
      if (pIndex === options.pageIndex) {
        return [...objects, object];
      } else {
        return objects;
      }
    });
  }

  function addTextFromDialogBox(event) {
    let appendable = {};

    if (event.detail.subtype === 'date') {
      appendable.dateSegment = event.detail.dateSegment;
      appendable.groupId = event.detail.groupId;
    }

    addTextField(event.detail.text, {
      annotationType: event.detail.annotationType,
      createdFromContextMenu: true,
      disableEditing: event.detail.disableEditing,
      isAnnotation: event.detail.isAnnotation,
      x: event.detail.x,
      y: event.detail.y,
      subtype: event.detail.subtype,
      ...appendable,
    });

    editorToolsVisible = false;
  }

  function addAnnotationFromDialogBox(event) {
    addActionableAnnotation({
      participant: event.detail.participant,
      type: event.detail.type,
      x: event.detail.x,
      y: event.detail.y,
      pageIndex: selectedPageIndex,
    });

    editorToolsVisible = false;
  }

  function addActionGroupAnnotationFromDialogBox(event) {
    addActionGroupAnnotation({
      participant: event.detail.participant,
      type: event.detail.type,
      x: event.detail.x,
      y: event.detail.y,
      pageIndex: selectedPageIndex,
    });

    editorToolsVisible = false;
  }

  function autoplacedElementAdjustedY(annotation, methodName) {
    let y = annotation.y;

    if (methodName === 'addParticipantSignature') {
      y = y - (defaultSignatureWidth * 0.1389);
    } else if (methodName === 'addParticipantInitials') {
      y = y - (defaultInitialsWidth * 0.075);
    }

    return y;
  }

  function canZoomInFurther() {
    return window.ES_zoomLevel < 2.5;
  }

  function cleanedObjects() {
    let cleanedObjects = [];

    allObjects.forEach((pageObjects, pageIndex) => {
      if (!cleanedObjects[pageIndex]) {
        cleanedObjects[pageIndex] = [];
      }

      pageObjects.forEach((pageObject) => {
        if (pageObject.type === 'actionable-annotation' && pageObject.hidden !== undefined) {
          delete pageObject.hidden;
        }

        cleanedObjects[pageIndex].push(pageObject);
      });
    });

    return cleanedObjects;
  }

  function clearAnnotations() {
    let updatedObjects = [];

    allObjects.forEach((pageObjects, pageIndex) => {
      updatedObjects[pageIndex] = [];

      pageObjects.forEach((pageObject) => {
        if (objectIsAnnotation(pageObject)) {
          return;
        }

        updatedObjects[pageIndex].push(pageObject);
      });
    });

    allObjects = updatedObjects;
  }

  function clickEventPosition(clickEvent) {
    let zoomFactor = window.ES_zoomLevel;

    if (zoomFactor !== 1) {
      zoomFactor = 1 / ( 1.25 * zoomFactor);
    }

    return {
      x: clickEvent.offsetX * zoomFactor,
      y: clickEvent.offsetY * zoomFactor,
    };
  }

  function computePageTagStyle() {
    if (documentIsWiderThanWindow()) {
      return pageTagStyle = '';
    }

    return pageTagStyle = 'align-items: center';
  }

  function createEmptySignatureDataForUser(email) {
    return {
      email: email,
      initials: 'empty',
      participant_email: email, // Looks like 'participant_email' is used in Esign. Test there before altering this (RA, Apr 2024)
      signature: 'empty',
    };
  }

  function dateIsCurrentOrFuture(month, day, year) {
    let providedDate = new Date(year, month - 1, day);
    let currentDate = new Date();

    providedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    let thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    let futureLimit = new Date(currentDate);
    futureLimit.setFullYear(futureLimit.getFullYear() + 100);

    // Check if the provided date is either current or in the future (within 100 years)
    if (providedDate >= currentDate && providedDate <= futureLimit) {
      return true;
    }

    // Check if the provided date is within the last 30 days
    return providedDate <= thirtyDaysAgo;
  }

  function debugDraggablePositioning(xPercentage, yPercentage, page, pdfPage) {
    const width = page.electrosigWidthMetadata ? page.electrosigWidthMetadata : pdfPage.view[2];
    const height = page.electrosigHeightMetadata ? page.electrosigHeightMetadata : pdfPage.view[3];

    return {
      x: (xPercentage / 100) * width,
      y: (yPercentage / 100) * height,
    };
  }

  function deleteObject(objectId, pageObjectIsOn) {
    if (pageObjectIsOn === undefined) {
      pageObjectIsOn = selectedPageIndex; // aka, the current page
    }

    allObjects = allObjects.map((objects, pIndex) => {
      if (pIndex === pageObjectIsOn) {
        let actionableAnnotation = getObjectByRelatedId(objectId);

        if (actionableAnnotation) {
          updateObject(
            actionableAnnotation.id, 
            { associatedObjectId: null },
            pIndex
          );
        }

        emitDocumentModified({
          action: 'delete',
          target: 'object',
          objectId: objectId,
          pageIndex: pageObjectIsOn,
        });

				return objects.filter(object => object.id !== objectId);
			} else {
				return objects;
			}
		});
  }

  function pageHasActionableAnnotations(pageIndex) {
    if (!allObjects[pageIndex]) return false;
    
    return allObjects[pageIndex].some(object => 
      object.type === 'actionable-annotation'
    );
  }

	function deletePage() {
		if (pages.length < 2) {
			simpleModalButtonText = 'Okay';
			simpleModalMessage = 'You must have at least one page.';
			simpleModalTitle = 'Cannot delete page';
			return simpleModalVisible = true;
		}

    if (pageHasActionableAnnotations(selectedPageIndex)) {
      simpleModalButtonText = 'Okay';
      simpleModalMessage = 'Cannot delete this page because it contains signature or initials fields that need to be completed.';
      simpleModalTitle = 'Cannot delete page';
      return simpleModalVisible = true;
    }

		twoChoicesModalCtaAction = async () => {
      let pageToDelete = pages[selectedPageIndex];

      if (pageToDelete.electrosigIsFirstDoc) {
        originalPagesRemaining = originalPagesRemaining.filter(
          (pageIndex) => {
            return pageIndex !== pageToDelete.electrosigInitialDocPageIndex;
          }
        );
      }

      let newAllObjects = [];

      pages.forEach((page, pIndex) => {
        if (pIndex !== selectedPageIndex) {
          newAllObjects.push(allObjects[pIndex]);
        }
      });

			pages = pages.filter((page, pIndex) => {
				return pIndex !== selectedPageIndex
			});

      allObjects = newAllObjects;
      pdfFile = await removePageFromPdfBlob(pdfFile, selectedPageIndex);
			selectedPageIndex = 0;
			twoChoicesModalVisible = false;
		};

		twoChoicesModalCtaText = 'Delete';
		twoChoicesModalMessage = "Are you sure you want to delete that page?";
		twoChoicesModalOtherBtnText = 'Cancel';
		twoChoicesModalTitle = 'Delete Page';
		twoChoicesModalVisible = true;
	}

  function documentIsWiderThanWindow() {
    let windowWidth = window.innerWidth;
    let maxPageWidth = largestPageWidth();
    
    return (maxPageWidth * window.ES_zoomLevel) > windowWidth;
  }

  function doOnceDocLoaded(callback) {
    if (documentId && firstDocumentLoaded) {
      return callback();
    }

    setTimeout(() => {
      doOnceDocLoaded(callback);
    }, 1500);
  }

  function emitBack() {
    return window.parent.postMessage({
      type: 'back-clicked',
    }, "*");
  }

  function emitClickedMoreBtn() {
    return window.parent.postMessage({
      type: 'more-btn-clicked',
    }, "*");
  }

  function emitDatesCurrent() {
    return window.parent.postMessage({
      type: 'dates-current',
    }, "*");
  }

  function emitDatesOutdated() {
    return window.parent.postMessage({
      type: 'dates-outdated',
    }, "*");
  }

  function emitDocumentModified(details = {}) {
    if (!documentId) {
      return;
    }

    return window.parent.postMessage({
      type: 'document-modified',
      documentId: documentId,
      documentModified: true,
      details: {
        action: details.action || null,
        target: details.target || null,
        objectId: details.objectId || null,
        pageIndex: details.pageIndex || selectedPageIndex,
        ...details,
      },
    }, "*");
  }

  function emitPromptForOneTimeSignature() {
    return window.parent.postMessage({
      type: 'prompt-for-one-time-signature',
    }, "*");
  }

  function emitPromptForInitials(promptedByAnnotation = false, participant = null) {
    return window.parent.postMessage({
      type: 'prompt-for-initials',
      promptedByAnnotation: promptedByAnnotation,
      forParticipant: participant,
    }, "*");
  }

  function emitPromptForNotaryToolsNotReady() {
    return window.parent.postMessage({
      type: 'prompt-for-notary-tools-not-ready',
    }, "*");
  }

  function emitPromptForSignature(promptedByAnnotation = false, participant = null) {
    editorToolsVisible = false;
    return window.parent.postMessage({
      type: 'prompt-for-signature',
      promptedByAnnotation: promptedByAnnotation,
      forParticipant: participant,
    }, "*");
  }

  function emitRejectSignatures(participantData) {
    return window.parent.postMessage({
      type: 'rejected-signatures',
      participantData: participantData,
    }, "*");
  }

  function erasePageObjects(eraseAnnotations) {
    let updatedObjects = [];

    allObjects.forEach((pageObjects, pageIndex) => {
      updatedObjects[pageIndex] = [];

      pageObjects.forEach((pageObject) => {
        if (!eraseAnnotations && pageObject.isAnnotation) {
          updatedObjects[pageIndex].push(pageObject);
        }
      });
    });

    allObjects = updatedObjects;
  }

  function formatCloudinaryUrl(url) {
    url = url.replace(/upload/g, 'upload/w_1000,');

    let extension = url.split('.').pop();

    if (extension === 'pdf') {
      return url;
    }

    let exploded_url = url.split('.');
    exploded_url.pop();
    exploded_url.push('pdf');

    return exploded_url.join('.');
  }

  function fullDatesAreCurrentOrFuture(fullDateObjects) {
    for (let i = 0; i < fullDateObjects.length; i++) {
      let fullDate = fullDateObjects[i].lines[0];
      let fullDateParts = fullDate.split('/');
      let month = fullDateParts[0];
      let day = fullDateParts[1];
      let year = fullDateParts[2];

      if (!dateIsCurrentOrFuture(month, day, year)) return false;
    }

    return true;
  }

  function getAllAnnotations() {
    let annotations = [];

    // find all annotations
    for (let i = 0; i < pages.length; i++) {
      let pageAnnotations = allObjects[i].filter((object) => {
        return object.isAnnotation;
      });

      annotations.push(pageAnnotations);
    }

    return annotations;
  }

  function getCurrentParticipantEmails() {
    return currentParticipants.map((participant) => participant.email);
  }

  function getFirstCurrentParticipantWithEmail(email) {
    let firstCurrentParticipant = null;

    currentParticipants.forEach((currentParticipant) => {
      if (currentParticipant.email === email && !firstCurrentParticipant) {
        return firstCurrentParticipant = currentParticipant;
      }
    });

    return firstCurrentParticipant;
  }

  function getNotPerformedActionableAnnotations(participantEmails = []) {
    return getObjectsByType('actionable-annotation').filter(
      (annotation) => {
        return annotation.participant !== undefined
          && participantEmails.includes(annotation.participant.email) 
          && !annotation.associatedObjectId;
      }
    );
  }

  // All instances of 'dumbGetObject' to this method eventually. Just need to test each to make sure nothing breaks (RA, Apr 2024)
  // --
  function getObject(id) {
    for (let pageIndex = 0; pageIndex < allObjects.length; pageIndex++) {
      const objects = allObjects[pageIndex];

      for (let i = 0; i < objects.length; i++) {
        const object = objects[i];

        if (object.id === id) {
          object.pageIndex = pageIndex;
          return object;
        }
      }
    }

    return null;
  }

    /*
      this exist because of the inconsistency in the detail property in the object, we have plans to clean it soon.
      so far this only work for signatures and initials
      Aneudy A Nov 2024.
    */
    function getParticipantEmailFromPageObject(object) {
      if (object.type !== 'image') {
        return null
      }

      const { signatureData, participant } = object.detail;

      if (signatureData) {
        return signatureData.participant_email || signatureData.email 
      }


      return participant.email;
    }

  // This is called 'dumb' because it's dumb to return an array of objects in a method originally called 'getObject'. This is the older method that should be phased out and replaced in the future. (RA, Apr 2024)
  // --
  function dumbGetObject(id) {
    return allObjects.map((objects, pIndex) => {
      return objects.find(object => {
        object.pageIndex = pIndex;
        return object.id === id;
      });
    });
  }

  function findAndReplaceExistingSignatures(event,emailToIndexMap) {
    allObjects.forEach((pageObjects, pageIndex) => {
      pageObjects.forEach((object) => {
        if (object.type === 'image' && 
          (object.subtype === "participant_signature" || object.subtype === 'participant_initials')) {
          
          const participantEmail = getParticipantEmailFromPageObject(object);
          
          if (participantEmail && emailToIndexMap.has(participantEmail)) {
            // Get the new signature data
            const newSignatureData = event.data.signatures[emailToIndexMap.get(participantEmail)];
            
            // Store position and details before deleting
            const oldX = object.x;
            const oldY = object.y;
            const oldPageIndex = pageIndex;
            const height = object.height;
            const width = object.width;
            const isSignature = object.subtype === 'participant_signature';
            const participant = object.detail.participant;

            deleteObject(object.id);
            
            const methodName = isSignature ? 'addParticipantSignature' : 'addParticipantInitials';
            draggableObjectFactory[methodName]({
              detail: {
                x: oldX,
                y: oldY,
                signatureData: newSignatureData,
                participant: participant
              },
              pageIndex: oldPageIndex,
              height: height,
              width: width,
            });
          }
        }
      });
    });
  }

  function getObjectByRelatedId(objectId) {
    let object = null;

    allObjects.forEach((pageObjects, pageIndex) => {
      pageObjects.forEach((pageObject) => {
        if (pageObject.associatedObjectId === objectId) {
          object = pageObject;
        }
      });
    });

    return object;
  }

  function getObjectsByType(type) {
    let objects = [];

    allObjects.forEach((pageObjects, pageIndex) => {
      pageObjects.forEach((pageObject) => {
        if (pageObject.type === type) {
          pageObject.pageIndex = pageIndex;
          objects.push(pageObject);
        }
      });
    });

    return objects;
  }

  function getParticipantIndexByEmail(email) {
    return participants.findIndex(participant => participant.email === email);
  }

  function getParticipantIndex(name) {
    let index = -1;
    const lowercaseName = name.toLowerCase().trim();

    for (let i = 0; i < participants.length; i++) {
      if (participants[i].full_name.toLowerCase().trim() === lowercaseName) {
        index = i;
        break;
      }
    }

    return index;
  }

  // Looks like 'participant_email' is used in Esign. Test there before altering this (RA, Apr 2024)
  // --
  function getParticipantSignatureDataByEmail(email) {
    return participantSignatures.find(
      (signature) => signature.participant_email === email
    );
  }

  function getSpecificAnnotation(id) {
    let annotationsGrouped = getAllAnnotations();

    for (let i = 0; i < annotationsGrouped.length; i++) {
      let annotations = annotationsGrouped[i];
      for (let j = 0; j < annotations.length; j++) {
        let annotation = annotations[j];
        if (annotation.id === id) {
          return {
            annotation: annotation,
            annotationIndex: j,
            pageIndex: i,
          };
        }
      }
    }

    return null;
  }

  function goToFirstPage() {
    selectedPageIndex = 0;
    window.scrollTo(0, 0);
  }

  function groupSegmentedDateObjects(objectArray) {
    let groupedObjects = {};

    // group objects by groupId
    for (let i = 0; i < objectArray.length; i++) {
      let groupId = objectArray[i].groupId;

      if (!groupedObjects[groupId]) {
        groupedObjects[groupId] = [];
      }

      groupedObjects[groupId].push(objectArray[i]);
    }

    let outputObjects = {};

    // assign the month, day, and year objects to respective keys for their groups
    for (let groupId in groupedObjects) {
      let monthObject = groupedObjects[groupId].find((object) => {
        return object.dateSegment === 'month';
      });
      let dayObject = groupedObjects[groupId].find((object) => {
        return object.dateSegment === 'day';
      });
      let yearObject = groupedObjects[groupId].find((object) => {
        return object.dateSegment === 'year';
      });

      outputObjects[groupId] = {
        month: monthObject,
        day: dayObject,
        year: yearObject,
      };
    }

    for (let groupId in outputObjects) {
      if (!outputObjects[groupId].month || !outputObjects[groupId].day || !outputObjects[groupId].year) {
        delete outputObjects[groupId];
      }
    }

    return outputObjects;
  }

  // This is used for click-to-add-signature annotations. Not triggered or used for click-to-place-signing-date annotations. For that, see placeElementAboveAnnotation(). It's dumb, but that's where we are with things. (RA, Apr 2024)
  // --
  function handleActionableAnnotationClick(event) {
    actionableAnnotationLastClickedAt = event.timeStamp;
    let email = event.detail.participant.email;
    const expectedParticipant = getFirstCurrentParticipantWithEmail(email);

    if (!expectedParticipant || isMode('notary')) {
      return console.log('User clicked annotation not assigned to them, not handling actionable annotation click.');
    }

    event.detail.signatureData = getParticipantSignatureDataByEmail(email);
    event.detail.waitForObjectPlacement = true;

    if (event.detail.type === 'initials') {
      draggableObjectFactory.addParticipantInitials(event);
    } else if (event.detail.type === 'signature') {
      draggableObjectFactory.addParticipantSignature(event);
    } else {
      return console.log('Unknown actionable annotation type:', event.detail.type);
    }

    let annotation = getObject(event.detail.objectId)

    if (annotation) {
      updateObject(annotation.id, {
        appliedAt: Date.now(),
        appliedByUserEmail: expectedParticipant.email,
      });
    }
  }

  function handleAnnotationCompletedByClient(event) {
    let shouldScroll = true;

    if (event.shouldScroll !== null && event.shouldScroll !== undefined) {
      shouldScroll = event.shouldScroll;
    }

    if (shouldScroll) {
      scrollToNextActionableAnnotation();
    }
  }

  function handleBrowserResize() {
    updateIsMobile();
    computePageTagStyle();
  }

  function handleClickedPage(pageIndex, clickEvent) {
    if (selectedPageIndex !== pageIndex) {
      return selectPage(pageIndex);
    }

    if (!clickEvent.target.classList.contains("page-canvas") 
      || editingText
    ) {
      return;
    }

    if (tapoutLastTick) {
      tapoutLastTick = false;
      editorToolsVisible = false;
      return;
    }

    if (hasPointerMoved(clickEvent, pointerDownPos)) return;

    openEditorTools(clickEvent);
    addDebugDot(clickEvent.offsetX, clickEvent.offsetY, 'green');
    addDebugDot(editorToolsXPosition, editorToolsYPosition, 'blue');
    addDebugDot(clickedXPosition, clickedYPosition, 'red');
  }

  function handleDebugMode(event) {
    debug = event.detail.debug;
    debugAnnotations = event.detail.command.includes('annotations') || event.detail.command.includes('all');
    debugAddDraggables = event.detail.command.includes('draggables') || event.detail.command.includes('all');
    debugAnonParticipants = event.detail.command.includes('anon_participants') || event.detail.command.includes('all');
    debugAutoPlaceElements = event.detail.command.includes('auto_place_elements') || event.detail.command.includes('all');
    debugNotaryData = event.detail.command.includes('notary_data') || event.detail.command.includes('all');
    debugParticipants = event.detail.command.includes('participants') || event.detail.command.includes('all');
    debugCurrentParticipant = event.detail.command.includes('current_participant') || event.detail.command.includes('all');
    debugCurrentParticipants = event.detail.command.includes('current_participants') || event.detail.command.includes('all');
    debugShowClickDots = event.detail.command.includes('clicks') || event.detail.command.includes('all');
    debugShowSaveButton = event.detail.command.includes('save_button') || event.detail.command.includes('all');
    debugSignatures = event.detail.command.includes('signatures') || event.detail.command.includes('all');
    debugShowInteractionInstructions = event.detail.command.includes('interaction_instructions') || event.detail.command.includes('all');

    let debugLogPages = event.detail.command.includes('log_pages') || event.detail.command.includes('all');
    let debugLogObjects = event.detail.command.includes('log_objects') || event.detail.command.includes('all');
    let debugLogParticipantData = event.detail.command.includes('log_participant_data') || event.detail.command.includes('all');
    let debugClearObjects = event.detail.command.includes('clear_objects') || event.detail.command.includes('all');

    if (debugAddDraggables) {
      addDebugDraggables(pages);
    }

    if (debugClearObjects) {
      allObjects = [];

      pages.forEach((page) => {
        allObjects.push([]);
      });
    }

    if (debugAnnotations) {
      addDebugAnnotations(pages);
    }

    if (debugLogPages) {
      console.log('pages', pages);
      debugLogPages = false;
    }

    if (debugLogObjects) {
      console.log('allObjects', allObjects);
      debugLogObjects = false;
    }

    if (debugLogParticipantData) {
      console.log('currentParticipants', currentParticipants);
      console.log('participants', participants);
      console.log('participantSignatures', participantSignatures);
      console.log('oneTimeSignature', oneTimeSignature);
      debugLogParticipantData = false;
    }

    if (debugSignatures) {
      addDebugSignatures();
    }

    if (debugShowInteractionInstructions) {
      debugShowInteractionInstructions = true;
      showInteractionInstructions = true;
    }

    if (debugParticipants) {
      addDebugParticipants();
    }

    if (debugCurrentParticipant) {
      addDebugCurrentParticipant();
    }

    if (debugCurrentParticipants) {
      addDebugCurrentParticipants();
    }

    if (debugAnonParticipants) {
      addDebugAnonParticipants();
    }

    if (debugNotaryData) {
      addDebugNotaryData();
    }

    if (debugAutoPlaceElements) {
      placeElementsAboveAnnotations();
    }
  }

  function handleEsignSignatureImported(event) {
    participantSignatures = [event.data.signatures];
    
    if (!event.data.place_object) {
      return null;
    }

    setTimeout(() => {
      if (event.data.signature_type === 'signature') {
        return draggableObjectFactory.addParticipantSignature({
          detail: {
            signatureData: {
              signature: event.data.signatures.signature,
            },
          },
        });
      }

      draggableObjectFactory.addParticipantInitials({
        detail: {
          signatureData: {
            initials: event.data.signatures.initials,
          },
        },
      });

    }, 500);
  }

  function handleInteractionInstructionsClick(event) {
    if (pages.length === 0 || pages[0] === undefined) {
      console.log('Couldn\'t find first page width, not opening editor tools.');
      return showInteractionInstructions = false;
    }

    let dialogWidthOffset = 100;
    let dialogHeightOffset = 100;

    let x = (pages[0].electrosigWidthMetadata / 2) - dialogWidthOffset;
    let y = (pages[0].electrosigHeightMetadata / 2) - dialogHeightOffset;

    showInteractionInstructions = false;

    selectPage(0);
    openEditorTools({ offsetX: x, offsetY: y, x: x, y: y });
  }

  function handleTextClick(event) {
    if (participants.length < 1) return;
    if (isMode('esign_preorder', 'preorder', 'cna_preorder')) return;

    let { id } = event.detail;
    let annotation = getSpecificAnnotation(id);

    if (annotation === null) {
      console.log('Couldn\'t find annotation with id:', id);
      return;
    }

    waitForObjectPlacement(event, annotation);
    placeElementAboveSpecificAnnotation(annotation, true);
  }

  function handlePointerDown(clickEvent) {
    pointerDownPos.x = clickEvent.x;
    pointerDownPos.y = clickEvent.y;
  }

  function handlePointerUp(clickEvent) {
    mouseIsDown = false;
    lastMouseX = null;
    lastMouseY = null;
  }

  function handleRejectSignaturesClick() {
    const hasAnySignaturesPlaced = allObjects.some(pageObjects => 
      pageObjects.some(obj => 
        obj.type === 'image' && 
        (obj.subtype === 'participant_signature' || obj.subtype === 'participant_initials')
      )
    );

    if (!hasAnySignaturesPlaced && participantSignatures.length === 0) {
      simpleModalTitle = 'No Signatures Found';
      simpleModalMessage = 'There are no signatures or initials to reject.';
      simpleModalButtonText = 'Close';
      simpleModalVisible = true;
      return;
    }

    showRejectParticipantsListModal = true;
  }

  function hasPointerMoved(pointerDownPos, pointerUpPos) {
    return Math.abs(pointerDownPos.x - pointerUpPos.x) > pointerMoveThreshold
      || Math.abs(pointerDownPos.y - pointerUpPos.y) > pointerMoveThreshold;
  }

  function largestPageWidth() {
    let currentMax = pages[0].electrosigWidthMetadata;
    let numberOfPages = pages.length;

    for (var i = 0; i < numberOfPages; i++) {
      let pageWidth = pages[i].electrosigWidthMetadata;

      if (pageWidth > currentMax) {
        currentMax = pageWidth;
      }
    }

    return currentMax;
  }

  // We should figure out the correct abstraction boundaries to distinguish the purpose of 'loadPdf()' and 'addPDF()' (RA, Feb 2023)
  // --
  async function loadPdf(file) {
    loadingPdf = true;

    try {
      const pdf = await readAsPDF(file);
      const pdfData = await pdf.getData();
      const updatedFile = new File([pdfData], file.name, {type: 'application/pdf'});

      pdfName = file.name;
      pdfFile = updatedFile;
      originalPagesRemaining = [];

      for (let i = 0; i < pdf.numPages; i++) {
        let page = pdf.getPage(i + 1);
        page.electrosigIsFirstDoc = !firstDocumentLoaded;

        if (page.electrosigIsFirstDoc) {
          page.electrosigInitialDocPageIndex = i;
        }

        pages.push(page);
        allObjects.push([]);
        originalPagesRemaining.push(i);
        pagesScale.push(1);
        rotationKeys.push(0);
      }

      window.scrollTo(0, 0);

      setTimeout(() => {
        selectPage(0);

        if (!firstDocumentLoaded) {
          firstDocumentLoaded = true;
        }
      }, 100);
    } catch (e) {
      let errorMsg;

      if (e.message === 'No password given') {
        errorMsg = "The file is encrypted or password protected. Please try another PDF.";
      } else {
        errorMsg = e.message;
      }

      document.getElementById('pdf').value = "";
      console.log("Failed to add pdf. Error: " + errorMsg);
      throw e;
    } finally {
      loadingPdf = false;
    }
  }

  // The 'loadingPdf' while loop below fixes a bug where 
  // multiple loadRemotePdf calls are fired too quickly,
  // causing the pages and/or objects to duplicate. This requires the
  // function to wait until the previous pdf is loaded before continuing.
  // Only waits 5 seconds (20 loops) max to ensure it doesn't loop infinitely.
  // (MB, June 2023)
  // --
  async function loadRemotePdf(url, removeExistingPages, myCallback) {
    let checkLoop = 0;

    while (loadingPdf && checkLoop < 20) {
      await sleep(250);
      checkLoop++;
    }

    if (url.includes('res.cloudinary.com')) {
      url = formatCloudinaryUrl(url);
    }

    if (removeExistingPages) {
      pages = [];
      pagesScale = [];
      rotationKeys = [];
    }

    try {
      const res = await fetch(url);

      // const res = await fetch(url, { mode: 'no-cors'});
      const pdfBlob = await res.blob();
      await addPDF(pdfBlob);
      selectedPageIndex = 0;

      setTimeout(() => {
        fetchFont(currentFont);
        prepareAssets();

        if (myCallback) {
          myCallback();
        }
      }, 1200);
    } catch (e) {
      if (e.message === 'Failed to fetch') {
        console.log('URL does not exist');
        showMessageToUser('Failed to load PDF from URL. Please check the URL and try again.', 'failed-to-load-pdf');
      } else {
        console.log(e);
      }
    }
  }

  // It's annoying that we have to create these 'blank' signatures and then later pretend they don't exist. We do this because there was too much code that committed to the idea of using numerical indices to refer to participants that it was too large of a project to refactor the ContextMenu to refer to user ids or user emails instead. (RA, Apr 2024)
  // --
  function loadSignaturesFromParentFrame(event) {
    let newIndex = 0;
    const emailToIndexMap = new Map();
    // Create map for new signatures
    event.data.signatures.forEach((eventMemberInfo) => {
      let email = eventMemberInfo.email || eventMemberInfo.participant_email;
      emailToIndexMap.set(email, newIndex);
      newIndex++;
    });

    findAndReplaceExistingSignatures(event,emailToIndexMap);

    // Update participantSignatures array
    let signatureData = mapParticipantsToSignatureData(event,emailToIndexMap);

    participantSignatures = signatureData;
    return placeObjectAfterWait = event.data.place_object ? event.data.place_object : false;
  }

  function markDocAcceptanceStatusCustomer(isAccepted) {
    showGreenSaveSvg = isAccepted;

    if (isAccepted) {
      greenSaveSvgPopoverText = 'Notarize Document';
    } else {
      greenSaveSvgPopoverText = 'Save';
    }
  }

  function markStoppedEditingText() {
    setTimeout(() => {
      editingText = false;
    }, 100);
  }

  function mapParticipantsToSignatureData(event,emailToIndexMap) {
    return currentParticipants.map((participantData) => {
      let signatureDataItem = createEmptySignatureDataForUser(participantData.email);
      let eventDataIndex = emailToIndexMap.get(participantData.email);

      if (eventDataIndex || eventDataIndex === 0) {        
        let eventSignatureData = event.data.signatures[eventDataIndex];
        signatureDataItem.signature = eventSignatureData.signature;
        signatureDataItem.initials = eventSignatureData.initials;
      }

      if (signatureDataItem.signature === 'empty' || signatureDataItem.initials === 'empty') {
        signatureDataItem = replacePlaceholderSigDataWithPreviousData(signatureDataItem);
      }

      return signatureDataItem;
    });
  }


  async function mergePdfBlobs(inputs) {
    const PDFLib = await getAsset('PDFLib');
    const pdfA = await PDFLib.PDFDocument.load(await inputs.pdfA.arrayBuffer());
    const pdfB = await PDFLib.PDFDocument.load(await inputs.pdfB.arrayBuffer());
    const mergedPdf = await PDFLib.PDFDocument.create();

    const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices());
    copiedPagesA.forEach((page) => mergedPdf.addPage(page));

    const copiedPagesB = await mergedPdf.copyPages(pdfB, pdfB.getPageIndices());
    copiedPagesB.forEach((page) => mergedPdf.addPage(page));

    emitDocumentModified({
      action: 'merge',
      target: 'pdf',
    })

    return new Blob([await mergedPdf.save()], {type: 'application/pdf'});
  }

  // We need the 'mergePdfBlobs' call here to make sure the pdfs are merged in the output file. (RA, Nov 2022)
  // --
  async function mergePdfIntoEditorFromUrl(pdfUrl) {
    try {
      const res = await fetch(pdfUrl);

      if (res.status !== 200) {
        console.log('URL does not exist');
        showMessageToUser('Failed to load PDF from URL. Please check the URL and try again.', 'failed-to-load-pdf');
        return;
      }

      const pdfBlob = await res.blob();

      try {
        const pdf = await readAsPDF(pdfBlob);

        pdfFile = await mergePdfBlobs({
          name: pdfName,
          pdfA: pdfFile,
          pdfB: pdfBlob,
        });

        for (let i = 0; i < pdf.numPages; i++) {
          pages.push(pdf.getPage(i + 1));
          allObjects.push([]);
          pagesScale.push(1);
        }

        justAddedPages = true;
        selectedPageIndex = pages.length - 1;
      } catch (e) {
        let errorMsg = e.message;
        console.log("Failed to add pdf. Error: " + errorMsg);
        throw e;
      }
    } catch (e) {
      if (e.message === 'Failed to fetch') {
        console.log('URL does not exist');
        showMessageToUser('Failed to load PDF from URL. Please check the URL and try again.', 'failed-to-load-pdf');
      } else {
        console.log(e);
      }
    }
  }

  async function mergePdfIntoEditorFromFile(file) {
    try {
      const pdf = await readAsPDF(file);

      pdfFile = await mergePdfBlobs({
        name: pdfName,
        pdfA: pdfFile,
        pdfB: file,
      });

      for (let i = 0; i < pdf.numPages; i++) {
        pages.push(pdf.getPage(i + 1));
        allObjects.push([]);
        pagesScale.push(1);
      }

      justAddedPages = true;
      selectedPageIndex = pages.length - 1;
    } catch (e) {
      let errorMsg = e.message;
      console.log("Failed to add pdf. Error: " + errorMsg);
      throw e;
    }
  }

  function monitorHumanActivity() {
    function handleEvent(event) {
      window.parent.postMessage({
        activity_type: event.type,
        type: 'human-activity',
      }, "*");
    }

    const throttledHandleEvent = throttle(handleEvent, 1700);

    window.addEventListener('mousemove', throttledHandleEvent);
    window.addEventListener('click', throttledHandleEvent);
    window.addEventListener('scroll', throttledHandleEvent);
  }

  function notifyDocumentLoaded() {
    window.parent.postMessage({
      documentId: documentId,
      type: 'initial-document-loaded',
    }, "*");
  }

  function objectInsidePage(objectId, pageIndex, horizontalPixelForgiveness) {
    if (!horizontalPixelForgiveness) horizontalPixelForgiveness = 0;
    let verticalPixelForgiveness = horizontalPixelForgiveness / 3; // might have to tweak for non-Text fields

    const page = pages[pageIndex];
    const pageWidth = page.electrosigWidthMetadata;
    const pageHeight = page.electrosigHeightMetadata;
    let object = getObject(objectId);

    const x = object.x;
    const y = object.y;
    const width = object.width || 0; // Default to 0 if undefined
    const height = object.height || 0;

    if (x < (0 - horizontalPixelForgiveness)) return false;
    if (y < (0 - verticalPixelForgiveness)) return false;
    if (x + width > (pageWidth + horizontalPixelForgiveness)) return false;
    if (y + height > (pageHeight + verticalPixelForgiveness)) return false;

    return true;
  }

  function objectIsAnnotation(pageObject) {
    return pageObject.isAnnotation 
      || pageObject.type === 'actionable-annotation'
      || pageObject.annotationType;
  }

  function onAddDrawing() {
    if (selectedPageIndex >= 0) {
      addingDrawing = true;
      editorToolsVisible = false;
    }
  }

  // The 'measure' event occurs whenever the screen size changes. This could be due to the user resizing the window, or the user changing the orientation of the device. This is a custom event defined in PDFPage.svelte. (RA, July 2022)
  // --
  function onMeasure(scale, i) {
    pagesScale[i] = scale;
  }

  function onPageRotationMeasured(event) {
    const { pageIndex, rotation, initialRotation } = event.detail;

    if (pages[pageIndex]) {
      pages[pageIndex].electrosigRotationMetadata = rotation;
      pages[pageIndex].electrosigInitialRotationMetadata = initialRotation;
    }
  }

  function onPageSizeMeasured(event) {
    const { pageIndex, width, height } = event.detail;

    if (pages[pageIndex]) {
      pages[pageIndex].electrosigWidthMetadata = width;
      pages[pageIndex].electrosigHeightMetadata = height;
    }

    computePageTagStyle();
  }

  function onPdfFileChange() {
    if (justAddedPages) {
      justAddedPages = false;

      return setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }
  }

  async function onUploadImage(e) {
    const file = e.target.files[0];
    if (file && selectedPageIndex >= 0) {
      addImage(file, {
        imgName: 'on_upload_image',
        pageIndex: selectedPageIndex,
        x: editorToolsXPosition,
        y: editorToolsYPosition,
      });

      editorToolsVisible = false;
    }

    e.target.value = null;
  }

  async function onUploadPDF(e) {
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
    const file = files[0];
    if (!file || file.type !== "application/pdf") return;

    selectedPageIndex = -1;
    allObjects = [];
    pages = [];

    try {
      await addPDF(file);
      selectedPageIndex = 0;
    } catch (e) {
      console.log(e);
    }
  }

  async function openEditorTools(clickEvent) {
    let clickPosition = clickEventPosition(clickEvent);

    clickedXPosition = clickPosition.x;
    clickedYPosition = clickPosition.y;
    editorToolsXPosition = clickPosition.x;
    editorToolsYPosition = clickPosition.y;

    editorToolsVisible = true;

    await tick();

    let absoluteX = clickEvent.x;
    let absoluteY = clickEvent.y;

    let floatingEditor = document.getElementById('context-menu');
    let floatingEditorRect = floatingEditor.getBoundingClientRect();
    let floatingEditorWidth = floatingEditorRect.width;
    let floatingEditorHeight = floatingEditorRect.height;

    if (absoluteX + floatingEditorWidth > window.innerWidth) {
      editorToolsXPosition = clickPosition.x - floatingEditorWidth;
    }
    if (absoluteY + floatingEditorHeight > window.innerHeight) {
      editorToolsYPosition = clickPosition.y - floatingEditorHeight;
    }
  }

  function panPageOnMousedragEvent(event) {
    if (!mouseIsDown || window.ES_panning_object) {
      return null;
    }

    const container = document.documentElement;
    const { clientWidth: width, clientHeight: height } = container;
    
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    
    if (lastMouseX !== null && lastMouseY !== null) {
      const deltaX = mouseX - lastMouseX;
      const deltaY = mouseY - lastMouseY;
      
      container.scrollLeft -= deltaX;
      container.scrollTop -= deltaY;
    }

    lastMouseX = mouseX;
    lastMouseY = mouseY;
  }

  // At least for RON, this is currently only used for the Signing Date annotation. Refactoring it to be reusable for click-to-add-signature annotations will be tricky, might make more sense to just rewrite it. For click-to-add-signature annotations, see handleActionableAnnotationClick() (RA, Apr 2024)
  // --
  function placeElementAboveAnnotation(inputs, fromClick = false) {
    if (isMode('order_template')) return;

    let annotation = inputs.annotation;
    let lineCount = annotation.lines.length;
    if (annotation.autoPlaced === true) return {status: 'already_placed'};

    if (lineCount !== 1) {
      return {status: 'invalid_line_count'}; // May need to revisit this at some point (RA, Jan 2023)
    }

    if (!inputs.pageIndex && inputs.pageIndex !== 0) {
      return {status: 'invalid_page_index'};
    }

    const name = annotation.lines[0].replace(' Sign', '').replace(' Initial', '');
    let participantIndex = getParticipantIndex(name);
    const capitalizedAnnotationType = pascalCase(annotation.annotationType);
    const methodName = 'addParticipant' + capitalizedAnnotationType;

    // skip auto placing dates for now on esign
    const isPreorderMode = isMode('esign_preorder', 'preorder', 'cna_preorder');
    const isLiveSessionMode = isMode('cls', 'notary');

    if (
      (isPreorderMode || isLiveSessionMode) 
      && !fromClick && methodName === 'addParticipantSigningDate'
    ) {
      return;
    }

    if (isMode('esign')) {
      participantIndex = 0; // a hack, since Esign users don't share devices (RA, Aug 2023)
    }

    if ('annotationIndex' in inputs) {
      allObjects[inputs.pageIndex][inputs.annotationIndex].autoPlacedAssociatedObject = true;
    }

    if (methodName === 'addParticipantDefault') return;

    draggableObjectFactory[methodName]({
      autoPlaced: true,
      detail: {
        signatureData: participantSignatures[participantIndex], // 'participantSignatures' here is actually an object that contains both signatures and initials (RA, Jan 2023)
        x: annotation.x,
        y: autoplacedElementAdjustedY(annotation, methodName),
      },
      pageIndex: inputs.pageIndex,
    });

    if (isMode('cls','esign','notary') 
      && methodName === 'addParticipantSigningDate'
    ) {
      deleteObject(annotation.id); // fix for dates getting stacked on top of each other (RA, Aug 2023)
    }

    let currentParticipant = currentParticipants[0]; // assuming the first signer, because we don't really know anyways for signing date. (RA, Apr 2024)

    if (currentParticipant) {
      updateObject(annotation.id, {
        appliedAt: Date.now(),
        appliedByUserEmail: currentParticipant.email,
      });
    }

    return {status: 'success'};
  }

  async function placeElementAboveSpecificAnnotation(annotation, fromClick = false) {
    placeElementAboveAnnotation(annotation, fromClick);
    await scrollToNextUnplacedAnnotation();
  }

  function placeElementsAboveAnnotations(event_data) {
    let annotations = getAllAnnotations();

    for (let i = 0; i < pages.length; i++) {
      placePageElementsAboveAnnotations({
        pageAnnotations: annotations[i],
        pageIndex: i,
      });
    }

    if (event_data && event_data.autoSaveDocument) {
        // Added a delay to help get text fields updating fully. (RA, Feb 2023)
        return setTimeout(() => {
          savePDF(null, {notaryLiveSkipApproveStep: true});
        }, 2000);
    }
  }

  function placePageElementsAboveAnnotations(inputs) {
    if (isMode('order_template')) return;

    let pageAnnotations = inputs.pageAnnotations;

    for (let x = 0; x < pageAnnotations.length; x++) {
      let annotation = pageAnnotations[x];
      if (annotation.annotationType === 'default') {continue;}

      let result = placeElementAboveAnnotation({
        annotation: annotation,
        pageIndex: inputs.pageIndex,
      });
    }
  }

  // This list is getting crazy long. Fixing this to be polymorphic is probably the way to go (RA, Jan 2023)
  // --
  function registerIframeParentEvents(isEsign) {
    window.onmessage = function(event) {
      if (event.data.type === 'auto-place-assets') {
        removeAllAutoplacedItems();
        return placeElementsAboveAnnotations(event.data);
      }

      if (event.data.type === 'doc-acceptance-status-customer') {
        return markDocAcceptanceStatusCustomer(event.data.isAccepted);
      }

      if (event.data.type === 'erase-page-objects') {
        return erasePageObjects(event.data.eraseAnnotations);
      }

      if (event.data.type === 'load-esign-signature-data') {
        return handleEsignSignatureImported(event);
      }

      if (event.data.type === 'load-notary-signature') {
        return notarySignatureUrl = event.data.notarySignatureUrl;
      }

      if (event.data.type === 'load-notary-stamp') {
        return notaryStampUrl = event.data.notaryStampUrl;
      }

      if (event.data.type === 'load-signatures') {
        return loadSignaturesFromParentFrame(event);
      }

      if (event.data.type === 'load-one-time-signature') {
        oneTimeSignature = event.data.signature_data;
        return placeObjectAfterWait = !!event.data.place_object ? event.data.place_object : false;
      }

      if (event.data.type === 'load-document-editing-mode') {
        return  shouldLockDocumentEditing = event.data.shouldLockDocumentEditing;
      }

      if (event.data.type === 'load-user-editing-permissions') {
        return userHasFullEditingPermissions = event.data.userHasFullEditingPermissions;
      }
      // For the live session and Esign. Annoying that this is different than the preorder forms (RA, Oct 2023)
      //
      if (event.data.type === 'load-current-participants') {
        return currentParticipants = event.data.currentParticipants;
      }

      // For IPen and the preorder forms for BA and Esign. Annoying that this is different than the live session (RA, Oct 2023)
      // 
      if (event.data.type === 'load-participants') {
        return participants = event.data.participants;
      }

      if (event.data.type === 'load-notary-data') {
        return notaryData = event.data.notaryData;
      }

      if (event.data.type === 'lock-editing') {
        return lockEditing = true;
      }

      // Used in IPen, trying to have a simpler method to just add a random signature (RA, Oct 2023)
      if (event.data.type === 'place-signature') {
        return draggableObjectFactory.addParticipantSignature({
          detail: {
            signatureData: {
              signature: event.data.signature,
            },
          },
        });
      }

      if (event.data.type === 'render-annotations') {
        currentParticipantEmail = event.data.currentParticipantEmail;
        isRemoteParticipantSinger = event.data.isRemoteParticipantSinger;

        return renderDocumentObjects({
          disableEditing: !event.data.allowEditing,
          documentObjects: event.data.annotations,
          isAnnotation: true,
        });
      }

      if (event.data.type === 'render-document-objects') {
        return replacePdfAndRenderObjects(isEsign, event);
      }

      // These are the edits created in NotaryLive by the 'Business Account'. (RA, Feb 2023)
      // --
      if (event.data.type === 'render-presaved-edits') {
        return renderDocumentObjects({
          // disableEditing: !event.data.allowEditing, // Don't change without discussing (RA, Feb 2023)
          documentObjects: event.data.elements.documentObjects,
          isPresaved: true,
        });
      }

      if (event.data.type === 'can-use-all-notary-tools') {
        return canUseAllNotaryTools = true;
      }

      if (event.data.type === 'save-document') {
        // Added a delay to help get text fields updating fully. That delay may need to be longer than the delay for Text.svelte's 'onKeydown', but I'm not sure if that is true. (RA, Nov 2022)
        return setTimeout(() => {
          if (isEsign) {
            savePDF(null, {forceFlattenDoc: true});
          } else {
            savePDF();
          }
        }, 1000);
      }

      if (event.data.type === 'save-document-flattened') {
        // Added a delay to help get text fields updating fully. That delay may need to be longer than the delay for Text.svelte's 'onKeydown', but I'm not sure if that is true. (RA, Nov 2022)
        return setTimeout(() => {
          savePDF(null, {forceFlattenDoc: true});
        }, 1000);
      }

      if (event.data.type === 'show-all-annotations') {
        return annotationsOverride = 'showAll';
      }

      if (event.data.type === 'can-use-all-notary-tools') {
        return canUseAllNotaryTools = true;
      }

      if (event.data.type === 'show-interaction-instructions') {
        return showInteractionInstructions = true;
      }

      if (event.data.type === 'unlock-editing') {
        return lockEditing = false;
      }

      if (event.data.type === 'update-document-id') {
        documentId = event.data.documentId;
        return doOnceDocLoaded(() => {notifyDocumentLoaded()});
      }

      if (event.data.type === 'zoom-in') {
        return zoomIn();
      }

      if (event.data.type === 'zoom-out') {
        return zoomOut();
      }
    };
  }

  function removeAllAutoplacedItems() {
    let updatedObjects = [];

    allObjects.forEach((pageObjects, pageIndex) => {
      updatedObjects[pageIndex] = [];

      pageObjects.forEach((pageObject) => {
        if (!pageObject.autoPlaced) {
          updatedObjects[pageIndex].push(pageObject);
        }
      });
    });

    allObjects = updatedObjects;
  }

  async function removePageFromPdfBlob(pdfBlob, pageIndex) {
    const PDFLib = await getAsset('PDFLib');
    const pdf = await PDFLib.PDFDocument.load(await pdfBlob.arrayBuffer());
    await pdf.removePage(pageIndex);

    emitDocumentModified({
      action: 'delete',
      target: 'page',
      pageIndex: pageIndex,
    });

    return new Blob([await pdf.save()], {type: 'application/pdf'});
  }

  function renderDocumentObject(inputs) {
    let settings = inputs.settings;
    let pageObject = inputs.pageObject;

    let creationParams = {
      autoPlaced: !!pageObject.autoPlaced,
      disableEditing: !!settings.disableEditing,
      pageIndex: inputs.pageIndex,
      x: pageObject.x,
      y: pageObject.y,
      subtype: pageObject.subtype ? pageObject.subtype : null,
    };

    if (pageObject.type === 'actionable-annotation'
      || pageObject.type === 'action-group-annotation'
    ) {
      creationParams.frozen = !!settings.disableEditing;

      return addActionableAnnotationFromPageObject(
        creationParams, pageObject
      );
    }

    if (pageObject.lines) {
      creationParams.annotationType = pageObject.annotationType;
      creationParams.fontFamily = pageObject.fontFamily;
      creationParams.isAnnotation = !!settings.isAnnotation;
      creationParams.lines = pageObject.lines;
      creationParams.lockedInPlace = !!settings.disableEditing;
      creationParams.textSize = pageObject.size;
      creationParams.width = pageObject.width;
      creationParams.groupId = pageObject.groupId || null;
      creationParams.isGrouped = pageObject.isGrouped || false;

      if (pageObject.subtype === 'date') {
        creationParams.dateSegment = pageObject.dateSegment;
      }

      return addTextField(pageObject.lines[0], creationParams);
    }

    creationParams.imgSrc = pageObject.imgSrc;
    creationParams.initialHeight = pageObject.height;
    creationParams.initialWidth = pageObject.width;

    addImage(pageObject, creationParams);
  }

  // This function is too long (RA, Aug 2023)
  // --
  function renderDocumentObjects(inputs) {
    goToFirstPage();
    if (inputs.isAnnotation) { clearAnnotations(); }

    if (inputs.disableEditing && !inputs.isAnnotation) {
      let updatedObjects = [];

      allObjects.forEach((pageObjects, pageIndex) => {
        updatedObjects[pageIndex] = [];

        pageObjects.forEach((pageObject) => {
          if (!pageObject.disableEditing
            || objectIsAnnotation(pageObject)
          ) {
            updatedObjects[pageIndex].push(pageObject);
          }
        });
      });

      allObjects = updatedObjects;
    }

    let documentObjects = JSON.parse(inputs.documentObjects);
    var pages = Object.keys(documentObjects);

    if (isMode('cls','notary','order')) {
      for (var i = 0; i < pages.length; i++) {
        if (!allObjects[i]) {
          allObjects.push([]);
        }
      }
    }

    for (var i1 = 0; i1 < pages.length; i1++) {
      let newInputs = inputs;
      var pageIndex = parseInt(pages[i1]);
      var pageObjects = documentObjects[pageIndex];

      for (var i2 = 0; i2 < pageObjects.length; i2++) {
        var pageObject = pageObjects[i2];

        // For now, only annotations are unflattened in Esign. This may change in the future (RA, Aug 2023)
        if (isMode('esign', 'esign_preorder') 
          && !objectIsAnnotation(pageObject)
        ) {
          continue;
        }

        renderDocumentObject({
          pageObject: pageObject,
          pageIndex: pageIndex,
          settings: newInputs,
        });
      }
    }

    verifyDatesAreCurrentOrFuture();
  }

  // Don't enable editing outside of Esign without talking with Rob (RA, Feb 2023)
  // --
  async function replacePdfAndRenderObjects(isEsign, event) {
    let downstreamArguments = {
      documentObjects: event.data.documentObjects,
      originalPagesRemaining: event.data.originalPagesRemaining,
      updatedPdf: event.data.updatedPdf,
    };

    if (isEsign) {
      downstreamArguments.disableEditing = !event.data.allowEditing;
    }

    if (!downstreamArguments.originalPagesRemaining
      || !downstreamArguments.updatedPdf
    ) {
      return renderDocumentObjects(downstreamArguments);
    }

    await loadRemotePdf(downstreamArguments.updatedPdf, true,
      () => {
        shiftExistingEdits(
          JSON.parse(downstreamArguments.originalPagesRemaining)
        );
        renderDocumentObjects(downstreamArguments);
      }
    );
  }

  function replacePlaceholderSigDataWithPreviousData(
    signatureDataPlaceholder
  ) {
    if (participantSignatures.length < 1) {
      return signatureDataPlaceholder;
    }

    let participantSignature;

    for (var i = 0; i < participantSignatures.length; i++) {
      participantSignature = participantSignatures[i];

      if (participantSignature.email === signatureDataPlaceholder.email) {
        return participantSignature;
      }
    }

    return signatureDataPlaceholder;
  }

  function rotatePage(direction) {
    rotationDirection = direction;
    rotationKeys[selectedPageIndex] = rotationKeys[selectedPageIndex] + 1;
  }

  // * Need to keep the 'event' param here to deal with the possibility of this triggering from a click event. (RA, Feb 2023)
  // * Either here or in PDF.js save(), we can export the objects data to re-import later on. That way, multiple users can edit the same pdf over time without it being flattened. (RA, June 2022)
  // * ShizukuIchi's original project had the note: 'FIXME: Should wait all objects finish their async work'
  // --
  async function savePDF(event, options) {
    if (window.ES_zoomLevel !== 1) {
      zoomTo(1);
    }

    // Disabled for now, until we fix the bugs with it (RA, Aug 2024)
    // if (isMode('esign')
    //     && getNotPerformedActionableAnnotations(getCurrentParticipantEmails()).length > 0
    //     && !userShownIncompleteActionableAnnotationsWarning
    // ) {
    //   userShownIncompleteActionableAnnotationsWarning = true;
      
    //   return showMessageToUser(
    //     'User has not completed all actionable annotations.', 
    //     'incomplete-actionable-annotations'
    //   );
    // }

    if (!pdfFile || saving || !pages.length) return;
    saving = true;
    let shouldFlattenEdits = flattenEdits;

    if (options && options.forceFlattenDoc) {
      shouldFlattenEdits = true;
    }

    let notaryLiveSkipApproveStep = false;

    if (options && options.notaryLiveSkipApproveStep) {
      notaryLiveSkipApproveStep = true;
    }

    let saveInputs = {
      documentId: documentId,
      flattenEdits: shouldFlattenEdits,
      isIframe: urlParams.iframe,
      name: pdfName,
      notaryLiveSkipApproveStep: notaryLiveSkipApproveStep,
      notarySavingFinalEdits: isMode('cls', 'notary') && shouldFlattenEdits,
      objects: isMode('esign', 'esign_preorder') ? cleanedObjects() : allObjects,
      originalPagesRemaining: originalPagesRemaining,
      pagesMetadata: pages,
      pdfFile: pdfFile,
    };

    try {
      await save(saveInputs);
    } catch (e) {
      console.log(e);
    } finally {
      saving = false;
    }
  }

  function scrollToElement(id) {
    let el = document.getElementById(id);
    setTimeout(() => {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 500);
  }

  async function scrollToNextUnplacedAnnotation() {
    let annotationsGrouped = getAllAnnotations();
    let nextAnnotation = null;
    let nextAnnotationPageIndex = null;

    for (let i = 0; i < annotationsGrouped.length; i++) {
      let annotations = annotationsGrouped[i];
      for (let j = 0; j < annotations.length; j++) {
        let annotation = annotations[j];
        if (annotation.autoPlacedAssociatedObject) continue;

        nextAnnotation = annotation;
        nextAnnotationPageIndex = i;
        break;
      }

      if (nextAnnotation) break;
    }

    if (!nextAnnotation || !nextAnnotationPageIndex) return;

    if (selectedPageIndex === nextAnnotationPageIndex) return;

    scrollToElement('text-field-' + nextAnnotationPageIndex + '-' + nextAnnotation.id);
    selectPage(nextAnnotationPageIndex);
  }

  function segmentedDatesAreCurrentOrFuture(groupedSegmentedDateObjects) {
    for (let group in groupedSegmentedDateObjects) {
      let month = groupedSegmentedDateObjects[group].month.lines[0];
      let day = groupedSegmentedDateObjects[group].day.lines[0];
      let year = groupedSegmentedDateObjects[group].year.lines[0];

      if (!dateIsCurrentOrFuture(month, day, year)) return false;
    }

    return true;
  }

  function selectFontFamily(objectId, payload) {
    const name = payload.name;
    fetchFont(name);
    currentFont = name;
    updateObject(objectId, { fontFamily: name });
  }

  function selectPage(index) {
    editorToolsVisible = false;
    selectedPageIndex = index;
  }

  function setActionElementsForNewActionGroupAnnotation(inputs) {
    if (inputs.actionElements) {
      return inputs.actionElements;
    }

    let output = [{
      x: 5,
      y: 5,
    }];

    if (inputs.type === 'radioBtn') {
      output.push({
        x: 50,
        y: 5,
      });
    }

    return output;
  }

  function setTextFieldOptionDefaults(text, options) {
    if (!options.autoPlacedAssociatedObject) {
      options.autoPlacedAssociatedObject = null;
    }

    if (!options.fontFamily) {
      fetchFont(currentFont);
      options.fontFamily = currentFont;
    }

    if (!options.lines) {
      options.lines = [text];
    }

    if (!options.lockedInPlace) {
      options.lockedInPlace = false;
    }

    if (!options.pageIndex && options.pageIndex !== 0) {
      options.pageIndex = selectedPageIndex;
    }

    if (!options.textSize) {
      options.textSize = 16;
    }

    if (!options.width) {
      options.width = 100;
    }

    if (!options.x) {
      options.x = 0;
    }

    if (!options.y) {
      options.y = 0;
    }

    if (!options.subtype) {
      options.subtype = null;
    }

    if (!options.groupId) {
      options.groupId = null;
    }

    if (!options.isGrouped) {
      options.isGrouped = !!options.groupId;
    }

    return options;
  }

  function shiftExistingEdits(newOriginalPagesRemaining) {
    let pagesToRemove = originalPagesRemaining.filter((page) => {
      return !newOriginalPagesRemaining.includes(page);
    });

    pagesToRemove.forEach((page) => {
      allObjects.splice(page, 1);
    });

    originalPagesRemaining = newOriginalPagesRemaining;
  }

  function shouldHideAnnotation(pageObject) {
    if (annotationsOverride === 'showAll') {
      return false;
    }

    let email = pageObject.participant && pageObject.participant.email;

    if (isRemoteParticipantSinger) {
      return !isMode('notary') && (currentParticipantEmail !== email);
    }
      
    return getFirstCurrentParticipantWithEmail(email) === null;
  }

  function showMessageToUser(message, messageType = null) {
    if (!urlParams.iframe) {
      return alert(message);
    }

    window.parent.postMessage({
      type: 'show-message',
      message: message,
      messageType: messageType,
    }, "*");
  }

  function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  async function testIfPdfCanBeSaved(file) {
    let failedToLoad = false;
    let errorMessage = 'This file is not supported. Please try another PDF.';
    const PDFLib = await getAsset('PDFLib');

    if (!failedToLoad && file.type === 'text/html') {
      console.log('File is not a PDF');
      console.log('File mime: ' + file.type);
      failedToLoad = true;
    }

    if (!failedToLoad && file.type === 'application/pdf') {
      try {
        await PDFLib.PDFDocument.load(
          await readAsArrayBuffer(file)
          // {ignoreEncryption: true} // Can be used to load encrypted PDFs, but these files won't be downloadable
        );
      } catch (e) {
        if (e.message.includes('encrypt'), 'a') {
          console.log('File is encrypted');
          errorMessage = 'This file is encrypted or password protected. Please try another PDF.';
          failedToLoad = true;
        }
      }
    }

    if (failedToLoad) {
      showMessageToUser(errorMessage, 'failed-to-load-pdf');
    }
  }

  function turnOffDocumentPanning() {    
    docPanningEnabled = false;
    document.documentElement.style.cursor = 'default';
    window.removeEventListener("mousemove", panPageOnMousedragEvent);
  }

  function turnOnDocumentPanning() {
    if (!sessionStorage.getItem('alreadyShowedPanningInstructions')) {
      showPanningInstructions = true;
      sessionStorage.setItem('alreadyShowedPanningInstructions', true);
    }

    docPanningEnabled = true;
    document.documentElement.style.cursor = 'move';
    window.addEventListener("mousemove", panPageOnMousedragEvent);
  }

  function updateIsMobile() {
    isMobile = (
      window.innerWidth <= 480
      || window.innerHeight <= 480
    );
  }

  function updateObject(objectId, payload, pageIndex) {
    emitDocumentModified({
      action: 'update',
      target: 'object',
      objectId: objectId,
      payload: payload,
    });

    allObjects = allObjects.map((objects, pIndex) => {
      return objects.map(object => {
        if (object.id === objectId) {
          return { ...object, ...payload };
        }

        return object;
      });
    });

    if (payload.eventType === 'dragEnd'
      && pageIndex !== undefined
      && pageIndex !== null
      && !objectInsidePage(objectId, pageIndex, 35)
    ) {
      deleteObject(objectId, pageIndex);
    }
  }

  function verifyDatesAreCurrentOrFuture() {
    let dateObjects = allObjects.map((pageObjects, pageIndex) => {
      return pageObjects.filter((pageObject) => {
        return pageObject.subtype === 'date';
      });
    });

    dateObjects = dateObjects.flat();

    let groupedSegmentedDateObjects = groupSegmentedDateObjects(dateObjects, 'groupId');
    let fullDateObjects = dateObjects.filter((dateObject) => {
      return !dateObject.groupId;
    });

    if (Object.keys(groupedSegmentedDateObjects).length > 0 && segmentedDatesAreCurrentOrFuture(groupedSegmentedDateObjects) === false) {
      return emitDatesOutdated();
    }

    if (fullDateObjects.length > 0 && fullDatesAreCurrentOrFuture(fullDateObjects) === false) {
      return emitDatesOutdated();
    }

    return emitDatesCurrent();
  }

  function waitForAnnotationActionCompletion(event, annotation) {
    if (annotation.actionPerformed) return;

    let checkToPlace = setInterval(() => {
      if (placeObjectAfterWait) {
        handleActionableAnnotationClick(event);
        clearInterval(checkToPlace);
        return placeObjectAfterWait = false;
      }
    }, 250);

    if (checkToPlace) {
      setTimeout(() => {
        return clearInterval(checkToPlace);
      }, 30000);
    }
  }

  function waitForObjectPlacement(event, annotation) {
    if (annotation.annotation.autoPlacedAssociatedObject) return;

    let checkToPlace = setInterval(() => {
      if (placeObjectAfterWait) {
        handleTextClick(event);
        clearInterval(checkToPlace);
        return placeObjectAfterWait = false;
      }
    }, 250);

    if (checkToPlace) {
      setTimeout(() => {
        clearInterval(checkToPlace);
      }, 30000);
    }
  }

  // We have to fix this to account for grouped radio buttons as well (RA, Nov 2024)
  // --
  function scrollToNextActionableAnnotation() {
    let notPerformedActionableAnnotations = getNotPerformedActionableAnnotations(getCurrentParticipantEmails());

    if (notPerformedActionableAnnotations.length === 0) return;

    let nextAnnotation = notPerformedActionableAnnotations[0];

    scrollToElement('actionable-annotation-' + nextAnnotation.pageIndex + '-' + nextAnnotation.id);
  }

  function zoomIn() {
    if (!canZoomInFurther()) {
      return null;
    }

    zoomTo(window.ES_zoomLevel + zoomStepFactor);
  }

  function zoomOut() {
    if (window.ES_zoomLevel <= 1) {
      return null;
    }

    zoomTo(window.ES_zoomLevel - zoomStepFactor);
  }

  function zoomTo(input) {
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const viewportCenterX = scrollX + windowWidth / 2;
    const viewportCenterY = scrollY + windowHeight / 2;
    const zoomRatio = input / window.ES_zoomLevel;

    window.ES_zoomLevel = input;
    document.body.style.zoom = window.ES_zoomLevel;
    document.getElementById('blue-bar').style.zoom = 1 / window.ES_zoomLevel;
    computePageTagStyle();

    if (documentIsWiderThanWindow()) {
      turnOnDocumentPanning();
    } else {
      turnOffDocumentPanning();
    }

    const newScrollX = viewportCenterX * zoomRatio - windowWidth / 2;
    const newScrollY = viewportCenterY * zoomRatio - windowHeight / 2;
    window.scrollTo(newScrollX, newScrollY);
  }

  // -----------
  // - Objects -
  // -----------

  var draggableObjectFactory = {
    addNotaryStamp: async function(presavedStamps) {
      const imgBlob = await (await fetch(notaryStampUrl)).blob();

      if (presavedStamps) {
        await addImage(imgBlob, {
          imgName: presavedStamps.imgName,
          imgSrc: notaryStampUrl,
          initialWidth: presavedStamps.width,
          pageIndex: presavedStamps.pageIndex,
          x: presavedStamps.x,
          y: presavedStamps.y,
          subtype: 'notary_stamp',
        });

        editorToolsVisible = false;
        selectedPageIndex = 0;  /// this resets the pageIndex back  to the first page after all the presaved images are added to the document (AA, Oct 2022)
        return;
      }

      await addImage(imgBlob, {
        imgName:'add_notary_stamp',
        imgSrc: notaryStampUrl,
        initialWidth: 210,
        pageIndex: selectedPageIndex,
        x: editorToolsXPosition,
        y: editorToolsYPosition,
        subtype: 'notary_stamp',
      });

      editorToolsVisible = false;
    },

    addNotarySignature: async function(presavedSignatures) {
      const imgBlob = await (await fetch(notarySignatureUrl)).blob();

      if (presavedSignatures) {
        await addImage(imgBlob, {
          imgName: 'add_notary_signature',
          imgSrc: notarySignatureUrl,
          initialWidth: presavedSignatures.width,
          pageIndex: presavedSignatures.pageIndex,
          x: presavedSignatures.x,
          y: presavedSignatures.y,
          subtype: 'notary_signature',
        });

        selectedPageIndex = 0;   /// this resets the pageIndex back to the first page after all the presaved images are added to the document (AA, OCt 2022)
        editorToolsVisible = false;
        return;
      }

      await addImage(imgBlob, {
        imgName: 'add_notary_signature',
        imgSrc: notarySignatureUrl,
        initialWidth: defaultSignatureWidth,
        pageIndex: selectedPageIndex,
        x: editorToolsXPosition,
        y: editorToolsYPosition,
        subtype: 'notary_signature',
      });
      editorToolsVisible = false;
    },

    addOneTimeSignature: async function(event) {
      const isCorrectMode = isMode('ipen');

      if (isCorrectMode && oneTimeSignature === null) {
        emitPromptForOneTimeSignature();

        let checkToPlaceInterval = setInterval(() => {
          if (placeObjectAfterWait) {
            draggableObjectFactory.addOneTimeSignature(event);
            clearInterval(checkToPlaceInterval);
            placeObjectAfterWait = false;
          }
        }, 250);

        setTimeout(() => {
          clearInterval(checkToPlaceInterval);
        }, 30000);

        return;
      }

      const imageUrl = oneTimeSignature;
      const imgBlob = await (await fetch(imageUrl)).blob();

      await addImage(imgBlob, {
        imgName:'add_one_time_signature',
        imgSrc: imageUrl,
        initialWidth: 210,
        pageIndex: selectedPageIndex,
        x: event.detail.x ? event.detail.x : editorToolsXPosition,
        y: event.detail.y ? event.detail.y : editorToolsYPosition,
        subtype: 'one_time_signature',
      });

      editorToolsVisible = false;
      oneTimeSignature = null;
    },

    addParticipantInitials: async function (event) {
      const isCorrectMode = isMode('esign', 'esign_preorder',  'cls', 'ipen');
      const hasNoInitials = event.detail.signatureData === undefined
          || event.detail.signatureData === null 
          || event.detail.signatureData.initials === null 
          || event.detail.signatureData.initials === 'empty';

      if (isCorrectMode && hasNoInitials && !event.detail.objectId) {
        return emitPromptForInitials(true, event.detail.participant);
      }

      if (isCorrectMode && hasNoInitials && event.detail.objectId) {
        emitPromptForInitials(true, event.detail.participant);
        return waitForAnnotationActionCompletion(event, dumbGetObject(event.detail.objectId));
      }

      const imageUrl = event.detail.signatureData.initials;
      const imgBlob = await (await fetch(imageUrl)).blob();

      let creationParams = {
        autoPlaced: !!event.autoPlaced,
        imgName: 'add_participant_initials',
        imgSrc: imageUrl,
        signatureOrInitialsDetails: event.detail,
        subtype: 'participant_initials',
      };

      if (event.isPresaved) {
        creationParams.imgName = event.imgName;
        creationParams.initialWidth = event.width;
        creationParams.pageIndex = event.pageIndex;
        creationParams.x = event.x;
        creationParams.y = event.y;
      } else {
        creationParams.imgName = 'add_participant_initials';
        creationParams.initialWidth = defaultInitialsWidth;
        creationParams.pageIndex = event.autoPlaced ? event.pageIndex : selectedPageIndex;
        creationParams.x = event.detail.x ? event.detail.x : editorToolsXPosition;
        creationParams.y = event.detail.y ? event.detail.y : editorToolsYPosition;      
      }

      let imageObject = await addImage(imgBlob, creationParams);

      if (event.detail.objectId !== undefined && imageObject) {
        updateObject(event.detail.objectId, { associatedObjectId: imageObject.id });
      }

      if (event.isPresaved) {
        selectedPageIndex = 0;  /// this resets the pageIndex back to the first page after all the presaved images are added to the document (AA, Oct 2022)
      }

		  editorToolsVisible = false;
	  },

    addParticipantSignature: async function (event) {
      const isCorrectMode = isMode('cls', 'esign', 'esign_preorder', 'ipen');

      const hasNoSignature = event.detail.signatureData === undefined
          || event.detail.signatureData === null 
          || event.detail.signatureData.signature === null
          || event.detail.signatureData.signature === 'empty';

      if (isCorrectMode && hasNoSignature && !event.detail.objectId) {
        return emitPromptForSignature(true, event.detail.participant);
      }

      if (isCorrectMode && hasNoSignature && event.detail.objectId) {
        emitPromptForSignature(true, event.detail.participant);
        return waitForAnnotationActionCompletion(event, dumbGetObject(event.detail.objectId));
      }

      const imageUrl = event.detail.signatureData.signature;
      const imgBlob = await (await fetch(imageUrl)).blob();

      let creationParams = {
        autoPlaced: event.autoPlaced,
        imgName: 'add_participant_signature',
        imgSrc: imageUrl,
        signatureOrInitialsDetails: event.detail,
        subtype: 'participant_signature',
      };

      if (event.isPresaved) {
        creationParams.imgName = event.imgName;
        creationParams.initialWidth = event.width;
        creationParams.pageIndex = event.pageIndex;
        creationParams.x = event.x;
        creationParams.y = event.y;
      } else {
        creationParams.imgName = 'add_participant_signature';
        creationParams.initialWidth = event.width || defaultSignatureWidth;
        creationParams.pageIndex = event.autoPlaced ? event.pageIndex : selectedPageIndex;
        creationParams.x = event.detail.x ? event.detail.x : editorToolsXPosition;
        creationParams.y = event.detail.y ? event.detail.y : editorToolsYPosition;
      }

      if (event.height) {
        creationParams.initialHeight = event.height;
      }

      let imageObject = await addImage(imgBlob, creationParams);

      if (event.detail.objectId !== undefined && imageObject) {
        updateObject(event.detail.objectId, { associatedObjectId: imageObject.id });
      }

      if (event.isPresaved) {
        selectedPageIndex = 0;  /// this resets the pageIndex back to the first page after all the presaved images are added to the document (AA, Oct 2022)
      }

      editorToolsVisible = false;
    },

    addParticipantSigningDate: function (options) {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      let yyyy = today.getFullYear();
      options.subtype = 'participant_signing_date';

      addTextField(`${mm}/${dd}/${yyyy}`, options);
    },

    addWhiteoutBox: function (event) {
      editorToolsVisible = false;
      const whitout_box_url = 'whiteout-box.jpeg';

      fetch(whitout_box_url).then(response => {
        return response.blob();
      }).then(async (blob) => {
        await addImage(blob, {
          imgName:'add_whiteout_box',
          imgSrc: '/' + whitout_box_url,
          initialWidth: event.isPresaved ? event.width : 100,
          pageIndex: event.isPresaved ? event.pageIndex : selectedPageIndex,
          x: event.detail.x,
          y: event.detail.y,
          subtype: 'whiteout_box',
        });

        if (event.isPresaved) {
          selectedPageIndex = 0;  /// this resets the pageIndex back to the first page after all the presaved images are added to the document (AA, OCt 2022)
        }
      });
    },
  }

  // ---------------------------
  // - Lifecycle Event Binding -
  // ---------------------------

  onMount(() => {
    updateIsMobile();
    window.addEventListener('resize', handleBrowserResize);
    window.addEventListener('pointerdown', () => mouseIsDown = true);
    window.addEventListener('pointerup', handlePointerUp);

    urlParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (urlParams.pdf) {
      loadRemotePdf(urlParams.pdf);
    }

    if (urlParams.iframe) {
      window.parent.postMessage({
        type: 'iframe-loaded',
      }, "*");
      registerIframeParentEvents();
    }

    if (urlParams.flatten !== '0') {
      flattenEdits = true;
    }

    if (urlParams.debug) {
      handleDebugMode({
        detail: {
          debug: true,
          command: urlParams.debug
        }
      });
    }

    monitorHumanActivity();
  });

  // ---------------------------------------------
  // - Watchers to react to changes in variables -
  // ---------------------------------------------
  
  // Helpful for debugging
  // $: allObjects, console.log(allObjects); 
  $: pdfFile, onPdfFileChange();

</script>

<style>
  .fade-in-out {
    animation: fadeInOut 2s linear infinite;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0.2;
    }
    33% {
      opacity: 0.7;
    }
    66% {
      opacity: 1;
    }
    100% {
      opacity: 0.7;
    }
  }

  /* For more info on tooltips: */
  /* https://www.w3schools.com/css/css_tooltip.asp */
  /* https://www.w3schools.com/css/tryit.asp?filename=trycss_tooltip_arrow_top */
  /* -- */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
</style>

<!-- ====== -->
<!-- Markup -->
<!-- ====== -->

<svelte:window
  on:dragenter|preventDefault
  on:dragover|preventDefault
  on:drop|preventDefault={onUploadPDF} />
<svelte:options accessors></svelte:options>

<Tailwind />

<!-- ====== -->
<!-- Modals -->
<!-- ====== -->

{#if chooseNewPageTypeModalVisible}
	<ChooseNewPageTypeModal
    on:addDocument={(e) => {mergePdfIntoEditorFromUrl(e.detail.url)}}
    on:uploadDocument={(e) => {mergePdfIntoEditorFromFile(e.detail.file)}}
		on:closeModal={() => chooseNewPageTypeModalVisible = false}
    notaryData={notaryData}
	/>
{/if}

{#if simpleModalVisible}
	<SimpleModal
		on:closeModal={() => simpleModalVisible = false}
		modalButtonText={simpleModalButtonText}
		modalMessage={simpleModalMessage}
		modalTitle={simpleModalTitle}
	/>
{/if}

{#if twoChoicesModalVisible}
	<TwoChoicesModal
		on:closeModal={() => twoChoicesModalVisible = false}
		on:ctaClicked={() => twoChoicesModalCtaAction()}
		twoChoicesModalCtaText={twoChoicesModalCtaText}
		twoChoicesModalMessage={twoChoicesModalMessage}
		twoChoicesModalOtherBtnText={twoChoicesModalOtherBtnText}
		twoChoicesModalTitle={twoChoicesModalTitle}
	/>
{/if}

<!-- ========== -->
<!-- End Modals -->
<!-- ========== -->

{#if showInteractionInstructions && !lockEditing}
  <div
      class="fixed inset-0 z-50 flex items-center justify-center"
      style="background-color: rgba(248, 250, 252, 0.5);"
      on:click={event => handleInteractionInstructionsClick(event)}
  >
    <div
        class="flex flex-col items-center rounded-lg shadow-lg w-full bg-white text-gray-700 px-6 pt-6 pb-2 text-center"
        style="max-width: 272px"
        on:click={event => handleInteractionInstructionsClick(event)}
    >
      <strong>Click or tap on the document to start editing.</strong>
      <GestureFingerTouch/>
    </div>
  </div>
{/if}

{#if showPanningInstructions}
  <div 
    class="fixed"
    style="z-index:100"
  >
    <div
        class="fixed inset-0 flex items-center justify-center"
        style="background-color: rgba(248, 250, 252, 0.5);"
        on:mousedown={event => showPanningInstructions = false}
    >
      <div
          class="flex flex-col items-center rounded-lg shadow-lg w-full bg-white text-gray-700 px-6 pt-6 pb-2 text-center"
          style="max-width: 272px"
          on:mousedown={event => showPanningInstructions = false}
      >
        <strong>Click and drag to view the rest of the page.</strong>
        <GestureFingerTouch/>
      </div>
    </div>
  </div>
{/if}

<div>
  <main 
    class="flex flex-col py-16 bg-gray-100 min-h-screen"
  >
    <div
      class="bg-gray-100 fixed inset-0 opacity-35 z-20"
      style="display: {lockEditing  || shouldLockDocumentEditing ? 'block' : 'none'}"
    >
      <div class="flex items-center justify-center h-full w-full opacity-90">
        <p class="fade-in-out text-red-500 text-6xl" style="text-align:center;"><b>Review Mode</b></p>
      </div>
    </div>

    <div
      class="bg-gray-200 border-b border-gray-300 fixed flex h-12 items-center justify-center left-0 right-0 top-0 z-10"
      id="blue-bar"
      style="position:fixed; z-index:1000"
    >
      <input
        type="file"
        name="pdf"
        id="pdf"
        on:change={onUploadPDF}
        class="hidden"
      />

      <input
        type="file"
        id="image"
        name="image"
        class="hidden"
        on:change={onUploadImage}
      />

      {#if urlParams.showBack}
        <button
          on:pointerup={emitBack}
          class="bg-white font-bold hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
        >
  			  <img
  					src="/back.svg"
  					alt="Back button"
  				/>

          <span class="tooltiptext">Back</span>
  			</button>
      {/if}

      {#if !urlParams.iframe}
        <label
          class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
          font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
          for="pdf">
          Choose PDF
        </label>
      {/if}

      {#if pages.length}
        <!-- Hiding, but could be handy later. (RA, Aug 2022) -->
        <!-- {#if !urlParams.iframe}
          <div class="justify-center mr-3 md:mr-4 w-full max-w-xs hidden md:flex">
            <img src="/edit.svg" class="mr-2" alt="a pen, edit pdf name" />
            <input
              placeholder="Rename your PDF here"
              type="text"
              class="flex-grow bg-transparent"
              bind:value={pdfName} />
          </div>
        {/if} -->

        <!-- The rotate buttons are done on the visual side, but we still need to fix the saving of the final pdf file side of things. We also need to take into account how this works with draggables on the page, and for adding and removing pages of the document. For the current draggables, we could just clear them all out during rotation as a plan B strategy. (RA, Sept 20, 2022) -->

        <!-- <button
          on:pointerup={() => {rotatePage(-1)}}
          class="bg-white font-bold hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
        >
          <img 
            src="/rotate-page-counterclockwise.svg" 
            alt="Rotate Page Counterclockwise"
          />

          <span class="tooltiptext">Rotate Page Counterclockwise</span>
        </button>

        <button
          on:pointerup={() => {rotatePage(1)}}
          class="bg-white font-bold hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
        >
          <img 
            src="/rotate-page-clockwise.svg" 
            alt="Rotate Page Clockwise"
          />

          <span class="tooltiptext">Rotate Page Clockwise</span>
        </button> -->

  			{#if isMode('ipen', 'notary')}
  				<button
  					on:pointerup={deletePage}
  					class="bg-white font-bold hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
  				>
  					<img
  						src="/delete-page.svg"
  						alt="Delete Page"
  					/>

            <span class="tooltiptext">Delete Page</span>
  				</button>
        {/if}

        {#if isMode('notary')}
          <button
            on:pointerup={handleRejectSignaturesClick}
            class="bg-white font-bold hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
          >
            <img
              src="/reject-signatures.svg"
              alt="Reject Signatures"
            />
            <span class="tooltiptext">Reject Signatures</span>
          </button>
        {/if}
    
        {#if showRejectParticipantsListModal}
          <RejectParticipantsListModal
            participantsData={participants}
            on:closeModal={() => (showRejectParticipantsListModal = false)}
            on:rejectSignature={(e) => emitRejectSignatures(e.detail)}
          />
        {/if}

        {#if isMode('ipen', 'notary')}
  				<button
  					on:pointerup={() => chooseNewPageTypeModalVisible = true}
  					class="bg-white font-bold hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
  				>
  					<img
  						src="/add-document-to-file.svg"
  						alt="Add Document to File"
  					/>

            <span class="tooltiptext">Add Documents</span>
          </button>
  			{/if}

        {#if !isMobile && isMode('esign', 'esign_preorder', 'ipen', 'preorder', 'ron_order', 'order_template')}
          <button
            on:pointerup={zoomIn}
            class="bg-white font-bold h-10 hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
            style=""
          >
            <IconZoomIn style="width: 28px; height: 28px;" />
          </button>
        {/if}

        {#if !isMobile && isMode('esign', 'esign_preorder', 'ipen', 'preorder', 'ron_order', 'order_template')}
          <button
            on:pointerup={zoomOut}
            class="bg-white font-bold h-10 hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
          >
            <IconZoomOut style="width: 28px; height: 28px;" />
          </button>
        {/if}

        {#if !isMode('cls', 'esign', 'esign_preorder', 'ipen', 'notary', 'preorder', 'ron_order', 'order_template') || debugShowSaveButton}
          <button
            on:pointerup={savePDF}
            class="bg-white font-bold hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
            class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
            class:bg-gray-200={pages.length === 0 || saving || !pdfFile}
          >
            {#if showGreenSaveSvg}
              <img
                src="/green-save.svg"
                alt="Save Document"
              />
            {:else}
              <img
                src="/save.svg"
                alt="Save Document"
              />
            {/if}

            <span class="tooltiptext">
              {#if showGreenSaveSvg}
                {greenSaveSvgPopoverText}
              {:else}
                Save
              {/if}
            </span>
          </button>
        {/if}
      {/if}

      {#if urlParams.showMore && urlParams.mode !== 'notary'}
        <button
          on:pointerup={emitClickedMoreBtn}
          class="bg-white font-bold hover:bg-gray-100 md:px-1 mx-2 px-1 py-1 rounded tooltip w-10"
        >
  			  <img
  					src="/more.svg"
  					alt="More button"
  				/>

          <span class="tooltiptext">More</span>
  			</button>
      {/if}
    </div>

    {#if addingDrawing}
      <div
        transition:fly={{ y: -200, duration: 500 }}
        class="fixed z-10 top-0 left-0 right-0 border-b border-gray-300 bg-white
        shadow-lg"
        style="height: 50%;">
        <DrawingCanvas
          on:finish={e => {
            const { originWidth, originHeight, path } = e.detail;
            let scale = 1;
            if (originWidth > 500) {
              scale = 500 / originWidth;
            }
            addDrawing(originWidth, originHeight, path, scale);
            addingDrawing = false;
          }}
          on:cancel={() => (addingDrawing = false)} />
      </div>
    {/if}

    {#if pages.length}
      {#if !urlParams.iframe}
        <div class="flex justify-center px-5 w-full md:hidden">
          <img src="/edit.svg" class="mr-2" alt="a pen, edit pdf name" />
          <input
            placeholder="Rename your PDF here"
            type="text"
            class="flex-grow bg-transparent"
            bind:value={pdfName} />
        </div>
      {/if}

      <div class="w-full">
        {#each pages as page, pIndex (page)}
          <div
            on:enter={() => {selectPage(pIndex);}}
            on:pointerdown={(event) => handlePointerDown(event)}
            on:pointerup={(event) => handleClickedPage(pIndex, event)}
            use:inview={{threshold: 0.5}}
            class="flex flex-col py-5 w-full"
            id={`page-${pIndex}`}
            style={pageTagStyle}
          >
            <div
              id={`page-${pIndex}`}
              class="relative shadow-lg"
              class:opacity-35={pIndex !== selectedPageIndex}
            >
              <PDFPage
                on:measure={e => onMeasure(e.detail.scale, pIndex)}
                on:onPageRotationMeasured={e => onPageRotationMeasured(e)}
                on:onPageSizeMeasured={e => onPageSizeMeasured(e)}
                isActive={pIndex === selectedPageIndex}
                isMobile={isMobile}
                page={page}
                pageIndex={pIndex}
                rotationDirection={rotationDirection}
                rotationKey={rotationKeys[pIndex]}
              />

              <PageNumber
                  page={page}
                  pageIndex={pIndex}
                  totalPages={pages.length}
                  pageScale={pagesScale[pIndex]}
                  pageSelected={pIndex === selectedPageIndex}
              />

              {#if pIndex === selectedPageIndex && editorToolsVisible}
                <ContextMenu
                  on:addDrawing={() => onAddDrawing()}
                  on:addInitials={e => draggableObjectFactory.addParticipantInitials(e)}
                  on:addNotarySignature={() => draggableObjectFactory.addNotarySignature()}
                  on:addNotaryStamp={() => draggableObjectFactory.addNotaryStamp()}
                  on:addSignature={e => draggableObjectFactory.addParticipantSignature(e)}
                  on:addOneTimeSignature={e => draggableObjectFactory.addOneTimeSignature(e)}
                  on:addText={e => addTextFromDialogBox(e)}
                  on:addActionableAnnotation={e => addAnnotationFromDialogBox(e)}
                  on:addActionGroupAnnotation={e => addActionGroupAnnotationFromDialogBox(e)}
                  on:addWhiteoutBox={e => draggableObjectFactory.addWhiteoutBox(e)}
                  on:close={() => {editorToolsVisible = false; tapoutLastTick = false;}}
                  on:tapout={() => tapoutLastTick = true}
                  on:promptForSignature={(e) => emitPromptForSignature(false, e.detail.participant)}
                  on:promptForInitials={(e) => emitPromptForInitials(false, e.detail.participant)}
                  on:promptForOneTimeSignature={(e) => emitPromptForOneTimeSignature()}
                  on:promptForNotaryToolsNotReady={() => emitPromptForNotaryToolsNotReady()}
                  on:placedObjectAfterWait={() => placeObjectAfterWait = false}
                  on:zoomIn={() => zoomIn()}
                  on:zoomOut={() => zoomOut()}
                  canZoomInFurther={canZoomInFurther}
                  clickedXPosition={clickedXPosition}
                  clickedYPosition={clickedYPosition}
                  correctedXPosition={editorToolsXPosition}
                  correctedYPosition={editorToolsYPosition}
                  isIframe={urlParams.iframe}
                  isMobile={isMobile}
                  notaryData={notaryData}
                  notarySignatureUrl={notarySignatureUrl}
                  notaryStampUrl={notaryStampUrl}
                  participants={isMode('esign_preorder', 'ipen', 'preorder', 'cna_preorder') ? participants : currentParticipants}
                  participantSignatures={participantSignatures}
                  placeObjectAfterWait={placeObjectAfterWait}
                  canUseAllNotaryTools={canUseAllNotaryTools}
                  userHasFullEditingPermissions={userHasFullEditingPermissions}
                />
              {/if}

              <div
                class="absolute top-0 left-0 transform origin-top-left"
                style="transform: scale({pagesScale[pIndex]}); touch-action: none;"
              >
                {#each allObjects[pIndex] as object (object.id)}
                  {#if object.type === 'image'}
                    <Image
                      on:update={e => updateObject(object.id, e.detail, pIndex)}
                      on:delete={() => deleteObject(object.id)}
                      disableEditing={object.disableEditing}
                      file={object.file}
                      height={object.height}
                      imgName={object.imgName}
                      isFocusedProp={!!object.isFocused}
                      isWhiteoutBox={object.subtype === 'whiteout_box'}
                      initialHeight={object.initialHeight}
                      initialWidth={object.initialWidth}
                      pageScale={pagesScale[pIndex]}
                      payload={object.payload}
                      width={object.width}
                      x={object.x}
                      y={object.y}
                    />
                  {:else if object.type === 'text'}
                    <Text
                      on:editingText={() => {editingText = true}}
                      on:delete={() => deleteObject(object.id)}
                      on:selectFont={e => selectFontFamily(object.id, e.detail)}
                      on:stoppedEditingText={markStoppedEditingText}
                      on:update={e => updateObject(object.id, e.detail, pIndex)}
                      on:tapoutDialog={() => {tapoutLastTick = true}}
                      on:tapout={() => tapoutLastTick = false}
                      on:debug={e => {handleDebugMode(e)}}
                      on:click={e => {handleTextClick(e)}}
                      bind:simpleModalButtonText={simpleModalButtonText}
                      bind:simpleModalMessage={simpleModalMessage}
                      bind:simpleModalTitle={simpleModalTitle}
                      bind:simpleModalVisible={simpleModalVisible}
                      createdFromContextMenu={object.createdFromContextMenu}
                      currentActivePage={selectedPageIndex}
                      disableEditing={object.disableEditing}
                      editorMode={urlParams.mode}
                      fontFamily={object.fontFamily}
                      id={object.id}
                      isAnnotation={object.isAnnotation}
                      isDynamicField={object.annotationType === 'dynamic_field'}
                      isFocusedProp={!!object.isFocused}
                      lineHeight={object.lineHeight}
                      lines={object.lines}
                      lockedInPlace={object.lockedInPlace}
                      pageIndex={pIndex}
                      pageScale={pagesScale[pIndex]}
                      size={object.size}
                      text={object.text}
                      urlParams={urlParams}
                      x={object.x}
                      y={object.y}
                    />
                  {:else if object.type === 'actionable-annotation'}
                    <ActionableAnnotation
                      on:click={e => handleActionableAnnotationClick(e)}
                      on:completedByClient={e => {handleAnnotationCompletedByClient(e)}}
                      on:delete={e => deleteObject(object.id)}
                      on:update={e => updateObject(object.id, e.detail, pIndex)}
                      associatedObjectId={object.associatedObjectId}
                      hidden={object.hidden}
                      frozen={object.frozen}
                      id={object.id}
                      pageIndex={pIndex}
                      pageScale={pagesScale[pIndex]}
                      participant={object.participant}
                      type={object.annotationType}
                      x={object.x}
                      y={object.y}
                      width={object.width}
                      height={object.height}
                    />
                  {:else if object.type === 'action-group-annotation'}
                    <ActionGroupAnnotation
                      on:completedByClient={e => {handleAnnotationCompletedByClient(e)}}
                      on:delete={e => deleteObject(object.id)}
                      on:update={e => updateObject(object.id, e.detail, pIndex)}
                      actionElementsProp={object.actionElements}
                      actionGroupType={object.actionGroupType}
                      elementSize={object.elementSize}
                      filledElementsProp={object.filledElements}
                      frozen={object.frozen}
                      height={object.height}
                      hidden={object.hidden}
                      id={object.id}
                      pageIndex={pIndex}
                      pageScale={pagesScale[pIndex]}
                      participant={object.participant}
                      type={object.annotationType}
                      width={object.width}
                      x={object.x}
                      y={object.y}
                    />
                  {:else if object.type === 'drawing'}
                    <Drawing
                      on:update={e => updateObject(object.id, e.detail, pIndex)}
                      on:delete={() => deleteObject(object.id)}
                      path={object.path}
                      x={object.x}
                      y={object.y}
                      width={object.width}
                      originWidth={object.originWidth}
                      originHeight={object.originHeight}
                      pageScale={pagesScale[pIndex]} />
                  {/if}
                {/each}
              </div>
            </div>
          </div>
        {/each}
      </div>
    {:else}
      <div class="w-full flex-grow flex justify-center items-center">
        <span class="font-bold px-4 py-4 text-3xl text-gray-500">
          {#if urlParams.iframe || loadingPdf}
            <span class="fade-in-out">Loading...</span>
          {:else}
            <span>Drag something here or press 'Choose PDF' above to upload a PDF</span>
          {/if}
        </span>
      </div>
    {/if}
  </main>
</div>