<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g fill="none">
    <path d="M15,4.01v13.47"></path>
    <path d="M9,5.78v14.22"></path>
    <path
        d="M15.632,4.103l4.684,1.561c0.409,0.136 0.684,0.518 0.684,0.949v12.005c0,0.743 -0.782,1.227 -1.447,0.894l-3.658,-1.829c-0.563,-0.282 -1.226,-0.282 -1.789,0l-4.211,2.106c-0.563,0.282 -1.226,0.282 -1.789,0l-4.553,-2.276c-0.339,-0.17 -0.553,-0.516 -0.553,-0.895v-11.339c0,-0.683 0.669,-1.165 1.316,-0.949l4.051,1.35c0.411,0.137 0.854,0.137 1.265,0l4.735,-1.578c0.411,-0.136 0.855,-0.136 1.265,0.001Z"></path>
  </g>
</svg>