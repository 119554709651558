<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path d="M20.63,5.257l-0.888,-0.887c-0.391,-0.391 -1.024,-0.391 -1.414,0l-7.328,7.328v2.302h2.302l7.328,-7.328c0.39,-0.391 0.39,-1.024 0,-1.415Z"></path>
    <path d="M20.767,11c0.662,2.915 -0.133,6.094 -2.403,8.364"></path>
    <path d="M14,4.233c-2.915,-0.662 -6.094,0.133 -8.364,2.403c-3.515,3.515 -3.515,9.213 0,12.728"></path>
    <path d="M3.5,20h3v-3"></path>
  </g>
  <path d="M5.636,19.364c0.228,0.227 0.465,0.438 0.709,0.636"></path>
  <path d="M16.85,5.85l2.3,2.3"></path>
</svg>