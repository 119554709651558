<script>
  import {createEventDispatcher} from "svelte";
  import IconChevronRight from "../Icons/IconChevronRight.svelte";

  export let title;
  export let icon;
  export let hasSubMenu = false;
  export let style = '';

  const dispatch = createEventDispatcher();

  const methods = {
    emit: {
      click: () => dispatch("click"),
    },
  };
</script>

<style global>
    .context-menu-item {
        @apply flex items-center px-3 py-2 cursor-pointer;
    }

    .context-menu-item .label {
        @apply flex items-center gap-2 w-full pr-2;
    }

    .context-menu-item .label span {
        @apply text-base leading-5 text-gray-700;
    }

    .context-menu-item:hover .label span {
        @apply text-gray-900;
    }

    .context-menu-item svg {
        @apply text-gray-500;
    }

    .context-menu-item:hover svg {
        @apply text-gray-700;
    }
</style>

<div class="context-menu-item" 
  style={style}
  on:click={methods.emit.click}
>
  <div class="label text-blue-500">
    <svelte:component this={icon} />
    <span>{title}</span>
  </div>
  {#if hasSubMenu}
    <IconChevronRight />
  {/if}
</div>