export function pannable(node) {
  let x;
  let y;

  function calcZoomFactor() {
    let zoomFactor = window.ES_zoomLevel;

    if (zoomFactor !== 1) {
      zoomFactor = 1 / ( 1 * zoomFactor);
    }

    return zoomFactor;
  }

  // Can be a touch or mousemove event (RA, July 2024)
  // --
  function dispatchPanMoveEvent(event) {
    let zoomFactor = calcZoomFactor();
    const eventX = event.clientX * zoomFactor;
    const eventY = event.clientY * zoomFactor;

    const dx = eventX - x;
    const dy = eventY - y;

    x = eventX;
    y = eventY;

    node.dispatchEvent(
      new CustomEvent('panmove', {
        detail: { x, y, dx, dy },
      })
    );
  }

  function handleMousedown(event) {    
    if (shouldStopPannablePropagation(event)) {
      return;
    }

    let zoomFactor = calcZoomFactor();
    const eventX = event.clientX * zoomFactor;
    const eventY = event.clientY * zoomFactor;

    x = eventX;
    y = eventY;
    
    const target = event.target;

    node.dispatchEvent(
      new CustomEvent('panstart', {
        detail: { x, y, target },
      })
    );

    window.ES_panning_object = true;
    window.addEventListener('mousemove', handleMousemove);
    window.addEventListener('mouseup', handleMouseup);
  }

  function handleMousemove(event) {
    dispatchPanMoveEvent(event);
  }

  function handleMouseup(event) {
    x = event.clientX;
    y = event.clientY;

    node.dispatchEvent(
      new CustomEvent('panend', {
        detail: { x, y },
      })
    );

    window.ES_panning_object = false;
    window.removeEventListener('mousemove', handleMousemove);
    window.removeEventListener('mouseup', handleMouseup);
  }

  function handleTouchend(event) {
    const touch = event.changedTouches[0];
    x = touch.clientX;
    y = touch.clientY;

    node.dispatchEvent(
      new CustomEvent('panend', {
        detail: { x, y },
      })
    );

    window.removeEventListener('touchmove', handleTouchmove);
    window.removeEventListener('touchend', handleTouchend);
  }

  function handleTouchmove(event) {
    event.preventDefault();
    if (event.touches.length > 1) return;
    const touch = event.touches[0];
    dispatchPanMoveEvent(touch);
  }

  function handleTouchStart(event) {
    if (event.touches.length > 1) return;
    const touch = event.touches[0];
    x = touch.clientX;
    y = touch.clientY;
    const target = touch.target;

    node.dispatchEvent(
      new CustomEvent('panstart', {
        detail: { x, y, target },
      })
    );

    window.addEventListener('touchmove', handleTouchmove, { passive: false });
    window.addEventListener('touchend', handleTouchend);
  }

  function shouldStopPannablePropagation(event) {
    // Check if click target is within a nested pannable element
    const clickedPannable = event.target.closest('[data-stop-pannable-propagation="true"]');

    return clickedPannable && clickedPannable !== node;
  }

  node.addEventListener('mousedown', handleMousedown);
  node.addEventListener('touchstart', handleTouchStart);
  
  return {
    destroy() {
      node.removeEventListener('mousedown', handleMousedown);
      node.removeEventListener('touchstart', handleTouchStart);
    },
  };
}
