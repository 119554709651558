const AnnotationTypeFactory = (() => {
    function getBaseProperties(baseObjectProperties) {
      const { id, x, y, pageIndex, participant } = baseObjectProperties;
      return { id, x, y, pageIndex, participant };
    }
  
    function getActionableBase(baseObjectProperties) {
      const { width, height, fontSize, fontFamily, textColor } = baseObjectProperties;
      return {
        ...getBaseProperties(baseObjectProperties),
        type: 'actionable-annotation',
        width,
        height,
        fontSize,
        fontFamily: fontFamily || 'Helvetica',
        textColor: textColor || '#000000'
      };
    }
  
    const methods = {
      createTextAnnotation(baseObjectProperties) {
        const { fontFamily, textColor } = baseObjectProperties;
        return {
          ...getBaseProperties(baseObjectProperties),
          type: 'text',
          annotationType: 'text',
          width: 200,
          height: 50,
          lines: ['New text field...'],
          fontFamily: fontFamily || 'Helvetica',
          size: 16,
          textColor: textColor || '#000000',
          isAnnotation: false,
          lineHeight: 1.4,
          isFocused: true,
          createdFromContextMenu: true
        };
      },
    
      createTextboxAnnotation(baseObjectProperties) {
        return {
          ...getActionableBase(baseObjectProperties),
          annotationType: 'textbox',
          text: '',
          placeholder: 'Click here to add text',
          fontSize: 16
        };
      },
    
      createSignatureAnnotation(baseObjectProperties) {
        return {
          ...getActionableBase(baseObjectProperties),
          annotationType: 'signature',
          text: '',
          placeholder: ''
        };
      },
    
      createInitialsAnnotation(baseObjectProperties) {
        return {
          ...getActionableBase(baseObjectProperties),
          annotationType: 'initials',
          text: '',
          placeholder: ''
        };
      }
    };
  
    function getAnnotationObject(newType, baseObjectProperties) {
      const dynamicMethodName = `create${newType.charAt(0).toUpperCase() + newType.slice(1)}Annotation`;
      
      if (typeof methods[dynamicMethodName] !== 'function') {
        throw new Error(`Unknown annotation type: ${newType}`);
      }
      
      return methods[dynamicMethodName](baseObjectProperties);
    }
  
    return {
      getAnnotationObject
      // Add other methods here if those needs to be exposed Aneudy A March 2025
    };
  })();
  
  export default AnnotationTypeFactory;
  