// Don't link to Unpkg scripts here because they no longer seem to work across origin (RA, Oct 2022)
// --
const scripts = [
  {
    name: 'pdfjsLib',
    src: '/pdfJsLib.js',
  },
  {
    name: 'PDFLib',
    // src: 'https://unpkg.com/pdf-lib@1.17.1/dist/pdf-lib.min.js',
    src: '/PDFLib-fixed.min.js',
  },
  {
    name: 'download',
    src: '/downloadJS.js',
  },
];

const assets = {};

export function getAsset(name) {
  if (assets[name]) return assets[name];
  const script = scripts.find((s) => s.name === name);
  if (!script) throw new Error(`Script ${name} not exists.`);
  return prepareAsset(script);
}

export function prepareAsset({name, src}) {
  if (assets[name]) return assets[name];
  assets[name] = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(window[name]);
      // console.log(`${name} is loaded.`); // can turn on for debugging
    };
    script.onerror = () => {
      reject(`The script ${name} didn't load correctly.`);
      alert(`Some scripts did not load correctly. Please reload and try again.`)
    };
    document.body.appendChild(script);
  });
  return assets[name];
}

export default function prepareAssets() {
  scripts.forEach(prepareAsset);
}

// out of the box fonts
const fonts = {
  Courier: {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 6;
    },
  },
  Helvetica: {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 10;
    },
  },
  'Times-Roman': {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 7;
    },
  }
};

// Available fonts
export const Fonts = {
  ...fonts,
};

export function fetchFont(name) {
  if (fonts[name]) return fonts[name];

  const font = Fonts[name];

  if (!font) throw new Error(`Font '${name}' not exists.`);

  fonts[name] = fetch(font.src)
      .then((r) => r.arrayBuffer())
      .then((fontBuffer) => {
        const fontFace = new FontFace(name, fontBuffer);

        if (fontFace.status == 'error') return;

        fontFace.display = 'swap';
        fontFace.load().then(() => document.fonts.add(fontFace));

        return {
          ...font,
          buffer: fontBuffer,
        };
      });

  return fonts[name];
}
