<script>
  import { createEventDispatcher } from 'svelte';
  import MenuHeader from './ContextMenu/MenuHeader.svelte';
  import IconMinus from './Icons/IconMinus.svelte';
  import IconPlus from './Icons/IconPlus.svelte';
  import Portal from "./Portal.svelte";

  const dispatch = createEventDispatcher();

  export let type;
  export let annotationTypesOptions;
  export let fontSize;
  export let fontFamily;
  export let textColor;
  export let Families;
  export let width;
  export let participant;
  export let participants;
  export let position = { x: 0, y: 0 };

  // Computed properties

  // Anonymous Participants do not have an email
  $: hasMultipleParticipantsWithEmail = participants && participants.filter(p => p.email).length > 1;

  function decreaseFontSize() {
    dispatch('decreaseFontSize');
  }

  function handleTypeChange(newType) {
    dispatch('typeChange', { type: newType });
    handleClose();
  }

  function handleFontSizeChange(event) {
    dispatch('fontSizeChange', { fontSize: event.target.value });
  }

  function handleClose() {
    dispatch('close');
  }

  function handleParticipantChange(e) {
    if (e.target.value === '') {
      handleTypeChange("text");
      return;
    }
    const newParticipant = participants.find(p => p.email === e.target.value);
    if (newParticipant) {
      dispatch('participantChange', {
        participant: newParticipant
      });
    }
  }

  function increaseFontSize() {
    dispatch('increaseFontSize');
  }

  function onChangeFont(event) {
    dispatch('fontChange', { fontFamily: event.target.value });
  }
</script>

<Portal>
  <div 
    class="context-menu-container" 
    style="position: absolute; left: {position.x + 8}px; top: {position.y}px; z-index: 9999;"
  >
    <div class="context-menu">
      <div class="context-menu-page">
        <MenuHeader
          title="Edit..."
          on:close={handleClose}
        />
        <div class="p-3">
          <div class="flex flex-col w-full">
            <!-- Type selection section -->
            <div class="menu-section">
              <div class="text-sm text-gray-600 mb-2 font-medium">Type</div>
              <div class="relative">
                <select
                  value={type}
                  on:change={(e) => handleTypeChange(e.target.value)}
                  class="w-full appearance-none bg-white border border-gray-300 rounded text-sm pl-3 pr-8 py-2 text-gray-700 cursor-pointer hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                >
                  {#each annotationTypesOptions as option}
                    <option value={option.id}>
                      {option.label}
                    </option>
                  {/each}
                </select>
                <!-- Custom dropdown arrow -->
                <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg class="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Add Participant Reassignment section - only show if there are multiple participants -->
            <div class="menu-section">
              <div class="text-sm text-gray-600 mb-2 font-medium">Assign to</div>
              <div class="relative">
                <select
                  value={participant && participant.email ? participant.email : ''}
                  on:change={(e) => {
                    handleParticipantChange(e);
                  }}
                  class="w-full appearance-none bg-white border border-gray-300 rounded text-sm pl-3 pr-8 py-2 text-gray-700 cursor-pointer hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                >
                  {#if participant && participant.email}
                    <option value={participant.email}>
                      {participant.full_name}
                    </option>
                  {/if}
                  {#each participants as potentialParticipant}
                    {#if potentialParticipant.email && (!participant || potentialParticipant.email !== participant.email)}
                      <option value={potentialParticipant.email}>
                        {potentialParticipant.full_name}
                      </option>
                    {/if}
                  {/each}
                  {#if type === 'textbox'}
                    <option value="">Unassign</option>
                  {/if}
                </select>
                <!-- Custom dropdown arrow -->
                <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg class="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Text editing section (only show for textbox type) -->
            {#if type === 'textbox'}
              <div class="menu-section">
                <div class="text-sm text-gray-600 mb-2 font-medium">Text Options:</div>
                
                <div class="flex items-center gap-2 mb-3">
                  <div class="flex items-center justify-center w-full">
                    <button 
                      class="w-6 h-6 flex-shrink-0 flex items-center justify-center text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors" 
                      on:click={decreaseFontSize}
                    >
                      <IconMinus/>
                    </button>
                    
                    <input
                      bind:value={fontSize}
                      on:change={handleFontSizeChange}
                      type="number"
                      class="no-controls flex-1 mx-1 p-1 border border-gray-300 rounded text-center text-sm"
                    />
                    
                    <button 
                      class="w-6 h-6 flex-shrink-0 flex items-center justify-center text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors" 
                      on:click={increaseFontSize}
                    >
                      <IconPlus/>
                    </button>
                  </div>

                  <!-- Color palette  not being used since only black is available -->
                  <!-- <div class="flex items-center justify-center w-8 h-8 p-1 border border-gray-300 rounded">
                    <input 
                      type="radio"
                      name="color"
                      bind:group={textColor}
                      value={textColor} 
                      class="bg-black color-palette-item"
                      checked
                    />
                  </div> -->
                </div>

                <!-- Font family select -->
                <div class="relative">
                  <select
                    bind:value={fontFamily}
                    on:change={onChangeFont}
                    class="w-full appearance-none bg-white border border-gray-300 rounded text-sm pl-3 pr-8 py-2 text-gray-700 cursor-pointer hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  >
                    {#each Families as family}
                      <option value={family}>{family}</option>
                    {/each}
                  </select>
                  <!-- Custom dropdown arrow -->
                  <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg class="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</Portal>




<style>
   /* 
    Svelte components can include a <style> element containing CSS that belongs to the component.
    This CSS is scoped by default, meaning that styles will not apply to any elements on the page outside the component in question.
    https://svelte.dev/docs/svelte/scoped-styles
  */
.context-menu-container {
  position: absolute;
  z-index: 9999;
}

.context-menu {
  display: flex;
  flex-direction: column;
  width: 220px; /* Fixed width instead of auto/min/max */
  min-width: 12.4rem;
  max-width: 18rem;
  padding: 0;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.1), 0 8px 16px -2px rgba(0, 0, 0, 0.1);
  @apply border border-gray-300;
}

.context-menu-page {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  max-height: 256px;
}

.menu-section {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.menu-section:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.color-palette-item {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  margin: 0;
}

.color-palette-item:checked {
  border: 2px solid #4B5563;
}

 /* 
    Removes the default browser spinner buttons (up/down arrows) from number inputs
    Browser support:
    - Chrome 6+ (2010), Safari 5+ (2010), Edge 12+ (2015): Uses webkit pseudo-elements
    - Firefox 1+ (2004): Uses moz-appearance
    - IE: Does not support number input spinners anyway
    well supported in all modern browsers, safe to use without fallbacks
    https://caniuse.com/?search=-moz-appearance
    https://caniuse.com/?search=-webkit-inner-spin-button
  */
.no-controls::-webkit-inner-spin-button,
.no-controls::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-controls {
  -moz-appearance: textfield;
}
</style>
