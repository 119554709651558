<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <circle cx="11" cy="11" r="7"/>
    <path d="M8 11H14"/>
    <path d="M21 21L16.65 16.65"/>
  </g>
</svg>
