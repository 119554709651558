<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path d="M20 21H4" />
    <path
      d="M7.97502 18H11.422L19.488 9.934C19.6504 9.77192 19.7792 9.57942 19.8671 9.3675C19.955 9.15558 20.0002 8.92842 20.0002 8.699C20.0002 8.46958 19.955 8.24242 19.8671 8.0305C19.7792 7.81858 19.6504 7.62608 19.488 7.464L15.536 3.512C15.3739 3.34963 15.1814 3.22082 14.9695 3.13293C14.7576 3.04504 14.5304 2.9998 14.301 2.9998C14.0716 2.9998 13.8444 3.04504 13.6325 3.13293C13.4206 3.22082 13.2281 3.34963 13.066 3.512L4.51202 12.066C4.34965 12.2281 4.22083 12.4206 4.13294 12.6325C4.04506 12.8444 3.99982 13.0716 3.99982 13.301C3.99982 13.5304 4.04506 13.7576 4.13294 13.9695C4.22083 14.1814 4.34965 14.3739 4.51202 14.536L7.97502 18Z"/>
  </g>
</svg>
