<script>
  import { createEventDispatcher } from 'svelte';

  import IconCalendarDay from './Icons/IconCalendarDay.svelte';
  import IconCertificate from "./Icons/IconCertificate.svelte";
  import IconChatMessage from './Icons/IconChatMessage.svelte';
  import IconCheckedCheckbox from './Icons/IconCheckedCheckbox.svelte';
  import IconCitySkyline from "./Icons/IconCitySkyline.svelte";
  import IconEraser from './Icons/IconEraser.svelte';
  import IconMultipleFields from "./Icons/IconMultipleFields.svelte";
  import IconNone from "./Icons/IconNone.svelte";
  import IconNotaryStamp from './Icons/IconNotaryStamp.svelte';
  import IconOpenMap from "./Icons/IconOpenMap.svelte";
  import IconPenDrawing from './Icons/IconPenDrawing.svelte';
  import IconPenPad from './Icons/IconPenPad.svelte';
  import IconRadioButton from './Icons/IconRadioButton.svelte';
  import IconRefreshPen from "./Icons/IconRefreshPen.svelte";
  import IconSignature from './Icons/IconSignature.svelte';
  import IconSingleField from "./Icons/IconSingleField.svelte";
  import IconStampCheckCircle from './Icons/IconStampCheckCircle.svelte';
  import IconTextInput from './Icons/IconTextInput.svelte';
  import IconTextSquare from "./Icons/IconTextSquare.svelte";  
  import IconUserCircle from './Icons/IconUserCircle.svelte';
  import IconUserProfileCard from "./Icons/IconUserProfileCard.svelte";
  import IconVerifiedBadge from "./Icons/IconCertificate.svelte";
  import IconZoomIn from "./Icons/IconZoomIn.svelte";
  import IconZoomOut from "./Icons/IconZoomOut.svelte";

  import {isMode} from "./utils/helper";
  import MenuHeader from './ContextMenu/MenuHeader.svelte';
  import MenuItem from './ContextMenu/MenuItem.svelte';
  import { tapout } from './utils/tapout';

  /**
   * Component props
   */
  export let canZoomInFurther; // it's a function
  export let clickedXPosition;
  export let clickedYPosition;
  export let correctedXPosition;
  export let correctedYPosition;
  export let isIframe;
  export let isMobile;
  export let notarySignatureUrl;
  export let notaryData;
  export let notaryStampUrl;
  export let participants;
  export let participantSignatures;
  export let placeObjectAfterWait;
  export let userHasFullEditingPermissions;
  export let canUseAllNotaryTools;

  /**
   * Component variables
   */
  let waitToPlaceObject = false;

  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = createEventDispatcher();
  const isNotaryMode = isMode('notary');
  const isClsMode = isMode('cls');
  const isIpenMode = isMode('ipen');
  const isEsignMode = isMode('esign', 'esign_preorder');
  const isPreorderMode = isMode('esign_preorder', 'preorder', 'cna_preorder');
  const isEsignOrPreorderMode = isMode('preorder', 'esign', 'esign_preorder', 'cna_preorder');

  /**
   * Reactive variables
   */
  $: correctedXPosition = correctedXPosition;
  $: correctedYPosition = correctedYPosition;

  let hasParticipants = participants.length > 0;
  let hasAtLeastOneParticipant = participants.length >= 1;

  $: hasParticipants = participants.length > 0;
  $: hasAtLeastOneParticipant = participants.length >= 1;

  function hasAtLeastOneParticipantAutograph(type) {
    if (type === 'Initials') {
      return hasAtLeastOneParticipantInitial();
    }

    return hasAtLeastOneParticipantSignature();
  }

  function hasAtLeastOneParticipantInitial() {
    for (var i = 0; i < participantSignatures.length; i++) {
      if (participantSignatures[i]
        && participantSignatures[i].initials
        && participantSignatures[i].initials !== 'empty'
      ) {
        return true;
      }
    }

    return false;
  }

  function hasAtLeastOneParticipantSignature() {
    for (var i = 0; i < participantSignatures.length; i++) {
      if (participantSignatures[i]
        && participantSignatures[i].signature
        && participantSignatures[i].signature !== 'empty'
      ) {
        return true;
      }
    }

    return false;
  }

  function hasParticipantAutograph(type, index) {
    if (type === 'Initials') {
      return hasParticipantInitials(index);
    }

    return hasParticipantSignature(index);
  }

  function hasParticipantInitials(index) {
    return participantSignatures.length > 0
      && participantSignatures[index]
      && participantSignatures[index].initials
      && participantSignatures[index].initials !== 'empty';
  }

  function hasParticipantSignature(index) {
    return participantSignatures.length > 0
      && participantSignatures[index]
      && participantSignatures[index].signature
      && participantSignatures[index].signature !== 'empty';
  }

  /**
   * Menus
   */
  // Main menu
  let mainMenu = {
    title: '',
    
    items: [
      {
        action: () => zoomIn(),
        condition: !isIframe && !isMobile && canZoomInFurther(),
        hasSubMenu: false,
        icon: IconZoomIn,
        title: 'Zoom In',
      },

      {
        action: () => zoomOut(),
        condition: !isIframe && !isMobile && window.ES_zoomLevel > 1,
        hasSubMenu: false,
        icon: IconZoomOut,
        title: 'Zoom Out',
      },

      {
        action: () => addText(),
        condition: shouldAllowItemInContextMenu(),
        hasSubMenu: false,
        icon: IconTextInput,
        title: 'Text field',
      },

      {
        action: () => setActiveMenu(dateMenu),
        condition: !isMode('order_template'),
        hasSubMenu: true,
        icon: IconCalendarDay,
        title: 'Today\'s date',
      },

      {
          action: () => setActiveMenu(signatureParticipantsMenu()),
          condition: isMode('cls'),
          hasSubMenu: true,
          icon: IconPenDrawing,
          title: 'Signature',
      },
      {
          action: () => setActiveMenu(initialsParticipantsMenu()),
          condition: isMode('cls'),
          hasSubMenu: true,
          icon: IconPenPad,
          title: 'Initials',
      },
      
      {
        action: () => setActiveMenu(annotationsMenu),
        condition: (isPreorderMode || isIpenMode) && hasParticipants,
        hasSubMenu: true,
        icon: IconChatMessage,
        title: 'Annotations',
      },
      
      {
        action: () => addAnnotation(
          'field_name',
          'dynamic_field'
        ),
        condition: isMode('order_template'),
        icon: IconChatMessage,
        title: 'Dynamic Field',
      },

      {
        action: () => hasAtLeastOneParticipant || isNotaryMode ? setActiveMenu(customersMenu()) : setActiveMenu(customerMenu()),
        condition: !isEsignOrPreorderMode && hasParticipants && !isMode('cls'),
        hasSubMenu: true,
        icon: isClsMode ? IconSignature : IconUserCircle,
        title: (isClsMode || isIpenMode) ? 'Signer' + (hasAtLeastOneParticipant ? 's' : '') : 'Customers',
      },
      
      {
        // Used for eSign, shows only the signature (once loaded) without a sub menu
        action: () => isEsignMode && hasAtLeastOneParticipantSignature()
            ? setActiveMenu(yourSignatureMenu())
            : addSignatureOrInitial('Signature'),
        condition: isEsignMode,
        hasSubMenu: () => isEsignMode && hasAtLeastOneParticipantSignature(),
        icon: IconPenDrawing,
        title: 'Your Signature',
      },
      
      {
        // Used for eSign, shows only the signature (once loaded) without a sub menu
        action: () => isEsignMode && hasAtLeastOneParticipantInitial()
            ? setActiveMenu(yourInitialsMenu())
            : addSignatureOrInitial('Initials'),
        condition: isEsignMode,
        hasSubMenu: () => isEsignMode && hasAtLeastOneParticipantInitial(),
        icon: IconPenPad,
        title: 'Your Initials',
      },
      
      {
        action: () => setActiveMenu(notaryMenu),
        condition: (isNotaryMode || isIpenMode) && notarySignatureUrl && notaryStampUrl,
        hasSubMenu: true,
        icon: IconStampCheckCircle,
        title: 'Notary',
      },
      
      {
        action: () => addWhiteout(),
        condition: shouldAllowItemInContextMenu(),
        icon: IconEraser,
        title: 'Whiteout',
      },

      {
        title: 'Re-Sign',
        icon: IconPenPad,
        action: () => {
          if (participants.length === 1) {
            // Skip participant selection and directly show options if only one participant
            setActiveMenu(reSignOptionsMenu(0));  // Index 0 for the only participant
          } else {
            setActiveMenu(reSignParticipantsMenu());  // Show participant selection menu for multiple participants
          }
        },
        condition: isMode('cls') && (hasAtLeastOneParticipantInitial() || hasAtLeastOneParticipantSignature()),
        hasSubMenu: true,
      },
    ],
  };

  // Today's Date menu
  let dateMenu = {
    title: 'Today\'s date',
    parent: mainMenu,
    items: [
      {
        title: 'Single field',
        icon: IconSingleField,
        action: () => addDateText(getTodaysDate())
      },
      {
        title: 'Multiple fields',
        icon: IconMultipleFields,
        action: () => addDateText(getTodaysDate(), true)
      },
    ]
  };

  // Annotations menu
  let annotationsMenu = {
    title: 'Annotations',
    parent: mainMenu,
    items: [
      {
        action: () => addAnnotation(),
        icon: IconChatMessage,
        title: 'Annotation',
      },

      {
        action: () => setActiveMenu(requestSignatureMenu()),
        condition: hasParticipants,
        hasSubMenu: true,
        icon: IconPenDrawing,
        title: 'Request signature',
      },
      
      {
        action: () => setActiveMenu(requestInitialsMenu()),
        condition: hasParticipants,
        hasSubMenu: true,
        icon: IconPenPad,
        title: 'Request initials',
      },

      {
        action: () => setActiveMenu(requestTextBoxMenu()),
        condition: hasParticipants,
        hasSubMenu: true,
        icon: IconTextInput,
        title: 'Text Box',
      },
      
      {
        action: () => addLockedAnnotation(
          'Signing date',
          'signingDate'
        ),
        icon: IconCalendarDay,
        title: 'Signing date',
      },
      
      {
        action: () => setActiveMenu(requestCheckboxMenu()),
        condition: hasParticipants,
        hasSubMenu: true,
        icon: IconCheckedCheckbox,
        title: 'Checkbox',
      },

      {
        action: () => setActiveMenu(requestRadioButtonSelectionMenu()),
        condition: hasParticipants,
        hasSubMenu: true,
        icon: IconRadioButton,
        title: 'Radio Buttons',
      },
    ],
  };

  // Notary menu
  let notaryMenu = {
    title: 'Notary',
        parent: mainMenu,
        items: [
      {
        title: 'Add Notary Tools',
        icon: IconPenDrawing,
        action: () => addNotaryTools(),
        style: !canUseAllNotaryTools ? 'opacity: 0.5;' : '',
      },
      
      {
        title: 'Signature',
        icon: IconPenDrawing,
        action: () => addNotarySignature(),
        style: !canUseAllNotaryTools ? 'opacity: 0.5;' : '',
      },
      
      {
        title: 'Notary Stamp',
        icon: IconNotaryStamp,
        action: () => addNotaryStamp(),
        style: !canUseAllNotaryTools ? 'opacity: 0.5;' : '',
      },
      {
        title: 'Name & Title',
        icon: IconUserProfileCard,
        action: () => addNotaryNameAndTitle(),
        condition: (isNotaryMode || isIpenMode) && notaryData.full_legal_name,
      },
      {
        title: 'City',
        icon: IconCitySkyline,
        action: () => addNotaryCity(),
        condition: (isNotaryMode || isIpenMode) && notaryData.city,
      },
      {
        title: 'County',
        icon: IconCitySkyline,
        action: () => addNotaryCounty(),
        condition: (isNotaryMode || isIpenMode) && notaryData.county,
      },
      {
        title: 'State',
        icon: IconOpenMap,
        action: () => addNotaryState(),
        hasSubMenu: true,
        condition: (isNotaryMode || isIpenMode) && notaryData.state,
      },
      {
        title: 'Commission Number',
        icon: IconCertificate,
        action: () =>   addCommissionNumber(),
        condition: (isNotaryMode || isIpenMode) && notaryData.commissionNumber,
      },
      {
        title: 'Commission Expiry',
        icon: IconCertificate,
        action: () => addNotaryCertExpiry(),
        condition: (isNotaryMode || isIpenMode) && notaryData.cert_exp,
      },
    ],
  };

  function reSignParticipantsMenu() {
    let items = [];

    for (let i = 0; i < participants.length; i++) {
      items.push({
        title: getParticipantFullName(i),
        icon: IconUserCircle,
        action: () => setActiveMenu(reSignOptionsMenu(i)),  // Go to options for initials/signature
        hasSubMenu: true,
      });
    }

    return {
      title: 'Re-Sign',
      parent: mainMenu,
      items,
    };
  }

  function reSignOptionsMenu(participantIndex) {
      return {
        title: getParticipantFullName(participantIndex),
        parent: reSignParticipantsMenu(),
        items: [
          {
            title: 'Signature',
            icon: IconPenDrawing,
            action: () => promptForSignature(participantIndex),
          },

          {
            title: 'Initials',
            icon: IconPenPad,
            action: () => promptForInitials(participantIndex),  
          },
        ],
      };
    }

  // Your Signature menu
  function yourSignatureMenu(
      index = 0,
      backToCustomerMenu = false
  ) {
    return {
      title: isIpenMode ? 'Signature' : 'Your Signature',
      parent: backToCustomerMenu ? customerMenu(index, true) : mainMenu,
      items: [
        {
          title: 'Place Signature',
          icon: IconPenDrawing,
          action: () => addSignatureOrInitial('Signature', participantSignatures[index], index),
        },
        {
          title: 'Redo Signature',
          icon: IconRefreshPen,
          action: () => promptForSignature(index),
        },
      ],
    }
  }
  // Your Signature menu
  function oneTimeSignatureMenu() {
    return {
      title: 'Add Signature',
      parent: mainMenu,
      items: [
        {
          title: 'Place Signature',
          icon: IconPenDrawing,
          action: () => addOneTimeSignature(),
        },
        {
          title: 'Redo Signature',
          icon: IconRefreshPen,
          action: () => dispatch('promptForOneTimeSignature', {}),
        },
      ],
    }
  }

  // Your Signature menu
  function yourInitialsMenu(
      index = 0,
      backToCustomerMenu = false
  ) {
    return {
      title: isIpenMode ? 'Initials' : 'Your Initials',
      parent: backToCustomerMenu ? customerMenu(index, true) : mainMenu,
      items: [
        {
          title: 'Place Initials',
          icon: IconPenPad,
          action: () => addSignatureOrInitial(
            'Initials', participantSignatures[index], index
          ),
        },
        {
          title: 'Redo Initials',
          icon: IconRefreshPen,
          action: () => promptForInitials(index),
        },
      ],
    }
  }

  // Customer list (dynamic)
  function customersMenu() {
    let items = [];

    for (let i = 0; i < participants.length; i++) {
      items.push({
        title: hasParticipants ? getParticipantFullName(i) : 'Signer #' + (i + 1),
        icon: IconUserCircle,
        action: () => setActiveMenu(customerMenu(i, true)),
        hasSubMenu: true,
      });
    }

    return {
      title: (isClsMode || isIpenMode) ? 'Signers' : 'Customers',
      parent: mainMenu,
      items,
    };
  }

  // Customer menu (dynamic)
  function customerMenu(
    index = 0, 
    backToCustomerList = false
  ) {
    return {
      title: getCustomerTitle(index, backToCustomerList),
      parent: backToCustomerList ? customersMenu() : mainMenu,
      items: [
        {
          title: 'Name',
          icon: IconTextSquare,
          condition: !!getParticipantFullName(index),
          action: () => addName(participants[index]),
        },
    
        {
          title: isMode('cls', 'esign', 'esign_preorder') ? 'Signature' : 'Customer signature',
          icon: IconPenDrawing,
          action: () => addSignatureOrInitial('Signature', participantSignatures[index]),
          condition: !isIpenMode && hasParticipantSignature(index),
        },
    
        {
          title: isClsMode ? 'Initials' : 'Customer initials',
          icon: IconPenPad,
          action: () => addSignatureOrInitial(
            'Initials', participantSignatures[index]
          ),
          condition: !isIpenMode && hasParticipantInitials(index),
        },

        {
          title:  'ID Type',
          icon: IconPenPad,
          action: () => addIDType(participantSignatures[index]),
          condition:  isNotaryMode && notaryData.id_type !== '' 
        },

        // IPen Specific Signature & Initials Menu Items
        {
          title: 'Signature',
          icon: IconPenDrawing,
          action: () => !hasParticipantSignature(index) ? addSignatureOrInitial('Signature', null, index) : setActiveMenu(yourSignatureMenu(index, true)),
          condition: isIpenMode,
          hasSubMenu: () => hasParticipantSignature(index),
        },
        {
          title: 'Initials',
          icon: IconPenPad,
          action: () => !hasParticipantInitials(index) ? addSignatureOrInitial('Initials', null, index) : setActiveMenu(yourInitialsMenu(index, true)),
          condition: isIpenMode,
          hasSubMenu: () => hasParticipantInitials(index),
        },
      ],
    };
  }

  function requestCheckboxMenu() {
    let items = [];
    
    for (let i = 0; i < participants.length; i++) {
      if (getParticipantFullName(i) === '') {
        continue;
        // No longer allowing the Preorder creator to add 'check here' autoplacer annotations for anonymous signers. Eventually we will allow this again, but as a more involved followup project
      }
    
      items.push({
        action: () => addActionGroupAnnotation(participants[i], 'checkbox'),
        icon: IconUserCircle,
        title: getParticipantFullName(i),
      });
    }
    
    return {
      title: 'Require Check',
      parent: annotationsMenu,
      items,
    };
  }

  function requestRadioButtonSelectionMenu() {
    let items = [];
    
    for (let i = 0; i < participants.length; i++) {
      if (getParticipantFullName(i) === '') {
        continue;
        // No longer allowing the Preorder creator to add 'check here' autoplacer annotations for anonymous signers. Eventually we will allow this again, but as a more involved followup project
      }
    
      items.push({
        action: () => addActionGroupAnnotation(participants[i], 'radioBtn'),
        icon: IconUserCircle,
        title: getParticipantFullName(i),
      });
    }
    
    return {
      title: 'Radio Buttons',
      parent: annotationsMenu,
      items,
    };
  }

  // Request signature menu (dynamic)
  function requestSignatureMenu() {
    let items = [];
    
    for (let i = 0; i < participants.length; i++) {
      if (getParticipantFullName(i) === '') {
        continue;
        // No longer allowing the Preorder creator to add 'sign/initial here' autoplacer annotations for anonymous signers. Eventually we will allow this again, but as a more involved followup project
      }
    
      items.push({
        title: getParticipantFullName(i),
        icon: IconUserCircle,
    
        action: () => addActionableAnnotation(participants[i], 'signature')
      });
    }
    
    return {
      title: 'Request signature',
      parent: annotationsMenu,
      items,
    };
  }

  // Request initials menu (dynamic)
  function requestInitialsMenu() {
    let items = [];
    
    for (let i = 0; i < participants.length; i++) {
      if (getParticipantFullName(i) === '') {
        continue;
        // No longer allowing the Preorder creator to add 'sign/initial here' autoplacer annotations for anonymous signers. Eventually we will allow this again, but as a more involved followup project
      }
    
      items.push({
        title: getParticipantFullName(i),
        icon: IconUserCircle,
    
        action: () => addActionableAnnotation(participants[i], 'initials')
      });
    }
    
    return {
      title: 'Request initials',
      parent: annotationsMenu,
      items,
    };
  }

  /**
   * Adders
   */
  function addAnnotation(
      text = 'New annotation...',
      annotationType = 'default',
      disableEditing = false,
      subtype = 'annotation'
  ) {
    dispatch('addText', {
      annotationType: annotationType,
      isAnnotation: true,
      disableEditing: !!disableEditing,
      text: text,
      subtype: subtype ? subtype : 'annotation', // Seems like it's not being used when adding a basic text annotation? (RA, March 2024)
      ...getPositionCoordinates(),
    });
    handleClose();
  }

  function addActionableAnnotation(
      participant,
      type = 'signature'
  ) {
    dispatch('addActionableAnnotation', {
      participant,
      type,
      ...getPositionCoordinates(),
    })
  }

  function addActionGroupAnnotation(
      participant,
      type
  ) {
    dispatch('addActionGroupAnnotation', {
      participant,
      type,
      ...getPositionCoordinates(),
    });
  }

  function addLockedAnnotation(
      text = 'New annotation...',
      annotationType = 'default'
  ) {
    addAnnotation(text, annotationType, true, 'locked_annotation');
  }

  function addDrawing() {
    dispatch('addDrawing', {});
    handleClose();
  }

  function addOneTimeSignature() {
    dispatch('addOneTimeSignature', {
      ...getPositionCoordinates(),
    });
    handleClose();
  }

  function addIDType () {
    addText(notaryData.idType, 'id_type');
  }

  function addName(participant) {
    addText(participant['full_name'], 'participant_name');
  }

  function requestTextBoxMenu() {
    let items = [];
    
    for (let i = 0; i < participants.length; i++) {
      if (getParticipantFullName(i) === '') {
        continue;
      }

      items.push({
        action: () => addActionableAnnotation(participants[i], 'textbox'),
        icon: IconUserCircle,
        title: getParticipantFullName(i),
      });
    }
    
    return {
      title: 'Text Box',
      parent: annotationsMenu,
      items,
    };
  }


  function addNotaryCertExpiry() {
    let cert_exp = new Date(notaryData.cert_exp);
    let cert_exp_formatted = cert_exp.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    addText(cert_exp_formatted, 'notary_cert_expiry');
  }

  function addNotaryCity() {
    addText(notaryData.city, 'notary_city');
  }

  function addNotaryCounty() {
    addText(notaryData.county, 'notary_county');
  }

  function addNotaryNameAndTitle() {
    let full_name = notaryData.full_legal_name.trim();
    addText(full_name + ', Notary Public', 'notary_name_and_title');
  }

  function addNotaryTools() {
    if (!canUseAllNotaryTools) {
      dispatch('promptForNotaryToolsNotReady', {});
      return;
    }


    updatePosition(120, 30);
    addText(notaryData.state, 'notary_state');
    updatePosition(120, 30);
    addNotaryCounty();
    updatePosition(120, 30);
    addNotaryCity();
    updatePosition(120, 30);
    addDateText(getTodaysDate());
    updatePosition(120, 30);
    addNotaryNameAndTitle();
    updatePosition(120, 30);
    addNotaryStamp();
    addNotarySignature();
  }

  function addNotarySignature() {
    if (!canUseAllNotaryTools) {
      dispatch('promptForNotaryToolsNotReady', {});
      return;
    }

    dispatch('addNotarySignature', {});
    handleClose();
  }

  function addNotaryStamp() {
    if (!canUseAllNotaryTools) {
      dispatch('promptForNotaryToolsNotReady', {});
      return;
    }

    dispatch('addNotaryStamp', {});
    handleClose();
  }

  function addNotaryState() {
    setActiveMenu({
      title: 'State',
      parent: notaryMenu,
      hasSubMenu: true,
      items: [
        {
          title: 'Abbreviated',
          icon: IconOpenMap,
          action: () => addText(notaryData.commission_type, 'notary_commission_type'),
        },
        {
          title: 'Spelled out',
          icon: IconOpenMap,
          action: () => addText(notaryData.state, 'notary_state'),
        },
      ],
    });
  }

  function addCommissionNumber() {
    addText(notaryData.commissionNumber, 'notary_commission_number');
  }

  function addText(
      text = 'New text field...', 
      subtype = null
  ) {
    dispatch('addText', {
      text: text.trim(),
      subtype: subtype,
      ...getPositionCoordinates(),
    });
    handleClose();
  }

  function addMultipleText(
      items = [], 
      subtype = null
  ) {
    items.forEach(function (value, index, array) {
      let offset = 16;

      dispatch('addText', {
        text: value,
        subtype: subtype ? subtype : 'multiple_text',
        ...getPositionCoordinates(
            index * offset, index * offset
        ),
      });
    });

    handleClose();
  }

  function addDateText(
      text,
      multiText = false
  ) {
    if (!multiText) {
      dispatch('addText', {
        text: text.trim(),
        subtype: 'date',
        dateSegment: 'full',
        ...getPositionCoordinates(),
      });

      return handleClose();
    }

    let items = (text).split('/');
    let timestamp = new Date().getTime();

    items.forEach(function (value, index, array) {
      let offset = 16;
      let dateSegment = 'month';

      if (index === 1) {
        dateSegment = 'day';
      } else if (index === 2) {
        dateSegment = 'year';
      }

      dispatch('addText', {
        text: value,
        subtype: 'date',
        groupId: 'date_' + timestamp,
        dateSegment: dateSegment,
        ...getPositionCoordinates(
            index * offset, index * offset
        ),
      });
    });

    handleClose();
  }

  function addSignatureOrInitial(
    type = 'Signature',
    signatureData = null, 
    participantIndex = null ,isRedoingSignature = false
  ) {
    const forParticipant = participantIndex !== null ? participants[participantIndex] : null;

    if (isRedoingSignature ||(isEsignMode) // Adding IPEN to this created infinite loop (RA Oct, 2024)
      && signatureData === null 
      && !hasParticipantAutograph(type, participantIndex)
    ) {
      return promptForThenPlace(
        forParticipant, signatureData, type, participantIndex
      );
    }

    if (isEsignMode && hasAtLeastOneParticipantAutograph('type')) {
      signatureData = participantSignatures[0];
    }

    if (isIpenMode 
      && signatureData === null 
      && participantIndex !== null
    ) {
      signatureData = participantSignatures[participantIndex];
    }

    const positionCoordinates = getPositionCoordinates();

    dispatch('add' + type, {
      signatureData,
      ...positionCoordinates,
      participant: forParticipant,
    });
    
    handleClose();
  }

  function addWhiteout() {
    dispatch('addWhiteoutBox', {
      ...getPositionCoordinates(),
    });
    handleClose();
  }

  /**
   * Handlers
   */
  function handleBack(parentMenu) {
    setActiveMenu(parentMenu);
  }
  
  function handleClose() {
    dispatch('close');
  }

  function handleTapout(event) {
    dispatch('tapout', {});
  }

  function promptForThenPlace(
    forParticipant, 
    signatureData, 
    type,
    participantIndex
  ) {
    dispatch('promptFor' + type, { participant: forParticipant });

    let checkToPlaceInterval = setInterval(() => {
      if (placeObjectAfterWait) {
        addSignatureOrInitial(type, signatureData, participantIndex);
        clearInterval(checkToPlaceInterval);
        dispatch('placedObjectAfterWait');
      }
    }, 250);

    setTimeout(() => {
      clearInterval(checkToPlaceInterval);
    }, 30000);
  }

  function promptForInitials(participantIndex = null) {
    const forParticipant = participantIndex !== null ? participants[participantIndex] : null;

    dispatch('promptForInitials', {
      participant: forParticipant,
    });
    
    handleClose();
  }
  
  function promptForSignature(participantIndex = null) {
    const forParticipant = participantIndex !== null ? participants[participantIndex] : null;

    dispatch('promptForSignature', {
      participant: forParticipant,
    });
    
    handleClose();
  }

  function setActiveMenu(key) {
    if (key) {
      if (typeof key === 'function') {
        activeMenu = key();
      } else {
        activeMenu = key;
      }
    } else {
      activeMenu = mainMenu;
    }
  }

   function getCanvasDimensions() {
    const canvas = document.querySelector('.page-canvas'); // This is the PDF page canvas
    if (!canvas) {
        console.error('Canvas not found');
        return null;
    }

    return {
        width: canvas.width,
        height: canvas.height
    };
  }

  function getCustomerTitle(index, backToCustomerList) {
    if (backToCustomerList) {
      return hasParticipants ? getParticipantFullName(index) : 'Signer #' + (index + 1);
    } else {
      return isClsMode ? getParticipantFullName(index) : 'Customer';
    }
  }

  function getParticipantFullName(index = 0) {
    return participants[index].full_name.trim();
  }
  
  function getItems(items) {
    let computedItems = [];

    items.forEach((item) => {
      let conditionResult = false;

      if (item.condition !== undefined && typeof item.condition === 'function') {
        conditionResult = item.condition();
      } else if (item.condition === undefined) {
        conditionResult = true;
      } else {
        conditionResult = item.condition;
      }

      if (conditionResult) {
        computedItems.push(item);
      }
    });

    return computedItems;
  }
  
  function getTodaysDate() {
    return new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }
  
  function getPositionAnchor() {
    let anchor = 'top-left';

    if (clickedXPosition === correctedXPosition && clickedYPosition === correctedYPosition) {
      anchor = 'top-left';
    } else if (clickedXPosition !== correctedXPosition && clickedYPosition === correctedYPosition) {
      anchor = 'top-right';
    } else if (clickedXPosition === correctedXPosition && clickedYPosition !== correctedYPosition) {
      anchor = 'bottom-left';
    } else if (clickedXPosition !== correctedXPosition && clickedYPosition !== correctedYPosition) {
      anchor = 'bottom-right';
    }

    return anchor;
  }
  
  function getPositionCoordinates(offsetX = 0, offsetY = 0) {
    return {
      x: getPositionMin(isMobile ? clickedXPosition : correctedXPosition) + offsetX,
      y: getPositionMin(isMobile ? clickedYPosition : correctedYPosition) + offsetY,
    };
  }
  
  function getPositionMin(coordinate) {
    return coordinate < 0 ? 0 : coordinate;
  }

  function shouldAllowItemInContextMenu() {
    if (!isClsMode) {
      return !isMode('order_template');
    }
    return userHasFullEditingPermissions;
  }

  function updatePosition(dx, dy) {
    let { width, height } = getCanvasDimensions();

    if (correctedXPosition + dx > width) {
        correctedXPosition = 0; // Reset X to the start of the next line
        correctedYPosition += dy; // Move down a line
    } else {
        correctedXPosition += dx; // Move right by dx
    }
  }

  function zoomIn() {
    dispatch('zoomIn');
    handleClose();
  }

  function zoomOut() {
    dispatch('zoomOut');
    handleClose();
  }

  function initialsParticipantsMenu() {
    let items = [];

    for (let i = 0; i < participants.length; i++) {
        items.push({
            title: getParticipantFullName(i),
            icon: IconUserCircle,
            action: () => setActiveMenu(initialsActionsMenu(i)),
            hasSubMenu: true,
        });
    }

    return {
        title: 'Initials',
        parent: mainMenu,
        items,
    };
  }

  function signatureParticipantsMenu() {
    let items = [];

    for (let i = 0; i < participants.length; i++) {
        items.push({
            title: getParticipantFullName(i),
            icon: IconUserCircle,
            action: () => setActiveMenu(signatureActionsMenu(i)),
            hasSubMenu: true,
        });
    }

    return {
      title: 'Signature',
      parent: mainMenu,
      items,
    };
  }

  function signatureActionsMenu(participantIndex) {
    return {
        title: getParticipantFullName(participantIndex),
        parent: signatureParticipantsMenu(),
        items: [
            {
                title: 'Add Signature',
                icon: IconPenDrawing,
                action: () => addSignatureForParticipant(participantIndex),
            },
        ],
    };
  }

  function initialsActionsMenu(participantIndex) {
    return {
        title: getParticipantFullName(participantIndex),
        parent: initialsParticipantsMenu(),
        items: [
            {
                title: 'Add Initials',
                icon: IconPenPad,
                action: () => addInitialsForParticipant(participantIndex),
            },
        ],
    };
  }

    function addSignatureForParticipant(index) {
      addSignatureOrInitial('Signature', participantSignatures[index], index),
      dispatch('placedObjectAfterWait');
    }


    function addInitialsForParticipant(index) {
      addSignatureOrInitial('Initials', participantSignatures[index], index),
      dispatch('placedObjectAfterWait');
    }

  let activeMenu = mainMenu;
</script>

<style global>
    .context-menu-container {
        position: absolute;
        z-index: 100;
    }

    .context-menu {
        display: flex;
        flex-direction: column;

        width: auto;
        min-width: 12.4rem;
        max-width: 18rem;

        padding: 0;

        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.1), 0 8px 16px -2px rgba(0, 0, 0, 0.1);

        @apply border border-gray-300;
    }

    .context-menu-hotspot {
        position: absolute;
        z-index: -1;
        content: '';

        width: 1rem;
        height: 1rem;

        border-radius: 50%;
        box-shadow: 0 0 0 8px rgba(65, 152, 225, 0.1);;

        @apply bg-blue-500;
    }

    .context-menu-container.top-left .context-menu-hotspot {
        top: -0.5rem;
        left: -0.5rem;
    }

    .context-menu-container.top-right .context-menu-hotspot {
        top: -0.5rem;
        right: -0.5rem;
    }

    .context-menu-container.bottom-right .context-menu-hotspot {
        bottom: -0.5rem;
        right: -0.5rem;
    }

    .context-menu-container.bottom-left .context-menu-hotspot {
        bottom: -0.5rem;
        left: -0.5rem;
    }

    .context-menu-item-empty {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        padding: 0.5rem 0.75rem;

        font-size: 0.875rem;
        font-weight: 500;

        @apply text-gray-400;
    }

    .context-menu-page {
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        max-height: 256px;
    }

    .context-menu-page .context-menu-item:not(:last-child) {
        @apply border-b border-gray-300;
    }

    /*@media (max-width: 480px) {*/
    /*    .context-menu-container.mobile {*/
    /*        position: fixed;*/
    /*        left: 1rem;*/
    /*        right: 1rem;*/
    /*        bottom: 1rem;*/
    /*    }*/

    /*    .context-menu-container.mobile .context-menu {*/
    /*        width: 100%;*/
    /*        max-width: 100%;*/
    /*    }*/
    /*}*/
</style>

<div
  id="context-menu"
  class="context-menu-container"
  class:top-left={getPositionAnchor() === 'top-left'}
  class:top-right={getPositionAnchor() === 'top-right'}
  class:bottom-right={getPositionAnchor() === 'bottom-right'}
  class:bottom-left={getPositionAnchor() === 'bottom-left'}
  class:mobile={isMobile}
  style={'top:' + correctedYPosition + 'px; left:' + correctedXPosition + 'px; transform: scale(' + (1 / window.ES_zoomLevel) + ');'}
  use:tapout
  on:tapout={(event) => handleTapout(event)}
>
  <!--  <div class="context-menu-hotspot"></div>-->
  <div class="context-menu">
    <div class="context-menu-page">
      <MenuHeader
        title={activeMenu.title}
        back={activeMenu.parent !== undefined && activeMenu.parent !== null}
        on:back={() => handleBack(activeMenu.parent)}
        on:close={handleClose}
      />
      {#if activeMenu.items.length > 0}
        {#each getItems(activeMenu.items) as item}
          <MenuItem
            icon={item.icon}
            title={item.title}
            hasSubMenu={item.hasSubMenu !== undefined && typeof item.hasSubMenu === 'function' ? item.hasSubMenu() : item.hasSubMenu}
            style={item.style}
            on:click={(event) => item.action(event)}
          />
        {/each}
      {:else}
        <div class="context-menu-item-empty">
          No items
        </div>
      {/if}
    </div>
  </div>
</div>