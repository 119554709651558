<script>
  import {onDestroy} from "svelte";

  export let page;
  export let pageIndex = 1;
  export let totalPages = 1;
  export let pageScale = 1;
  export let pageSelected = false;

  let isVisible = false;
  let visibilityTimer;

  $: currentPage = pageIndex + 1;
  $: positionRight = pageScale * 24;
  $: positionBottom = pageScale * 24;
  $: {
    if (pageSelected) {
      handlePageRecentlySelected(pageSelected);
    }
  }

  function handlePageRecentlySelected(selected) {
    if (selected) {
      isVisible = true;

      visibilityTimer = setTimeout(() => {
        isVisible = false;
      }, 2500);
    }
  }

  onDestroy(() => {
    clearTimeout(visibilityTimer);
  });
</script>

<div
    class="absolute select-none pointer-events-none"
    class:opacity-35={!isVisible}
    class:opacity-100={isVisible}
    style="z-index: 9; right: {positionRight}px; bottom: {positionBottom}px; transform: scale({pageScale}); touch-action: none; transition: opacity 0.5s ease;"
>
  <div
    class="page-number inline-block px-3 py-1 text-xs font-semibold text-white bg-gray-800 rounded"
  >{currentPage} of {totalPages}</div>
</div>