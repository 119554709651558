<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path
      d="M8.00017 13C7.85768 13 7.71685 13.0304 7.58709 13.0893C7.45733 13.1482 7.34164 13.2341 7.24779 13.3413C7.15393 13.4485 7.08406 13.5745 7.04287 13.7109C7.00168 13.8473 6.99011 13.9909 7.00894 14.1322L7.20013 15.5661C7.21614 15.6862 7.27522 15.7964 7.36638 15.8762C7.45754 15.956 7.57458 16 7.69574 16H16.2566C16.375 16 16.4895 15.958 16.5798 15.8815C16.6701 15.805 16.7304 15.6989 16.7498 15.5822L16.9861 14.1644C17.01 14.0211 17.0024 13.8744 16.9638 13.7344C16.9253 13.5944 16.8566 13.4644 16.7628 13.3536C16.6689 13.2428 16.552 13.1538 16.4202 13.0927C16.2885 13.0316 16.145 13 15.9997 13H8.00017Z"/>
    <path
      d="M13.5555 13L14.3492 9.42854C14.4274 9.07675 14.4255 8.7119 14.3438 8.36092C14.2621 8.00993 14.1027 7.68178 13.8772 7.40068C13.6517 7.11957 13.3659 6.8927 13.041 6.73681C12.7161 6.58092 12.3604 6.49998 12 6.49998C11.6396 6.49998 11.2839 6.58092 10.959 6.73681C10.6341 6.8927 10.3483 7.11957 10.1228 7.40068C9.89734 7.68178 9.73786 8.00993 9.65616 8.36092C9.57447 8.7119 9.57264 9.07675 9.65082 9.42854L10.4445 13"/>
    <path
      d="M20.582 9.353C20.855 10.209 20.9959 11.1016 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91131 5.51983 5.50571 6.99987 4.51677C8.47991 3.52784 10.22 3 12 3C12.8984 3.00406 13.791 3.14501 14.647 3.418"/>
    <path d="M21 4.274L19.012 6.262L17.737 4.987"/>
  </g>
</svg>
