<script>
  import {createEventDispatcher} from 'svelte';

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <path
    d="M2.79136 10.0287C3.97891 7.25687 7.62637 2 11.1163 2C15.4787 2 5.29975 22 3.19124 22C0.755564 22 2.40951 16.9425 4.97257 13.2903C7.21794 10.0908 9.98935 8.16487 12.5705 8.16487C13.9882 8.16487 12.8249 12.2151 12.8249 12.2151C12.8249 12.2151 15.0207 9.41935 16.3876 9.41935C17.7545 9.41935 16.1331 11.2115 17.6236 11.2115C19.1141 11.2115 19.0777 10.3154 19.623 10.3154C20.2774 10.3154 20.0229 11.2115 21.6952 11.2115"/>
</svg>
