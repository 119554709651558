<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path
      d="M21 12V16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21H8C6.67392 21 5.40215 20.4732 4.46447 19.5355C3.52678 18.5979 3 17.3261 3 16V8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3H12"/>
    <path
      d="M17.3795 3.62025C17.5766 3.42337 17.8105 3.26725 18.0679 3.16082C18.3253 3.05439 18.6011 2.99974 18.8796 2.99999C19.1582 3.00023 19.4339 3.05538 19.6911 3.16227C19.9483 3.26916 20.182 3.42569 20.3786 3.62293C20.5756 3.81989 20.7319 4.05373 20.8385 4.31111C20.9451 4.56848 21 4.84434 20.9999 5.12291C20.9999 5.40149 20.945 5.67733 20.8383 5.93468C20.7316 6.19202 20.5753 6.42583 20.3782 6.62274C20.3776 6.6221 16.2021 10.7974 14.7582 12.2412C14.4437 12.5557 14.0328 12.7559 13.5912 12.8098L12.1134 12.9923C11.9622 13.011 11.8087 12.9946 11.6649 12.9443C11.5211 12.8941 11.3907 12.8114 11.284 12.7026C11.1773 12.5939 11.0971 12.462 11.0496 12.3173C11.0021 12.1725 10.9886 12.0187 11.0101 11.8679L11.2288 10.3369C11.2883 9.91038 11.4852 9.51488 11.7896 9.21027C12.8001 8.19931 15.2695 5.72978 17.3795 3.62025Z"/>
  </g>
</svg>
