<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path
        d="m7 10v8c0 1.6569 1.34315 3 3 3h8c1.6569 0 3-1.3432 3-3v-8c0-1.65686-1.3432-3-3-3h-8c-1.65686 0-3 1.34315-3 3z"/>
    <path d="m11 11v6"/>
    <path
        d="m3.87866 16c-.27899-.2783-.50024-.6089-.65103-.973-.15079-.364-.22815-.7543-.22763-1.1483v-7.8787c0-.79565.31607-1.55871.87868-2.12132s1.32567-.87868 2.12132-.87868h7.8787c.394-.00052.7843.07683 1.1483.22762.3641.1508.6947.37205.973.65104"/>
  </g>
</svg>