export function camelCase(string) {
  return string.replace( /-\w/g, clearAndUpper );
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function clearAndUpper(string) {
  return string.replace(/-/, '').toUpperCase();
}

export function containsEmoji(string) {
  const emojiRegex = /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu;
  return emojiRegex.test(string); 
}

export function ggID() {
  let id = 0;
  return function genId() {
    return id++;
  };
}

// Works with the pdf-lib.js color types
export function hexToRGB(hexCode) {
  if (hexCode.charAt(0) === '#') {
    hexCode = hexCode.slice(1);
  }

  // Convert the hexadecimal code to RGB values
  const red = parseInt(hexCode.substring(0, 2), 16) / 255;
  const green = parseInt(hexCode.substring(2, 4), 16) / 255;
  const blue = parseInt(hexCode.substring(4, 6), 16) / 255;

  // Return the RGB object
  return { type: 'RGB', red, green, blue };
}

export function initials(fullName) {
  const words = fullName.split(' ');

  if (words.length === 1) {
    return words[0][0].toUpperCase();
  } else if (words.length > 1) {
    const firstName = words[0];
    const lastName = words[words.length - 1];
    return firstName[0].toUpperCase() + lastName[0].toUpperCase();
  } else {
    return '';
  }
}

export function isMode(...modes) {
  const urlParams = new URLSearchParams(window.location.search);
  const modeParam = urlParams.get('mode');

  return modes.includes(modeParam);
}

export const noop = () => {};

export function pascalCase(string) {
  return string.replace(/(^\w|-\w)/g, clearAndUpper);
}

export function timeout(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

export function throttle(fn, delay) {
  let lastExecution = 0;

  return function (...args) {
    const now = Date.now();
    
    if (now - lastExecution >= delay) {
      lastExecution = now;
      fn(...args);
    }
  };
}
