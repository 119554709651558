<script>
	import { onMount, createEventDispatcher } from 'svelte';

	export let participantsData;

	const dispatch = createEventDispatcher();

	function rejectSignature(participant) {
		dispatch('rejectSignature', {
			participant: participant,
		});
		closeModal();
	}

	function closeModal() {
		dispatch('closeModal');
	}

    onMount(() => {});
</script>

<div>
	<div
		aria-labelledby="modal-title"
		aria-modal="true"
		class="relative z-10"
		role="dialog"
	>
		<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

		<div class="fixed inset-0 z-10 overflow-y-auto">
			<div class="flex items-start sm:items-center justify-center min-h-full p-4 pt-24 text-center sm:p-0">
				<div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full">
					<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
						<h3 class="text-lg leading-6 font-medium text-gray-900 text-center" id="modal-title">Reject Participant Signatures</h3>
						<div class="mt-4">
							{#each participantsData as participant}
								<div class="flex justify-between items-center py-2 border-b border-gray-200 w-full">
									<div class="flex-grow">
										<p class="font-medium text-gray-700 w-full">{participant.full_name}</p>
									</div>
									<button
										on:pointerup={() => rejectSignature(participant)}
										class="ml-4 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
										type="button"
									>Reject</button>
								</div>
							{/each}
						</div>
					</div>

					<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
						<button
							on:pointerup={closeModal}
							class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
							type="button"
						>Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
