import { getAsset } from './prepareAssets';

// ---------------------
// - Private Functions -
// ---------------------

async function flattenPdfBlob(blob) {
  try {
    const formData = new FormData();
    formData.append('pdf', blob);
    
    const response = await fetch('https://pdf-flattener.com/api/flatten', {
      method: 'POST',
      body: formData,
    });
    
    const data = await response.json();
    const pdfUrl = data.pdf.replace('http://', 'https://');
    return await fetch(pdfUrl).then(r => r.blob());
  } catch (error) {
    console.error(error);
    return blob;
  }
}

// --------------
// - Public API -
// --------------

export function readAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function readAsImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    
    if (src instanceof Blob) {
      const url = window.URL.createObjectURL(src);
      img.src = url;
    } else {
      img.src = src;
    }
  });
}

export function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

// Safari possibly gets a webkitblobresource error 1 when using origin file blob (comment from original repo author)
// --
export async function readAsPDF(file) {
  const pdfjsLib = await getAsset('pdfjsLib');

  // Fixes bug 'No "GlobalWorkerOptions.workerSrc" specified'
  // more info: https://github.com/mozilla/pdf.js/issues/10478
  pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdfJsLib.worker.js';

  const blob = new Blob([file]);
  const updatedBlob = await flattenPdfBlob(blob);
  const url = window.URL.createObjectURL(updatedBlob);

  return pdfjsLib.getDocument(url).promise;
}
