<script>
  // ----------------
  // - Dependencies -
  // ----------------
  import AnnotationTypeFactory from './utils/AnnotationTypeFactory';
  import { createEventDispatcher, onMount } from "svelte";  
  import { getParticipantColors } from './utils/AnnotationsColorHelper';
  import EditAnnotationTypeMenu from './EditAnnotationTypeMenu.svelte';
  import { Fonts } from "./utils/prepareAssets.js";
  import IconPenDrawing from "./Icons/IconPenDrawing.svelte";
  import IconPenPad from "./Icons/IconPenPad.svelte";
  import IconSmallCheck from "./Icons/IconSmallCheck.svelte";
  import IconSmallX from "./Icons/IconSmallX.svelte";
  import { initials, isMode, measureTextWidth } from "./utils/helper";
  import IconEdit from "./Icons/IconEdit.svelte";
  import IconTextInput from "./Icons/IconTextInput.svelte";
  import { pannable } from "./utils/pannable.js";

  // ---------
  // - Props -
  // ---------

  export let activeEditAnnotationMenu;
  export let allSessionParticipants;
  export let associatedObjectId = null;
  export let color = 'red';
  export let currentParticipantEmail;
  export let expectedParticipantEmail;
  export let frozen = false;
  export let fontSize;
  export let fontFamily = 'Helvetica';
  export let height = 60;
  export let id;
  export let object;
  export let pageIndex;
  export let pageScale;
  export let participant;
  export let participants;
  export let placeholder = '';
  export let textAlign = 'left';
  export let textColor = '#000000';
  export let text = 'Click here to add text';
  export let type = 'signature';
  export let width = 190;
  export let x = 200;
  export let y = 250;
  export let isFocused = false;


  // -------------
  // - Constants -
  // -------------

  // The difference between participants and allSessionParticipants is that participants is the data for the signers on the same device, and allSessionParticipants is the data for all participants in the session. Aneudy A Jan 14 2025
  $: colors = isMode('cls') 
    ? getParticipantColors(participant, allSessionParticipants)
    : getParticipantColors(participant, participants);

  $: width, height, type, updateDisplayName();

  $: if (!placeholder || placeholder === '') {
    placeholder = 'Click here to add text';
  }

  $: if (object.fontFamily) {
    fontFamily = object.fontFamily;
  }

  $: isAnnotationOwnerPresent = isAnnotationOwnerPresent();

  const dispatch = createEventDispatcher();
  const isPreorderMode = isMode('esign_preorder', 'preorder', 'cna_preorder');

  // ----------------------
  // - Reactive Variables -
  // ----------------------

  let aspectRatio;
  let _colorSelection = textColor;
  let currentMinHeight = minHeight();
  let currentMinWidth = minWidth();
  let deleteBtnLeftStyle = '50%';
  let displayName;
  let _dialogXPosition = 0;
  let dx = 0;
  let dy = 0;
  let editable;
  let hasMoved = false;
  let initialHeight;
  let initialWidth;
  let initialX = x;
  let initialY = y;
  let isEditing = false;
  let lockAspectRatio = true;
  let moving = false;
  let resizeStartX;
  let resizeStartY;
  let resizing = false;
  let _size = fontSize;
  let startX;
  let startY;
  let textElement;
  let xResize = 0;
  let yResize = 0;
  let showTextEditor = false;
  const Families = Object.keys(Fonts);
  let _fontFamily = fontFamily;
  let editButtonRef;
  let menuPosition = { x: 0, y: 0 };

  // This is the list of options for the edit menu
  let annotationTypesOptions = [
    { id: 'textbox', label: 'Text Input', icon: IconTextInput },
    { id: 'signature', label: 'Signature', icon: IconPenDrawing },
    { id: 'initials', label: 'Initials', icon: IconPenPad }
  ];

  // ----------------------------
  // - Functions (Alphabetized) -
  // ----------------------------

  function decreaseFontSize() {
      fontSize = Math.max(8, fontSize - 1); 
      onFontSizeChange();
  }

  function getCursorStyle() {
    if (isMode('notary')) {
      return 'default';
    }
    if (moving) {
      return 'grabbing';
    }
    if (isMode('esign', 'cls')) {
      return 'text';
    }
    if (!frozen) {
      return 'grab';
    }
    return 'default';
  }

  function getFirstNameAndInitial(fullName) {
    const words = fullName.split(' ');

    if (words.length === 1) {
      return words[0];
    } else if (words.length > 1) {
      const firstName = words[0];
      const lastNameInitial = words[words.length - 1][0];
      return `${firstName} ${lastNameInitial}.`;
    } else {
      return '';
    }
  }

  function handleBlur() {
    if (isMode('cls')) {
      isEditing = false;
    }
  }

  function handleClick() {
    if (isMode('ipen') && hasMoved) return null;

    if ((type === 'signature' || type === 'initials') && isMode('ron_order')) {
      dispatch('notifyAnnotationNotFillableYet', {});
      return null;
    }

    dispatch('click', {
      objectId: id,
      pageIndex,
      pageScale,
      participant,
      type,
      x,
      y,
      width,
      height,
    });
  }

  function handleDelete(event) {
    event.stopPropagation();
    
    if (showTextEditor) {
      showTextEditor = false;
      dispatch('editMenuClose');
    }

    dispatch('delete', { id });
  }

  function handleFocus() {
    if (isMode('cls')) {
      isEditing = true;
    }
  }

  function handleInput(event) {
    text = event.target.textContent;
    
    // Get the text element's computed style
    const style = window.getComputedStyle(textElement);
    const lineHeight = parseInt(style.lineHeight);
    
    // Let the text element wrap naturally based on width
    textElement.style.whiteSpace = 'normal';
    textElement.style.wordWrap = 'break-word';
    
    // Calculate the scrollHeight which includes wrapped content
    const contentHeight = textElement.scrollHeight;
    const requiredHeight = contentHeight + 10; // Add padding
    
    // If text is being deleted, shrink the box height
    // For adding text, only grow the box if needed
    if (requiredHeight > height) {
      height = Math.min(maxHeight(), requiredHeight);
    }

    placeholder = text;

    if (isMode('cls','ipen','esign','ron_order')) {
        dispatch('update', {
        text,
        id,
        pageIndex,
        height,
        width
      });
    } else {
        dispatch('update', {
        placeholder,
        id,
        pageIndex,
        height,
        width
      });
    }
  }

  function handlePanEnd(event) {
    x += dx;
    y += dy;

    dispatch("update", {
      eventType: "dragEnd",
      x: x,
      y: y,
    });

    dx = 0;
    dy = 0;
    moving = false;
    
    if (showTextEditor) {
      updateMenuPosition();
    }

    if (!hasMoved) {
      handleClick();
    }
  }

  function handlePanMove(event) {
    if (frozen) return;

    dx = (event.detail.x - startX) / pageScale;
    dy = (event.detail.y - startY) / pageScale;

    if (Math.abs(dx) > 1 || Math.abs(dy) > 1) {
      hasMoved = true;
    }

    // Update menu position during drag
    if (showTextEditor) {
      updateMenuPosition();
    }
  }

  function handlePanStart(event) {
    const target = event.target.closest('.resize-handle, .delete-button');

    if (target) {
      moving = false;
      return;
    }

    startX = event.detail.x;
    startY = event.detail.y;
    moving = true;
    hasMoved = false;
  }

  function handleResizeMove(event) {
    if (!resizing) return;
    xResize = (event.clientX - resizeStartX) / pageScale;
    yResize = (event.clientY - resizeStartY) / pageScale;

    let newHeight = initialHeight + yResize;
    let newWidth = initialWidth + xResize;

    if (type === 'textbox') {
      textElement.style.whiteSpace = 'normal';
      textElement.style.wordWrap = 'break-word';
      
      textElement.style.width = `${newWidth}px`;
      
      const contentHeight = textElement.scrollHeight;
      const requiredHeight = contentHeight + 10;
      
      const words = text.split(' ');
      const longestWord = words.reduce((a, b) => a.length > b.length ? a : b, '');
      const tempSpan = document.createElement('span');
      tempSpan.style.font = window.getComputedStyle(textElement).font;
      tempSpan.style.visibility = 'hidden';
      tempSpan.textContent = longestWord;
      document.body.appendChild(tempSpan);
      const minWordWidth = tempSpan.offsetWidth;
      document.body.removeChild(tempSpan);
      
      const minRequiredWidth = minWordWidth + 20;
      
      // Apply constraints
      width = Math.max(minRequiredWidth, Math.min(maxWidth(), newWidth));
      height = Math.max(requiredHeight, Math.min(maxHeight(), newHeight));
      
      textElement.style.width = `${width}px`;
    } else {
      if (width < 110 || newWidth < 110) {
        const initialsText = `${initials(participant.full_name)} ${type === 'signature' ? 'Sign Here' : 'Initial Here'}`;
        const textWidth = measureTextWidth(initialsText, textElement);
        const minWidthNeeded = textWidth + 10;
        
        if (newWidth < minWidthNeeded) {
          newWidth = minWidthNeeded;
        }
      }

      height = Math.max(currentMinHeight, Math.min(maxHeight(), newHeight));
      width = Math.max(currentMinWidth, Math.min(maxWidth(), newWidth));

      if (lockAspectRatio) {
        height = width * aspectRatio;
      }
    }

    // Update menu position during resize
    if (showTextEditor) {
      updateMenuPosition();
    }

    dispatch('update', {
      id,
      height,
      width,
      pageIndex
    });
  }

  function handleResizeEnd() {
    resizing = false;
    window.removeEventListener('mousemove', handleResizeMove);
    window.removeEventListener('mouseup', handleResizeEnd);
    
    if (showTextEditor) {
      updateMenuPosition();
    }
    
    dispatch('update', { width, height });
  }

  function handleResizeStart(event) {
    if (event.button !== 0) return;
    resizing = true;
    event.preventDefault();
    event.stopPropagation();
    resizeStartX = event.clientX;
    resizeStartY = event.clientY;
    initialWidth = width;
    initialHeight = height;
    aspectRatio = height / width;
    window.addEventListener('mousemove', handleResizeMove);
    window.addEventListener('mouseup', handleResizeEnd);
  }

  function handleTypeChange(newType) {
      type = newType;
      const baseObjectProperties = {
        id,
        x,
        y,
        pageIndex,
        participant,
        width,
        height,
      };
    
    const annotationObject =  AnnotationTypeFactory.getAnnotationObject(newType, baseObjectProperties);
    dispatch('update', annotationObject);
  }

  function handleTextboxClick(event) {
    if ((isMode('esign_preorder','preorder','cna_preorder','ipen') ||  expectedParticipantEmail !== null) && !isMode('notary')) {
      if (!moving && !resizing && !hasMoved) {
        event.preventDefault();
        isFocused = true;
        if (textElement) {
          textElement.focus();
        }
      }
    }
  }

  function handleTextUpdate(event) {
    const updates = event.detail;
    
    text = updates.text;
    placeholder = updates.placeholder || placeholder;
    fontSize = updates.fontSize;
    fontFamily = updates.fontFamily;
    textAlign = updates.textAlign;
    textColor = updates.textColor;

    if (textElement) {
      textElement.style.whiteSpace = 'normal';
      textElement.style.wordWrap = 'break-word';
      textElement.style.fontSize = `${fontSize}px`;
      textElement.style.fontFamily = fontFamily;
      
      const contentHeight = textElement.scrollHeight;
      const requiredHeight = contentHeight + 10; 
      
      if (requiredHeight > height) {
        height = Math.min(maxHeight(), requiredHeight);
      }
    }

    dispatch('update', {
      id,
      text,
      fontSize,
      fontFamily,
      textAlign,
      textColor,
      height,
      width,
      pageIndex,
    });
  }

  function isAnnotationOwnerPresent() {
    if (isMode('notary')) {
      return participants.some(p => p.email === participant.email);
    }

    if (isMode('esign')) {
       return participant.email === currentParticipantEmail
    }

    if (isMode('ron_order')) {
      return participants.some(p => p.email === participant.email);
    }
  
    return participants.some(p => p.email === participant.email) || allSessionParticipants.some(p => p.email === participant.email);
  }

  function increaseFontSize() {
      fontSize = Math.min(72, fontSize + 1); 
      onFontSizeChange();
  }

  function maxHeight() {
    return 300;
  }

  function maxWidth() {
    return 500;
  }

  function minHeight() {
    return 30;
  }

  function minWidth() {
    return 80;
  }

  function onFontSizeChange() {
      console.log('onFontSizeChange called, current fontSize:', fontSize);
      handleTextUpdate({
          detail: {
              text,
              fontSize,
              fontFamily,
              textAlign,
              textColor
          }
      });
  }

  function onChangeFont() {
      handleTextUpdate({
          detail: {
              text,
              fontSize,
              fontFamily,
              textAlign,
              textColor
          }
      });
  }

  function onAssociatedObjectIdChange() {
    if (!associatedObjectId) {
      return null;
    }

    dispatch('completedByClient', {
      objectId: id,
      pageIndex,
      participant,
    });
  }

  function toggleTextEditor() {
    if (!showTextEditor) {
      dispatch('editMenuOpen', `actionable-${id}`);
    } else {
      dispatch('editMenuClose');
    }
    showTextEditor = !showTextEditor;
  }

  function textboxTextContent() {
    if (isMode('preorder', 'esign_preorder') && !text) {
      if (!placeholder || placeholder === '') {
        return 'Click to enter text';
      }

      return placeholder;
    }

    if (isMode('cls', 'notary', 'ron_order')) {
      return text;
    }
    
    return '';
  }

  function updateDisplayName() {
      if (!textElement) return;

      const fullDisplay = getFirstNameAndInitial(participant.full_name);
      const initialsDisplay = initials(participant.full_name);
      const style = window.getComputedStyle(textElement);
      const lineHeight = parseInt(style.lineHeight);
      const availableLines = Math.floor(height / lineHeight);
      
      const availableWidth = width - 20;
      const fullText = `${fullDisplay} ${type === 'signature' ? 'Sign Here' : 'Initial Here'}`;
      const fullDisplayWidth = measureTextWidth(fullText, textElement);
      
      displayName = initialsDisplay;
      const displayRatio = fullDisplayWidth / availableWidth;

      if (fullDisplayWidth <= availableWidth || 
        (availableLines >= 2 && displayRatio <= availableLines)) {
          displayName = fullDisplay;
      }
  }

  function updateMinDimensions() {
    if (textElement) {
      currentMinWidth = 80;
      currentMinHeight = 30;
    }
  }

  function updateMenuPosition() {
    if (!editButtonRef) return;
    const rect = editButtonRef.getBoundingClientRect();
    menuPosition = {
      x: rect.right + window.scrollX,
      y: rect.top + window.scrollY
    };
  }

  function handleAnnotationClick(event) {
    if (!moving && !resizing) {
      isFocused = true;
    }
  }

  function handleAnnotationBlur(event) {
    if (!event.relatedTarget 
      || !event.relatedTarget.closest(`#actionable-annotation-${pageIndex}-${id}`)
    ) {
      isFocused = false;
    }
  }

  function updateTextInputAnnotationForNonPresentOwner() {
    if (isAnnotationOwnerPresent) {
       return;
    }

    if (!isMode('ron_order')) {
      return;
    }

    if (text === '') {
      return;
    }

    text = '';
    dispatch('update', {
        text,
        id,
        pageIndex,
        height,
        width
      });
    }

  // ---------------------------
  // - Lifecycle Event Binding -
  // ---------------------------

  onMount(() => {
    aspectRatio = width / height;
    updateMinDimensions();
    updateDisplayName();
    updateTextInputAnnotationForNonPresentOwner();
  });

  // ---------------------------------------------
  // - Watchers to react to changes in variables -
  // ---------------------------------------------

  $: associatedObjectId, dispatch('actionPerformed', {
    actionPerformed: associatedObjectId !== null,
    objectId: id,
    pageIndex,
    participant,
  });

  //close the menu if another one opens
  $: if (activeEditAnnotationMenu && activeEditAnnotationMenu !== `actionable-${id}` && showTextEditor) {
    showTextEditor = false;
  }

  // update position when x or y changes
  $: if (showTextEditor && (x || y)) {
    updateMenuPosition();
  }

  //  update position when width or height changes
  $: if (showTextEditor && (width || height)) {
    updateMenuPosition();
  }

  $: participant, updateDisplayName();
</script>

<svelte:options immutable={true} />

<div
  id="actionable-annotation-{pageIndex}-{id}"
  class="absolute left-0 top-0 select-none"
  class:cursor-grab={!frozen && !moving}
  class:cursor-grabbing={!frozen && moving}
  class:hidden={!isAnnotationOwnerPresent}
  style="
    z-index: -1; 
    transform-origin: top left; 
    transform: translate({x + dx}px, {y + dy}px);
  "
  use:pannable
  on:panstart={handlePanStart}
  on:panmove={handlePanMove}
  on:panend={handlePanEnd}
  on:click={handleAnnotationClick}
  on:focusout={handleAnnotationBlur}
  tabindex="0"
  bind:this={editable}
>
  <!-- Delete/Check icons -->
  <div 
    class="absolute" 
    style="
      left: {deleteBtnLeftStyle}; 
      color: white; 
      top: -10px;
      transform: translateX(-50%); 
      opacity: {isFocused ? '1' : '0'};
      transition: opacity 0.2s ease;
    "
  >
    {#if isMode('esign_preorder', 'preorder', 'cna_preorder') && !associatedObjectId !== null || isMode('ipen')}
      <span 
        on:pointerup={handleDelete}
        class="delete-button flex justify-center items-center cursor-pointer bg-white rounded w-4 h-4"
        style="
          background-color: {colors.textColor}; 
          top: 0.125rem; 
          right: 0.125rem; 
          border-radius:10px;
        "
      >
        <IconSmallX />
      </span>
    {/if}

    {#if associatedObjectId !== null}
      <span 
        class="absolute flex justify-center items-center bg-white rounded w-4 h-4"
        style="
            color: {colors.hex}; 
            top: 0.125rem; 
            right: 0.125rem; 
            border-radius: 10px; 
            transform:translateX(-50%); 
            left:50%;
        "
      >
        <IconSmallCheck />
      </span>
    {/if}
  </div>

  {#if isMode('esign_preorder', 'preorder', 'cna_preorder')}
    <div
      class="edit-icon-container"
      class:is-resizing={resizing}
      style="opacity: {isFocused ? '1' : '0'}; transition: opacity 0.2s ease;"
    >
      <div class="bg-gray-700 border-0 cursor-pointer h-7 md:h-5 md:w-5 opacity-75 rounded-lg w-7">
        <button 
          bind:this={editButtonRef}
          class="edit-button"
          on:click={toggleTextEditor}
        >
          <img
            alt="edit annotation"
            class="h-full w-full"
            src="/edit-white.svg"
          />
        </button>
      </div>
    </div>
  {/if}

  {#if type === 'textbox'}
    {#if participants.length > 1 || isMode('cls') }
      <div 
        class="label-box text-center"
        style="
          background-color: {colors.backgroundColor}; 
          color: {colors.textColor};
          --border-color: {colors.backgroundColor};
        "
      >
        {initials(participant.full_name)}
      </div>
    {/if}

    <div
      class="flex justify-start items-start border-2 rounded gap-1"
      style="
        background-color: {colors.backgroundColor}; 
        border-color: {colors.borderColor}; 
        color: {colors.textColor};
        height: {height}px; 
        width: {width}px;
        font-family: {fontFamily};
        font-size: {fontSize}px;
        cursor: {getCursorStyle()};
      "
      on:click|stopPropagation={handleTextboxClick}
      on:focusout={handleAnnotationBlur}
      tabindex="0"
    >
      <div class="flex justify-start items-start w-full p-1 relative">
      {#if (!isMode('preorder','esign_preorder') && (placeholder && !text && text.length === 0))}
        <div 
          class="absolute inset-0 text-left break-words opacity-50 p-1"
          style="
            font-family: {fontFamily};
            font-size: {fontSize}px;
            pointer-events: none;
          "
        >
          {placeholder}
        </div>
      {/if}
      <span 
        bind:this={textElement} 
        class="text-left break-words outline-none w-full text-black"
        contenteditable={(isMode('esign_preorder','preorder','cna_preorder','ipen','ron_order') ||  expectedParticipantEmail !== null) && !isMode('notary')}
        on:input={handleInput}
        on:focus={handleFocus}
        on:blur={handleBlur}
        style="
          overflow: hidden;
          overflow-wrap: break-word; 
          white-space: normal; 
          word-break: break-word;
          font-family: {fontFamily};
          font-size: {fontSize}px;
          border: none;
          outline: none;
          color: {isPreorderMode ? colors.textColor : 'black'};
        "
      >
      {textboxTextContent()}
    </span>
  </div>

      {#if isPreorderMode || isMode('ipen')}
        <div 
          class="resize-handle" 
          style="background-color: {colors.borderColor}"
          on:mousedown|stopPropagation={handleResizeStart}
        ></div>
      {/if}
    </div>
  {:else if type === 'signature' || type === 'initials'}
    <div
      class="flex justify-center items-center border-2 rounded gap-1 resize-transition"
      style="
        background-color: {colors.backgroundColor}; 
        border-color: {colors.borderColor}; 
        color: {colors.textColor};
        height: {height}px; 
        width: {width}px; 
      "
    >
      <div class="flex justify-center items-center">
        <span 
          bind:this={textElement} 
          class="text-center break-words"
          style="
            overflow: hidden;
            overflow-wrap: break-word; 
            white-space: normal; 
            word-break: break-word; 
          "
        >
          {displayName || participant.full_name} {type === 'signature' ? 'Sign Here' : 'Initial Here'}
        </span>
      </div>

      {#if isPreorderMode || isMode('ipen')}
        <div 
          class="resize-handle" 
          style="background-color: {colors.borderColor}"
          on:mousedown|stopPropagation={handleResizeStart}
        ></div>
      {/if}
    </div>
  {/if}

  {#if showTextEditor}
    <EditAnnotationTypeMenu
      {type}
      {annotationTypesOptions}
      {fontSize}
      {fontFamily}
      {textColor}
      {Families}
      {width}
      {participant}
      {participants}
      position={menuPosition}
      on:typeChange={({ detail }) => handleTypeChange(detail.type)}
      on:fontSizeChange={({ detail }) => {
        fontSize = detail.fontSize;
        onFontSizeChange();
      }}
      on:increaseFontSize={increaseFontSize}
      on:decreaseFontSize={decreaseFontSize}
      on:fontChange={({ detail }) => {
        fontFamily = detail.fontFamily;
        onChangeFont();
      }}
      on:participantChange={({ detail }) => {
        dispatch('update', {
          ...detail,
          id,
          pageIndex
        });
      }}
      on:close={() => showTextEditor = false}
    />
  {/if}
</div>

<style>
  .label-box {
    margin-left: -51px;
    padding: 2px 4px;
    position: absolute;
    width: 33px;
    z-index: 1;
  }

  .label-box::after {
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent transparent var(--border-color);
    content: '';
    position: absolute;
    left: 33px;
    top: 50%;
    transform: translateY(-50%);
  }

  .resize-handle {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 10px;
    height: 10px;
    cursor: se-resize;
    border-radius: 50%;
  }

  .resize-transition {
    transition: width 0.1s ease, height 0.1s ease;
  }

  .no-controls::-webkit-inner-spin-button,
  .no-controls::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .no-controls {
    -moz-appearance: textfield;
  }

  .color-palette-item {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
  }

  .color-palette-item:checked {
    border: 2px solid #4B5563;
  }

  .opacity-50 {
    opacity: 0.5;
  }

  .menu-section {
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .menu-section:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .edit-icon-container {
    position: absolute;
    z-index: 10;
    left: calc(100% + 8px);
    top: 0;
    transform: translateZ(0);
    will-change: transform, left;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .edit-icon-container.is-resizing {
    /* Instead of disabling transition completely, use a faster, linear one */
    transition: all 0.1s linear;
  }
</style>