<script>
  import {createEventDispatcher} from 'svelte';

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <path
    d="M3 21H21M19 14.8V13.454C19 12.785 18.572 12.189 17.936 11.978L14.333 10.777V7.667L15.417 6.973C16.215 6.461 16.587 5.487 16.33 4.574C16.069 3.642 15.222 3 14.254 3H9.746C8.78 3 7.931 3.642 7.669 4.574C7.413 5.487 7.784 6.461 8.582 6.973L9.666 7.667V10.778L6.063 11.979C5.428 12.19 5 12.784 5 13.455V14.8C5 15.212 5.163 15.609 5.456 15.9L6.1 16.544C6.392 16.837 6.787 17 7.2 17H16.8C17.212 17 17.609 16.837 17.9 16.544L18.544 15.9C18.837 15.608 19 15.213 19 14.8Z"/>
</svg>
