<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 16px; height: 16px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 16 16"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <path d="M3.75 3.75L12.25 12.25M12.25 3.75L3.75 12.25"/>
</svg>
