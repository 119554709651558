<script>
  // ----------------
  // - Dependencies -
  // ----------------

  import { createEventDispatcher, onMount } from "svelte";  
  import { getParticipantColors } from './utils/AnnotationsColorHelper';
  import IconCalendarDay from "./Icons/IconCalendarDay.svelte";
  import IconPenDrawing from "./Icons/IconPenDrawing.svelte";
  import IconPenPad from "./Icons/IconPenPad.svelte";
  import IconSmallCheck from "./Icons/IconSmallCheck.svelte";
  import IconSmallX from "./Icons/IconSmallX.svelte";
  import { initials, isMode, measureTextWidth } from "./utils/helper";
  import { pannable } from "./utils/pannable.js";
  import MenuHeader from './ContextMenu/MenuHeader.svelte';
  import MenuBody from './ContextMenu/MenuBody.svelte';
  import IconMinus from './Icons/IconMinus.svelte';
  import IconPlus from './Icons/IconPlus.svelte';
  import { Fonts } from "./utils/prepareAssets.js";
  import IconEdit from "./Icons/IconEdit.svelte";


  // ---------
  // - Props -
  // ---------

  export let allSessionParticipants;
  export let associatedObjectId = null;
  export let color = 'red';
  export let frozen = false;
  export let fontSize;
  export let fontFamily = 'Helvetica';
  export let height = 60;
  export let hidden = false;
  export let id;
  export let object;
  export let pageIndex;
  export let pageScale;
  export let participant;
  export let participants;
  export let placeholder = '';
  export let textAlign = 'left';
  export let textColor = '#000000';
  export let text = 'Click here to add text';
  export let type = 'signature';
  export let width = 190;
  export let x = 200;
  export let y = 250;


  // -------------
  // - Constants -
  // -------------

  // The difference between participants and allSessionParticipants is that participants is the data for the signers on the same device, and allSessionParticipants is the data for all participants in the session. Aneudy A Jan 14 2025
  $: colors = isMode('cls') 
    ? getParticipantColors(participant, allSessionParticipants)
    : getParticipantColors(participant, participants);

  $: width, height, type, updateDisplayName();

  $: if (!placeholder || placeholder === '') {
    placeholder = 'Click here to add text';
  }

  $: if (object.fontFamily) {
    fontFamily = object.fontFamily;
  }

  const dispatch = createEventDispatcher();
  const isPreorderMode = isMode('esign_preorder', 'preorder', 'cna_preorder');

  // ----------------------
  // - Reactive Variables -
  // ----------------------

  let aspectRatio;
  let _colorSelection = textColor;
  let currentMinHeight = minHeight();
  let currentMinWidth = minWidth();
  let deleteBtnLeftStyle = '50%';
  let displayName;
  let _dialogXPosition = 0;
  let dx = 0;
  let dy = 0;
  let editable;
  let hasMoved = false;
  let initialHeight;
  let initialWidth;
  let initialX = x;
  let initialY = y;
  let isEditing = false;
  let lockAspectRatio = true;
  let moving = false;
  let resizeStartX;
  let resizeStartY;
  let resizing = false;
  let _size = fontSize;
  let startX;
  let startY;
  let textElement;
  let xResize = 0;
  let yResize = 0;
  let showTextEditor = false;
  const Families = Object.keys(Fonts);
  let _fontFamily = fontFamily;

  // ----------------------------
  // - Functions (Alphabetized) -
  // ----------------------------

  function getFirstNameAndInitial(fullName) {
    const words = fullName.split(' ');

    if (words.length === 1) {
      return words[0];
    } else if (words.length > 1) {
      const firstName = words[0];
      const lastNameInitial = words[words.length - 1][0];
      return `${firstName} ${lastNameInitial}.`;
    } else {
      return '';
    }
  }

  function toggleTextEditor() {
    showTextEditor = !showTextEditor;
    if (showTextEditor && !fontSize) {
      fontSize = 12;
    }
  }

  function handleInput(event) {
    text = event.target.textContent;
    
    // Get the text element's computed style
    const style = window.getComputedStyle(textElement);
    const lineHeight = parseInt(style.lineHeight);
    
    // Let the text element wrap naturally based on width
    textElement.style.whiteSpace = 'normal';
    textElement.style.wordWrap = 'break-word';
    
    // Calculate the scrollHeight which includes wrapped content
    const contentHeight = textElement.scrollHeight;
    const requiredHeight = contentHeight + 10; // Add padding
    
    // If text is being deleted, shrink the box height

    // For adding text, only grow the box if needed
    if (requiredHeight > height) {
      height = Math.min(maxHeight(), requiredHeight);
    }

    placeholder = text;

    if (isMode('cls','ipen','esign')) {
        dispatch('update', {
        text,
        id,
        pageIndex,
        height,
        width
      });
    } else {
        dispatch('update', {
        placeholder,
        id,
        pageIndex,
        height,
        width
      });
    }
  }

  function textboxTextContent() {
    if (isMode('preorder', 'esign_preorder') && !text) {
      return 'Click to enter text';
    }

    if (isMode('cls', 'notary')) {
      return text;
    }
    
    return '';
  }


  function updateDisplayName() {
      if (!textElement) return;

      const fullDisplay = getFirstNameAndInitial(participant.full_name);
      const initialsDisplay = initials(participant.full_name);
      const style = window.getComputedStyle(textElement);
      const lineHeight = parseInt(style.lineHeight);
      const availableLines = Math.floor(height / lineHeight);
      
      const availableWidth = width - 20;
      const fullText = `${fullDisplay} ${type === 'signature' ? 'Sign Here' : 'Initial Here'}`;
      const fullDisplayWidth = measureTextWidth(fullText, textElement);
      
      displayName = initialsDisplay;
      const displayRatio = fullDisplayWidth / availableWidth;

      if (fullDisplayWidth <= availableWidth || 
        (availableLines >= 2 && displayRatio <= availableLines)) {
          displayName = fullDisplay;
      }
  }

  function handleClick() {
    if (isMode('ipen') && hasMoved) return null;

    dispatch('click', {
      objectId: id,
      pageIndex,
      pageScale,
      participant,
      type,
      x,
      y,
      width,
      height,
    });
  }

  function handleDelete(event) {
    event.stopPropagation(); // Stop propagation to prevent drag
    dispatch('delete', { id });
  }

  function handlePanEnd(event) {
    x += dx;
    y += dy;

    dispatch("update", {
      eventType: "dragEnd",
      x: x,
      y: y,
    });

    dx = 0;
    dy = 0;
    moving = false;
    
    if (!hasMoved) {
      handleClick();
    }
  }

  function handlePanMove(event) {
    if (frozen) return;

    dx = (event.detail.x - startX) / pageScale;
    dy = (event.detail.y - startY) / pageScale;

    if (Math.abs(dx) > 1 || Math.abs(dy) > 1) {
      hasMoved = true;
    }
  }

  function handlePanStart(event) {
    const target = event.target.closest('.resize-handle, .delete-button');

    if (target) {
      moving = false;
      return;
    }

    startX = event.detail.x;
    startY = event.detail.y;
    moving = true;
    hasMoved = false;
  }

  function handleResizeEnd() {
    resizing = false;
    window.removeEventListener('mousemove', handleResizeMove);
    window.removeEventListener('mouseup', handleResizeEnd);
    dispatch('update', { width, height });
  }

  function handleResizeMove(event) {
    if (!resizing) return;
    xResize = (event.clientX - resizeStartX) / pageScale;
    yResize = (event.clientY - resizeStartY) / pageScale;

    let newHeight = initialHeight + yResize;
    let newWidth = initialWidth + xResize;

    if (type === 'textbox') {
      // Calculate minimum dimensions needed for text
      textElement.style.whiteSpace = 'normal';
      textElement.style.wordWrap = 'break-word';
      
      // Temporarily set the width to test text wrapping
      textElement.style.width = `${newWidth}px`;
      
      // Get the required height for the current width
      const contentHeight = textElement.scrollHeight;
      const requiredHeight = contentHeight + 10; // Add padding
      
      // Get minimum width needed for the longest word
      const words = text.split(' ');
      const longestWord = words.reduce((a, b) => a.length > b.length ? a : b, '');
      const tempSpan = document.createElement('span');
      tempSpan.style.font = window.getComputedStyle(textElement).font;
      tempSpan.style.visibility = 'hidden';
      tempSpan.textContent = longestWord;
      document.body.appendChild(tempSpan);
      const minWordWidth = tempSpan.offsetWidth;
      document.body.removeChild(tempSpan);
      
      const minRequiredWidth = minWordWidth + 20; // Add padding
      
      // Apply constraints
      width = Math.max(minRequiredWidth, Math.min(maxWidth(), newWidth));
      height = Math.max(requiredHeight, Math.min(maxHeight(), newHeight));
      
      // Update text element width to match final width
      textElement.style.width = `${width}px`;
    } else {
      // Original logic for signatures and initials
      if (width < 110 || newWidth < 110) {
        const initialsText = `${initials(participant.full_name)} ${type === 'signature' ? 'Sign Here' : 'Initial Here'}`;
        const textWidth = measureTextWidth(initialsText, textElement);
        const minWidthNeeded = textWidth + 10;
        
        if (newWidth < minWidthNeeded) {
          newWidth = minWidthNeeded;
        }
      }

      height = Math.max(currentMinHeight, Math.min(maxHeight(), newHeight));
      width = Math.max(currentMinWidth, Math.min(maxWidth(), newWidth));

      if (lockAspectRatio) {
        height = width * aspectRatio;
      }
    }

    dispatch('update', {
      id,
      height,
      width,
      pageIndex
    });
  }

  function handleResizeStart(event) {
    event.preventDefault();
    event.stopPropagation(); // Stop propagation to prevent drag
    resizing = true;
    resizeStartX = event.clientX;
    resizeStartY = event.clientY;
    initialWidth = width;
    initialHeight = height;
    aspectRatio = height / width;
    window.addEventListener('mousemove', handleResizeMove);
    window.addEventListener('mouseup', handleResizeEnd);
  }

  function maxHeight() {
    return 300;
  }

  function maxWidth() {
    return 500;
  }

  function minHeight() {
    return 30;
  }

  function minWidth() {
    return 80;
  }

  function onAssociatedObjectIdChange() {
    if (!associatedObjectId) {
      return null;
    }

    dispatch('completedByClient', {
      objectId: id,
      pageIndex,
      participant,
    });
  }

  function updateMinDimensions() {
    if (textElement) {
      currentMinWidth = 80;
      currentMinHeight = 30;
    }
  }

  function handleFocus() {
    if (isMode('cls')) {
      isEditing = true;
    }
  }

  function handleBlur() {
    if (isMode('cls')) {
      isEditing = false;
    }
  }

  function handleTextUpdate(event) {
    const updates = event.detail;
    
    text = updates.text;
    placeholder = updates.placeholder || placeholder;
    fontSize = updates.fontSize;
    fontFamily = updates.fontFamily;
    textAlign = updates.textAlign;
    textColor = updates.textColor;

    // Let the text wrap and calculate new height needed
    if (textElement) {
      // Update text element styles with new font size
      textElement.style.whiteSpace = 'normal';
      textElement.style.wordWrap = 'break-word';
      textElement.style.fontSize = `${fontSize}px`;
      textElement.style.fontFamily = fontFamily;
      
      // Calculate new required height
      const contentHeight = textElement.scrollHeight;
      const requiredHeight = contentHeight + 10; // Add padding
      
      // Adjust height if needed
      if (requiredHeight > height) {
        height = Math.min(maxHeight(), requiredHeight);
      }
    }

    dispatch('update', {
      id,
      text,
      fontSize,
      fontFamily,
      textAlign,
      textColor,
      height,
      width,
      pageIndex,
    });
  }

  function increaseFontSize() {
      fontSize = Math.min(72, fontSize + 1); 
      onFontSizeChange();
  }

  function decreaseFontSize() {
      fontSize = Math.max(8, fontSize - 1); 
      onFontSizeChange();
  }

  function onFontSizeChange() {
      console.log('onFontSizeChange called, current fontSize:', fontSize);
      handleTextUpdate({
          detail: {
              text,
              fontSize,
              fontFamily,
              textAlign,
              textColor
          }
      });
  }

  function onChangeFont() {
      console.log('onChangeFont called, current fontFamily:', fontFamily);
      handleTextUpdate({
          detail: {
              text,
              fontSize,
              fontFamily,
              textAlign,
              textColor
          }
      });
  }

  // ---------------------------
  // - Lifecycle Event Binding -
  // ---------------------------

  onMount(() => {
    aspectRatio = width / height;
    updateMinDimensions();
    updateDisplayName();
  });

  // ---------------------------------------------
  // - Watchers to react to changes in variables -
  // ---------------------------------------------

  $: associatedObjectId, dispatch('actionPerformed', {
    actionPerformed: associatedObjectId !== null,
    objectId: id,
    pageIndex,
    participant,
  });
</script>

<svelte:options immutable={true} />

<div
  id="actionable-annotation-{pageIndex}-{id}"
  class="absolute left-0 top-0 select-none"
  class:hidden={hidden}
  class:cursor-grab={!frozen && !moving}
  class:cursor-grabbing={!frozen && moving}
  style="
    z-index: -1; 
    transform-origin: top left; 
    transform: translate({x + dx}px, {y + dy}px);
  "
  use:pannable
  on:panstart={handlePanStart}
  on:panmove={handlePanMove}
  on:panend={handlePanEnd}
  bind:this={editable}
>
  <div 
    class="absolute" 
    style="
      left: {deleteBtnLeftStyle}; 
      color: white; 
      top: -10px;
      transform: translateX(-50%); 
    "
  >
    {#if isMode('esign_preorder', 'preorder', 'cna_preorder') && !associatedObjectId !== null || isMode('ipen')}
      <span 
        on:pointerup={handleDelete}
        class="delete-button flex justify-center items-center cursor-pointer bg-white rounded w-4 h-4"
        style="
          background-color: {colors.textColor}; 
          top: 0.125rem; 
          right: 0.125rem; 
          border-radius:10px;
        "
      >
        <IconSmallX />
      </span>
    {/if}

    {#if associatedObjectId !== null}
      <span 
        class="absolute flex justify-center items-center bg-white rounded w-4 h-4"
        style="
            color: {colors.hex}; 
            top: 0.125rem; 
            right: 0.125rem; 
            border-radius: 10px; 
            transform:translateX(-50%); 
            left:50%;
        "
      >
        <IconSmallCheck />
      </span>
    {/if}
  </div>

  {#if type === 'textbox'}
    <div 
      class="label-box text-center"
      style="
        background-color: {colors.backgroundColor}; 
        color: {colors.textColor};
        --border-color: {colors.backgroundColor};
      "
    >
      {initials(participant.full_name)}
    </div>

    <div
      class="flex justify-start items-start border-2 rounded gap-1"
      style="
        background-color: {colors.backgroundColor}; 
        border-color: {colors.borderColor}; 
        color: {colors.textColor};
        height: {height}px; 
        width: {width}px;
        font-family: {fontFamily};
        font-size: {fontSize}px;
      "
    >
      <div class="flex justify-start items-start w-full p-1 relative">
      {#if (!isMode('preorder','esign_preorder') && (placeholder && !text && text.length === 0))}
        <div 
          class="absolute inset-0 text-left break-words opacity-50 p-1"
          style="
            font-family: {fontFamily};
            font-size: {fontSize}px;
            pointer-events: none;
          "
        >
          {placeholder}
        </div>
      {/if}
      <span 
        bind:this={textElement} 
        class="text-left break-words outline-none w-full text-black"
        contenteditable={isPreorderMode || isMode('cls','esign','ipen')}
        on:input={handleInput}
        on:focus={handleFocus}
        on:blur={handleBlur}
        style="
          overflow: hidden;
          overflow-wrap: break-word; 
          white-space: normal; 
          word-break: break-word;
          font-family: {fontFamily};
          font-size: {fontSize}px;
          border: none;
          outline: none;
          color: {isPreorderMode ? colors.textColor : 'black'};
        "
      >
      {textboxTextContent()}
    </span>
  </div>

      {#if isMode('esign_preorder', 'preorder', 'cna_preorder')}
        <div
          class="absolute select-none"
          style="left: {width + 8}px; top: 0;"
        >
          <div class="bg-gray-700 border-0 cursor-pointer h-7 md:h-5 md:w-5 opacity-75 rounded-lg w-7">
            <img
              on:click={toggleTextEditor}
              alt="edit text"
              class="h-full w-full"
              src="/edit-white.svg"
            />
          </div>
        </div>
      {/if}

      {#if isPreorderMode || isMode('ipen')}
        <div 
          class="resize-handle" 
          style="background-color: {colors.borderColor}"
          on:mousedown|stopPropagation={handleResizeStart}
        ></div>
      {/if}
    </div>
  {/if}

  {#if type === 'date'}
    <div
      class="items-center border-2 rounded-b-md rounded-tr-md gap-1 overflow-hidden"
      style="
        background-color: rgba({colors[color].rgb}, 0.1); 
        border-color: rgb({colors[color].rgb}); 
        color: {colors[color].hex}
      "
    >
      <div
        class="flex justify-center items-center"
      >
        <select
          class="px-2 py-1 bg-transparent border-none outline-none"
          style="max-width: 112px;"
        >
          <option value="default">09/08/23</option>
          <option value="formatted">Sep. 8, 2023</option>
        </select>

        <button
          class="px-3 py-1 text-white ml-2"
          style="background-color: {color}"
        >
          <span>Place</span>
        </button>
      </div>
    </div>
  {/if}

  {#if type === 'signature' || type === 'initials'}
    <div
      class="flex justify-center items-center border-2 rounded gap-1 resize-transition"
      style="
        background-color: {colors.backgroundColor}; 
        border-color: {colors.borderColor}; 
        color: {colors.textColor};
        height: {height}px; 
        width: {width}px; 
      "
    >
      <div class="flex justify-center items-center">
        <span 
          bind:this={textElement} 
          class="text-center break-words"
          style="
            overflow: hidden;
            overflow-wrap: break-word; 
            white-space: normal; 
            word-break: break-word; 
          "
        >
          {displayName || participant.full_name} {type === 'signature' ? 'Sign Here' : 'Initial Here'}
        </span>
      </div>

      {#if isPreorderMode || isMode('ipen')}
        <div 
          class="resize-handle" 
          style="background-color: {colors.borderColor}"
          on:mousedown|stopPropagation={handleResizeStart}
        ></div>
      {/if}
    </div>
  {/if}
</div>

{#if showTextEditor}
  <div class="absolute z-50" style="left: {x + width + 10}px; top: {y}px;">
    <div class="bg-white rounded-lg shadow-lg w-64">
      <MenuHeader
        title="Edit text..."
        on:close={() => showTextEditor = false}
      />

      <MenuBody>
        <div class="flex flex-col w-full">
          <div class="flex gap-2 pb-2">
            <div class="flex items-center color-gray-500">
              <IconMinus on:click={decreaseFontSize}/>
              <input
                bind:value={fontSize}
                on:change={onFontSizeChange}
                type="number"
                class="no-controls w-full p-1 border border-gray-300 rounded-lg text-center"
              />
              <IconPlus on:click={increaseFontSize}/>
            </div>

            <div class="flex-shrink-0 p-1 border border-gray-300 rounded-lg color-palette">
              <input 
                type="radio"
                name="color"
                bind:group={textColor}
                value={textColor} 
                class="bg-black color-palette-item"
                checked
              />
            </div>
          </div>

          <select
            bind:value={fontFamily}
            on:change={onChangeFont}
            class="w-full p-1 border border-gray-300 rounded-lg"
          >
            {#each Families as family}
              <option value={family}>{family}</option>
            {/each}
          </select>
        </div>
      </MenuBody>
    </div>
  </div>
{/if}

<style>
  .label-box {
    margin-left: -51px;
    padding: 2px 4px;
    position: absolute;
    width: 33px;
    z-index: 1;
  }

  .label-box::after {
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent transparent var(--border-color);
    content: '';
    position: absolute;
    left: 33px;
    top: 50%;
    transform: translateY(-50%);
  }

  .resize-handle {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 10px;
    height: 10px;
    cursor: se-resize;
    border-radius: 50%;
  }

  .resize-transition {
    transition: width 0.1s ease, height 0.1s ease;
  }

  .no-controls::-webkit-inner-spin-button,
  .no-controls::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .no-controls {
    -moz-appearance: textfield;
  }

  .color-palette-item {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
  }

  .color-palette-item:checked {
    border: 2px solid #4B5563;
  }

  .opacity-50 {
    opacity: 0.5;
  }
</style>