<script>
  // ...
</script>

<style global>
    .context-menu-body {
        @apply flex items-center px-3 py-2;
    }
</style>

<div class="context-menu-body">
  <slot></slot>
</div>