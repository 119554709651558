<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <path d="M7.5 3.00002V6.00002"/>
  <path d="M16.5 3.00002V6.00002"/>
  <path
    d="M18 4.50002H6C4.34315 4.50002 3 5.84316 3 7.50002V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V7.50002C21 5.84316 19.6569 4.50002 18 4.50002Z"/>
  <path
    d="M10 10H8C7.44772 10 7 10.4477 7 11V13C7 13.5523 7.44772 14 8 14H10C10.5523 14 11 13.5523 11 13V11C11 10.4477 10.5523 10 10 10Z"/>
</svg>
