export function tapout(node) {
  function handleTouchstart(event) {
    if (!event.timeStamp || event.timeStamp + 500 < Date.now()) {
      if (!Array.from(event.touches).some((touch) => node.contains(touch.target))) {
        node.dispatchEvent(new CustomEvent('tapout'));
      }
    }
  }

  function handleMousedown(event) {
    if (!node.contains(event.target)) {
      node.dispatchEvent(new CustomEvent('tapout'));
    }
  }

  function handlePointerup(event) {
    if (!node.contains(event.target)) {
      node.dispatchEvent(new CustomEvent('tapout', {
        detail: {
          node: node,
          target: event.target,
        }
      }));
    }
  }

  // window.addEventListener('touchstart', handleTouchstart);
  // window.addEventListener('mousedown', handleMousedown);
  window.addEventListener('pointerup', handlePointerup);
  return {
    destroy() {
      // window.removeEventListener('touchstart', handleTouchstart);
      // window.removeEventListener('mousedown', handleMousedown);
      window.removeEventListener('pointerup', handlePointerup);
    },
  };
}
