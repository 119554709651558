<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path d="M18.556,16.223    l-1.945,1.945l-1.167-1.167"></path>
    <path d="M9,18H6    c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h12c1.657,0,3,1.343,3,3v4"></path>
    <line x1="7" y1="11.5" x2="10" y2="11.5"></line>
    <line x1="7" y1="7" x2="16" y2="7"></line>
    <path d="M12.275,17.814    l0.664,0.869l0.144,1.081c0.08,0.6,0.551,1.072,1.151,1.152l1.084,0.145l0.868,0.664c0.48,0.367,1.147,0.367,1.627,0l0.869-0.664    h-0.001l1.082-0.144c0.6-0.08,1.072-0.551,1.152-1.151l0.145-1.084c0,0.001,0.336-0.439,0.664-0.868    c0.367-0.48,0.367-1.147,0-1.627l-0.664-0.869l-0.144-1.081c-0.08-0.6-0.551-1.072-1.151-1.152l-1.084-0.145l-0.868-0.664    c-0.48-0.367-1.147-0.367-1.627,0l-0.869,0.664h0.001l-1.082,0.144c-0.6,0.08-1.072,0.551-1.152,1.151l-0.145,1.084    c0-0.001-0.336,0.439-0.664,0.868C11.908,16.667,11.908,17.333,12.275,17.814z"></path>
  </g>
</svg>