<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <path d="M18,21h-12c-1.657,0 -3,-1.343 -3,-3v-12c0,-1.657 1.343,-3 3,-3h12c1.657,0 3,1.343 3,3v12c0,1.657 -1.343,3 -3,3Z"></path>
  <path d="M10.25,15.5h3.5"></path>
  <path d="M12,8.5v7"></path>
  <path d="M15.5,10.084v-1.195c0,-0.215 -0.174,-0.389 -0.389,-0.389h-6.222c-0.215,0 -0.389,0.174 -0.389,0.389v1.196"></path>
</svg>