<script>
  import {createEventDispatcher} from "svelte";
  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';
  const dispatch = createEventDispatcher();
  function emitClick() {
    dispatch('click');
  }
</script>
<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <!-- Outer circle -->
  <circle cx="12" cy="12" r="9" stroke="currentColor" stroke-width="1.5"></circle>
  
  <!-- Inner dot -->
  <circle cx="12" cy="12" r="4" fill="currentColor"></circle>
</svg>