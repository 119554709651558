<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path
        d="m16 3h-8c-2.76142 0-5 2.23858-5 5v8c0 2.7614 2.23858 5 5 5h8c2.7614 0 5-2.2386 5-5v-8c0-2.76142-2.2386-5-5-5z"/>
    <path d="m8 8v8"/>
  </g>
</svg>