<script>
  // ----------------
  // - Dependencies -
  // ----------------

  import { createEventDispatcher, onMount } from "svelte";  
  import { getParticipantColors } from './utils/AnnotationsColorHelper';
  import IconCalendarDay from "./Icons/IconCalendarDay.svelte";
  import IconPenDrawing from "./Icons/IconPenDrawing.svelte";
  import IconPenPad from "./Icons/IconPenPad.svelte";
  import IconPlus from "./Icons/IconPlus.svelte";
  import IconSmallCheck from "./Icons/IconSmallCheck.svelte";
  import IconSmallX from "./Icons/IconSmallX.svelte";
  import { initials, isMode } from "./utils/helper";
  import { pannable } from "./utils/pannable.js";
  import { ulid } from 'ulid';

  // ---------
  // - Props -
  // ---------

  export let actionElementsProp = [];
  export let allSessionParticipants;
  export let actionGroupType = 'radioBtn';
  export let color = 'red';
  export let elementSize = 25;
  export let filledElementsProp = [];
  export let frozen = false;
  export let height = 60;
  export let hidden = false;
  export let id;
  export let pageIndex;
  export let pageScale;
  export let participant;
  export let participants;
  export let type = 'action-group-annotation';
  export let width = 190;
  export let x = 200;
  export let y = 250;

  // -------------
  // - Constants -
  // -------------

  // The difference between participants and allSessionParticipants is that participants is the data for the signers on the same device, and allSessionParticipants is the data for all participants in the session. Aneudy A Jan 14 2025
  $: colors = isMode('cls') 
    ? getParticipantColors(participant, allSessionParticipants)
    : getParticipantColors(participant, participants);

  $: isAnnotationOwnerPresent = isAnnotationOwnerPresent();

  const dispatch = createEventDispatcher();
  const isPreorderMode = isMode('cna_preorder', 'esign_preorder', 'preorder');

  // ----------------------
  // - Reactive Variables -
  // ----------------------

  let actionElements = [];
  let aspectRatio;
  let containerHeight = height;
  let containerWidth = width;
  let currentMinHeight = minHeight();
  let currentMinWidth = minWidth();
  let dx = 0;
  let dy = 0;
  let _dialogXPosition = 0;
  let editable;
  let filledElements = [];
  let hasMoved = false;
  let initialHeight;
  let initialWidth;
  let initialX = x;
  let initialY = y;
  let moving = false;
  let resizeStartX;
  let resizeStartY;
  let resizing = false;
  let startX;
  let startY;
  let xResize = 0;
  let yResize = 0;

  // ----------------------------
  // - Functions (Alphabetized) -
  // ----------------------------

  function addNewSubElement(event) {
    event.stopPropagation(); // Stop propagation to prevent drag
    
    let elementPosition = {
      x: (containerWidth / 2) + Math.floor(Math.random() * 21) - 10,
      y: (containerHeight / 2) + Math.floor(Math.random() * 21) - 10,
    };

    if (actionElements.length < 2) {
      elementPosition.x = actionElements[0].x + (1.8 * elementSize);
      elementPosition.y = actionElements[0].y;
    }

    let newElement = makeNewSubElement(elementPosition);
    actionElements.push(newElement);
    actionElements = [...actionElements]; // Trigger reactivity
  }

  function clickedActionElement(event) {
    if (!actionElements.length) return null;
    
    const rect = editable.getBoundingClientRect();
    const clickX = (event.clientX - rect.left) / pageScale;
    const clickY = (event.clientY - rect.top) / pageScale;

    for (let i = 0; i < actionElements.length; i++) {
      const element = actionElements[i];
      const elementX = element.currentX;
      const elementY = element.currentY;
      
      // Check if click is within radio button bounds
      if (clickX >= elementX
        && clickX <= elementX + elementSize
        && clickY >= elementY
        && clickY <= elementY + elementSize
      ) {
        return element.ulid;
      }
    }

    return null;
  }

  function emitUpdate() {
    dispatch('update', {
      actionElements,
      actionGroupType,
      elementSize,
      filledElements,
      height,
      width, 
      x,
      y,
    });
  }

  function getFirstNameAndInitial(fullName) {
    const words = fullName.split(' ');

    if (words.length === 1) {
      return words[0];
    } else if (words.length > 1) {
      const firstName = words[0];
      const lastNameInitial = words[words.length - 1][0];
      return `${firstName} ${lastNameInitial}.`;
    } else {
      return '';
    }
  }

  function handleCheckboxClicked(clickedElement) {
    const index = filledElements.indexOf(clickedElement);

    if (index > -1) {
      filledElements = filledElements.filter(i => i !== clickedElement);
    } else {
      filledElements = [...filledElements, clickedElement];
    }

    if (filledElements.length > 0) {
      dispatch('completedByClient', {
        objectId: id,
        pageIndex,
        participant,
        shouldScroll: false,
      });
    }
  }

  function handleClick() {
    if (!isMode('cls', 'ron_order')) return null;

    const clickedElement = clickedActionElement(event);

    if (clickedElement === null) {
      return null;
    }

    if (actionGroupType === 'radioBtn') {
      handleRadioButtonClicked(clickedElement);
    } else {
      handleCheckboxClicked(clickedElement);
    }
    
    emitUpdate();
  }

  function handleDelete(event) {
    event.stopPropagation(); // Stop propagation to prevent drag
    dispatch('delete', { id });
  }

  function handleElementPanEnd(event, index) {
    actionElements[index].isDragging = false;
    actionElements[index].x = actionElements[index].currentX;
    actionElements[index].y = actionElements[index].currentY;
    actionElements = [...actionElements]; // Trigger reactivity
    emitUpdate();
  }

  function handleElementPanMove(event, index) {
    if (frozen) return null;
    if (!actionElements[index].isDragging) return;

    const dx = (event.detail.x - actionElements[index].startX) / pageScale;
    const dy = (event.detail.y - actionElements[index].startY) / pageScale;

    actionElements[index].currentX = actionElements[index].x + dx;
    actionElements[index].currentY = actionElements[index].y + dy;
    actionElements = [...actionElements]; // Trigger reactivity

    updateContainerSizeAndPosition();
  }

  function handleElementPanStart(event, index) {
    if (frozen) return null;

    actionElements[index].isDragging = true;
    actionElements[index].startX = event.detail.x;
    actionElements[index].startY = event.detail.y;
    actionElements = [...actionElements]; // Trigger reactivity
  }

  function handleRadioButtonClicked(clickedElement) {
    const index = filledElements.indexOf(clickedElement);

    if (index > -1) {
      filledElements = [];
    } else {
      filledElements = [clickedElement];

      dispatch('completedByClient', {
        objectId: id,
        pageIndex,
        participant,
        shouldScroll: false, // eventually this will be true, but we have to fix App.svelte's scrollToNextActionableAnnotation() first (RA, Nov 2024)
      });
    }
  }

  function handleResizeEnd() {
    window.ES_panning_object = false;
    resizing = false;
    window.removeEventListener('mousemove', handleResizeMove);
    window.removeEventListener('mouseup', handleResizeEnd);
    emitUpdate();
  }

  function handleResizeMove(event) {
    if (!resizing) return;
    xResize = (event.clientX - resizeStartX) / pageScale;
    yResize = (event.clientY - resizeStartY) / pageScale;
    const maxChange = 20;
    if (xResize > maxChange) xResize = maxChange;
    if (yResize > maxChange) yResize = maxChange;

    let newHeight = initialHeight + yResize;
    let newWidth = initialWidth + xResize;

    // Enforce lower minimum resizing limits
    newHeight = Math.max(currentMinHeight, Math.min(maxHeight(), newHeight));
    newWidth = Math.max(currentMinWidth, Math.min(maxWidth(), newWidth));

    newHeight = newWidth * aspectRatio;
    let dampingFactor = 0.04;

    if (yResize < 0) {
      dampingFactor = 0.01;
    }

    elementSize += (
      (newHeight / height * elementSize) - elementSize
    ) * dampingFactor;

    setTimeout(() => {
      updateContainerSizeAndPosition();
    }, 150);
  }

  function handleResizeStart(event) {
    event.preventDefault();
    event.stopPropagation(); // Stop propagation to prevent drag
    window.ES_panning_object = true; // prevents the page from panning while the resize is occuring (RA, Dec 2024)
    resizing = true;
    resizeStartX = event.clientX;
    resizeStartY = event.clientY;
    initialWidth = width;
    initialHeight = height;
    aspectRatio = height / width;
    window.addEventListener('mousemove', handleResizeMove);
    window.addEventListener('mouseup', handleResizeEnd);
  }

  function isAnnotationOwnerPresent() {
    if (isMode('notary')) {
      return participants.some(p => p.email === participant.email);
    }

    if (isMode('esign')) {
       return participant.email === currentParticipantEmail
    }

    if (isMode('ron_order')) {
      return participants.some(p => p.email === participant.email);
    }
  
    return participants.some(p => p.email === participant.email) || allSessionParticipants.some(p => p.email === participant.email);
  }

  function makeNewSubElement(inputs) {
    return {
      currentX: inputs.x || 0,
      currentY: inputs.y || 0,
      isDragging: false,
      startX: 0,
      startY: 0,
      ulid: inputs.ulid || ulid(),
      x: inputs.x || 0,
      y: inputs.y || 0,
    };
  }

  function maxHeight() {
    return 300;
  }

  function maxWidth() {
    return 500;
  }

  function minHeight() {
    return 30;
  }

  function minWidth() {
    return 80;
  }

  function updateAnnotationsForNonPresentOwner() {
    if (isAnnotationOwnerPresent) {
      return;
    }

    if (!isMode('ron_order')) {
      return;
    }

    if (filledElements.length === 0) {
      return;
    } 

    filledElements = [];
    emitUpdate();
  }

  function updateContainerSizeAndPosition() {
    if (actionElements.length === 0) return;

    const xPositions = actionElements.map(el => el.currentX);
    const yPositions = actionElements.map(el => el.currentY);
    
    const minX = Math.min(...xPositions);
    const maxX = Math.max(...xPositions) + elementSize;
    const minY = Math.min(...yPositions);
    const maxY = Math.max(...yPositions) + elementSize;

    containerWidth = maxX - Math.min(0, minX) + 10;
    containerHeight = maxY - Math.min(0, minY) + 10;

    if (minX > 0) {
      containerWidth -= minX;
    }

    if (minY > 0) {
      containerHeight -= minY;
    }

    x += minX;
    y += minY;

    actionElements.forEach(el => {
      el.currentX -= minX;
      el.x -= minX;
      el.currentY -= minY;
      el.y -= minY;
    });
  }

  // ---------------------------
  // - Lifecycle Event Binding -
  // ---------------------------

  onMount(() => {
    aspectRatio = width / height;
    updateContainerSizeAndPosition();
    updateAnnotationsForNonPresentOwner();
  });

  // ---------------------------------------------
  // - Watchers to react to changes in variables -
  // ---------------------------------------------

  $: {
    actionElements = actionElementsProp.map((element) => {
      return makeNewSubElement(element);
    });
  }

  $: {
    filledElements = filledElementsProp.map((element) => {
      return element;
    });
  }
</script>

<svelte:options immutable={true} />

<div
  id="action-group-annotation-{pageIndex}-{id}"
  class="absolute left-0 top-0 select-none"
  class:hidden={!isAnnotationOwnerPresent}
  on:click={handleClick}
  style="
    z-index: -1; 
    transform-origin: top left; 
    transform: translate({x + dx}px, {y + dy}px);
  "
  bind:this={editable}
>
  <!-- Initials label -->
  <div 
    class="label-box text-center"
    style="
      background-color: {colors.backgroundColor}; 
      color: {colors.textColor};
      --border-color: {colors.backgroundColor};
    "
  >
    {initials(participant.full_name)}
  </div>

  <div 
    class="absolute" 
    style="
      color: white; 
      right: -15px; 
      top: -10px;
      transform: translateX(-50%); 
    "
  >
    {#if isMode('esign_preorder', 'preorder', 'cna_preorder', 'ipen')}
      <span 
        on:pointerup={handleDelete}
        class="delete-button flex justify-center items-center cursor-pointer bg-white rounded w-4 h-4"
        style="
          background-color: {colors.borderColor}; 
          top: 0.125rem; 
          right: 0.125rem; 
          border-radius:10px;
        "
      >
        <IconSmallX />
      </span>
    {/if}
  </div>

  <div
    class="border-2 rounded gap-1 resize-transition"
    style="
      border-color: {colors.borderColor}; 
      border-style: dashed;
      color: {colors.textColor};
      height: {containerHeight}px; 
      width: {containerWidth}px; 
    "
  >
    {#each actionElements as actionElement, i}
      <div
        class="absolute flex gap-1 items-center justify-center"
        class:bg-gray-400={!frozen && actionGroupType === 'radioBtn'}
        class:bg-white={actionGroupType === 'checkbox'}
        class:border-2={!frozen}
        class:border-gray-500={!frozen}
        class:cursor-move={!frozen}
        data-stop-pannable-propagation="true"
        on:panstart={(e) => handleElementPanStart(e, i)}
        on:panmove={(e) => handleElementPanMove(e, i)}
        on:panend={(e) => handleElementPanEnd(e, i)}
        style="
          height: {elementSize}px; 
          left: {actionElement.currentX}px;
          top: {actionElement.currentY}px;
          width: {elementSize}px; 
        "
        use:pannable
      >
        <div 
          class="bg-white"
          class:border-2={frozen}
          class:border-gray-800={frozen}
          class:bg-gray-800={actionGroupType === 'radioBtn' && filledElements.includes(actionElement.ulid)}
          class:rounded-full={actionGroupType === 'radioBtn'}
          style="
            height: {elementSize * 0.68}px;
            width: {elementSize * 0.68}px;
          "
        >
          {#if actionGroupType === 'checkbox' && filledElements.includes(actionElement.ulid)}
            <div 
              style="
                height: 250%;
                left: -60%;
                position: relative;
                top: -110%;
                width: 250%;
              "
            >
              <IconSmallCheck
                style="
                  color: black;
                "
              />
            </div>
          {/if}
        </div>
      </div>
    {/each}

    {#if isMode('esign_preorder', 'preorder', 'cna_preorder', 'ipen')}
      <span 
        on:pointerup={addNewSubElement}
        class="bg-blue-600 absolute flex justify-center items-center cursor-pointer rounded w-4 h-4"
        style="
          bottom: -8px;
          color: white;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 10px;
        "
      >
        <IconPlus />
      </span>
    {/if}

    {#if isPreorderMode || isMode('ipen')}
      <div 
        class="resize-handle" 
        on:mousedown|stopPropagation={handleResizeStart}
      ></div>
    {/if}
  </div>
</div>

<style>
  .label-box {
    margin-left: -51px;
    padding: 2px 4px;
    position: absolute;
    width: 33px;
    z-index: 1;
  }

  .label-box::after {
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent transparent var(--border-color);
    content: '';
    position: absolute;
    left: 33px;
    top: 50%;
    transform: translateY(-50%);
  }

  .resize-handle {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 10px;
    height: 10px;
    background-color: rgb(245, 101, 101);
    cursor: se-resize;
    border-radius: 50%;
  }

  .resize-transition {
    transition: width 0.1s ease, height 0.1s ease;
  }
</style>