<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <g>
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"/>
    <path
      d="M15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75V10.25C9 11.9069 10.3431 13.25 12 13.25C13.6569 13.25 15 11.9069 15 10.25V9.75Z"/>
    <path
      d="M5.636 18.364C7.14958 16.8504 9.20245 16 11.343 16H12.556C14.1495 16.0001 15.7073 16.4719 17.033 17.356L18.433 18.288"/>
  </g>
</svg>
