<script>
  // ----------------
  // - Dependencies -
  // ----------------

  import { createEventDispatcher, onMount } from "svelte";  
  import IconCalendarDay from "./Icons/IconCalendarDay.svelte";
  import IconPenDrawing from "./Icons/IconPenDrawing.svelte";
  import IconPenPad from "./Icons/IconPenPad.svelte";
  import IconSmallCheck from "./Icons/IconSmallCheck.svelte";
  import IconSmallX from "./Icons/IconSmallX.svelte";
  import { initials, isMode } from "./utils/helper";
  import { pannable } from "./utils/pannable.js";

  // ---------
  // - Props -
  // ---------

  export let associatedObjectId = null;
  export let color = 'red';
  export let frozen = false;
  export let height = 60;
  export let hidden = false;
  export let id;
  export let pageIndex;
  export let pageScale;
  export let participant;
  export let type = 'signature';
  export let width = 190;
  export let x = 200;
  export let y = 250;

  // -------------
  // - Constants -
  // -------------

  const colors = {
    red: {
      hex: '#F56565',
      rgb: '245, 101, 101',
    },
  };

  const dispatch = createEventDispatcher();
  const isPreorderMode = isMode('esign_preorder', 'preorder', 'cna_preorder');

  // ----------------------
  // - Reactive Variables -
  // ----------------------

  let _dialogXPosition = 0;
  let aspectRatio;
  let currentMinHeight = minHeight();
  let currentMinWidth = minWidth();
  let deleteBtnLeftStyle = '50%';
  let dx = 0;
  let dy = 0;
  let editable;
  let hasMoved = false;
  let initialHeight;
  let initialWidth;
  let initialX = x;
  let initialY = y;
  let lockAspectRatio = true;
  let moving = false;
  let resizing = false;
  let resizeStartX;
  let resizeStartY;
  let startX;
  let startY;
  let textElement;
  let xResize = 0;
  let yResize = 0;

  // ----------------------------
  // - Functions (Alphabetized) -
  // ----------------------------

  function getFirstNameAndInitial(fullName) {
    const words = fullName.split(' ');

    if (words.length === 1) {
      return words[0];
    } else if (words.length > 1) {
      const firstName = words[0];
      const lastNameInitial = words[words.length - 1][0];
      return `${firstName} ${lastNameInitial}.`;
    } else {
      return '';
    }
  }

  function handleClick() {
    if (isMode('ipen') && hasMoved) return null;

    dispatch('click', {
      objectId: id,
      pageIndex,
      pageScale,
      participant,
      type,
      x,
      y,
      width,
      height,
    });
  }

  function handleDelete(event) {
    event.stopPropagation(); // Stop propagation to prevent drag
    dispatch('delete', { id });
  }

  function handlePanEnd(event) {
    x += dx;
    y += dy;

    dispatch("update", {
      eventType: "dragEnd",
      x: x,
      y: y,
    });

    dx = 0;
    dy = 0;
    moving = false;
    
    if (!hasMoved) {
      handleClick();
    }
  }

  function handlePanMove(event) {
    if (frozen) return;

    dx = (event.detail.x - startX) / pageScale;
    dy = (event.detail.y - startY) / pageScale;

    if (Math.abs(dx) > 1 || Math.abs(dy) > 1) {
      hasMoved = true;
    }
  }

  function handlePanStart(event) {
    const target = event.target.closest('.resize-handle, .delete-button');

    if (target) {
      moving = false;
      return;
    }

    startX = event.detail.x;
    startY = event.detail.y;
    moving = true;
    hasMoved = false;
  }

  function handleResizeEnd() {
    resizing = false;
    window.removeEventListener('mousemove', handleResizeMove);
    window.removeEventListener('mouseup', handleResizeEnd);
    dispatch('update', { width, height });
  }

  function handleResizeMove(event) {
    if (!resizing) return;
    xResize = (event.clientX - resizeStartX) / pageScale;
    yResize = (event.clientY - resizeStartY) / pageScale;

    let newHeight = initialHeight + yResize;
    let newWidth = initialWidth + xResize;

    // Enforce lower minimum resizing limits
    height = Math.max(currentMinHeight, Math.min(maxHeight(), newHeight));
    width = Math.max(currentMinWidth, Math.min(maxWidth(), newWidth));

    if (lockAspectRatio) {
      height = width * aspectRatio;
    }

    updateMinDimensions();  // Recalculate min dimensions after resizing
  }

  function handleResizeStart(event) {
    event.preventDefault();
    event.stopPropagation(); // Stop propagation to prevent drag
    resizing = true;
    resizeStartX = event.clientX;
    resizeStartY = event.clientY;
    initialWidth = width;
    initialHeight = height;
    aspectRatio = height / width;
    window.addEventListener('mousemove', handleResizeMove);
    window.addEventListener('mouseup', handleResizeEnd);
  }

  function maxHeight() {
    return 300;
  }

  function maxWidth() {
    return 500;
  }

  function minHeight() {
    return 30;
  }

  function minWidth() {
    return 80;
  }

  function onAssociatedObjectIdChange() {
    if (!associatedObjectId) {
      return null;
    }

    dispatch('completedByClient', {
      objectId: id,
      pageIndex,
      participant,
    });
  }

  function updateMinDimensions() {
    if (textElement) {
      const textRect = textElement.getBoundingClientRect();
      currentMinWidth = Math.max(80, textRect.width + 10);  // Update the minimum width but keep it lower.
      currentMinHeight = Math.max(30, textRect.height + 5);  // Update the minimum height but allow for a smaller box.
    }
  }

  // ---------------------------
  // - Lifecycle Event Binding -
  // ---------------------------

  onMount(() => {
    aspectRatio = width / height;
    updateMinDimensions();
  });

  // ---------------------------------------------
  // - Watchers to react to changes in variables -
  // ---------------------------------------------

  $: associatedObjectId, dispatch('actionPerformed', {
    actionPerformed: associatedObjectId !== null,
    objectId: id,
    pageIndex,
    participant,
  });
</script>

<svelte:options immutable={true} />

<div
  id="actionable-annotation-{pageIndex}-{id}"
  class="absolute left-0 top-0 select-none"
  class:hidden={hidden}
  class:cursor-grab={!frozen && !moving}
  class:cursor-grabbing={!frozen && moving}
  style="
    z-index: -1; 
    transform-origin: top left; 
    transform: translate({x + dx}px, {y + dy}px);
  "
  use:pannable
  on:panstart={handlePanStart}
  on:panmove={handlePanMove}
  on:panend={handlePanEnd}
  bind:this={editable}
>
  <div 
    class="absolute" 
    style="
      left: {deleteBtnLeftStyle}; 
      color: white; 
      top: -10px;
      transform: translateX(-50%); 
    "
  >
    {#if isMode('esign_preorder', 'preorder', 'cna_preorder') && !associatedObjectId !== null || isMode('ipen')}
      <span 
        on:pointerup={handleDelete}
        class="delete-button flex justify-center items-center cursor-pointer bg-white rounded w-4 h-4"
        style="
          background-color: {colors[color].hex}; 
          top: 0.125rem; 
          right: 0.125rem; 
          border-radius:10px;
        "
      >
        <IconSmallX />
      </span>
    {/if}

    {#if associatedObjectId !== null}
      <span 
        class="absolute flex justify-center items-center bg-white rounded w-4 h-4"
        style="
          color: {colors[color].hex}; 
          top: 0.125rem; 
          right: 0.125rem; 
          border-radius: 10px; 
          transform:translateX(-50%); 
          left:50%;
        "
      >
        <IconSmallCheck />
      </span>
    {/if}
  </div>

  {#if type === 'date'}
    <div
      class="items-center border-2 rounded-b-md rounded-tr-md gap-1 overflow-hidden"
      style="
        background-color: rgba({colors[color].rgb}, 0.1); 
        border-color: rgb({colors[color].rgb}); 
        color: {colors[color].hex}
      "
    >
      <div
        class="flex justify-center items-center"
      >
        <select
          class="px-2 py-1 bg-transparent border-none outline-none"
          style="max-width: 112px;"
        >
          <option value="default">09/08/23</option>
          <option value="formatted">Sep. 8, 2023</option>
        </select>

        <button
          class="px-3 py-1 text-white ml-2"
          style="background-color: {color}"
        >
          <span>Place</span>
        </button>
      </div>
    </div>
  {:else}
    <div
      class="flex justify-center items-center border-2 rounded gap-1 resize-transition"
      style="
        background-color: rgba({colors[color].rgb}, 0.15); 
        border-color: rgba({colors[color].rgb}, 0.3); 
        color: {colors[color].hex};
        height: {height}px; 
        width: {width}px; 
      "
    >
      <div class="flex justify-center items-center">
        <span 
          bind:this={textElement} 
          class="text-center break-words"
          style="
            overflow: hidden;
            overflow-wrap: break-word; 
            white-space: normal; 
            word-break: break-word; 
          "
        >
          {#if type === 'signature'}
            {getFirstNameAndInitial(participant.full_name)} Sign Here
          {:else if type === 'initials'}
            {getFirstNameAndInitial(participant.full_name)} Initial Here
          {/if}
        </span>
      </div>

      {#if isPreorderMode || isMode('ipen')}
        <div 
          class="resize-handle" 
          on:mousedown|stopPropagation={handleResizeStart}
        ></div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .label-box {
    margin-left: -51px;
    padding: 2px 4px;
    position: absolute;
    width: 33px;
    z-index: 1;
  }

  .label-box::after {
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(245, 101, 101, 0.15);
    content: '';
    position: absolute;
    left: 33px;
    top: 50%;
    transform: translateY(-50%);
  }

  .resize-handle {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 10px;
    height: 10px;
    background-color: rgb(245, 101, 101);
    cursor: se-resize;
    border-radius: 50%;
  }

  .resize-transition {
    transition: width 0.1s ease, height 0.1s ease;
  }
</style>