<script>
  import {createEventDispatcher} from "svelte";

  export let style = 'width: 24px; height: 24px; flex-shrink: 0;';

  const dispatch = createEventDispatcher();

  function emitClick() {
    dispatch('click');
  }
</script>

<svg viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="1.5"
     stroke-linecap="round"
     stroke-linejoin="round"
     xmlns="http://www.w3.org/2000/svg"
     style={style}
     on:click={emitClick}>
  <path d="M16,21V4a1,1,0,0,0-1-1H7.5a1,1,0,0,0-1,1V8.29779"></path>
  <path d="M21,21V12.9a.9.9,0,0,0-.9-.9H16"></path>
  <path d="M11.00012,21,10.946,8.49557A1.5,1.5,0,0,0,8.7752,7.16026L4.10549,9.495a2,2,0,0,0-1.10561,1.78887V21"></path>
  <line x1="21.99999" y1="21" x2="1.99999" y2="21"></line>
  <line x1="5.99999" y1="14" x2="7.99999" y2="14"></line>
  <line x1="5.99999" y1="17" x2="7.99999" y2="17"></line>
</svg>