<script>
  import {onMount, onDestroy, createEventDispatcher, tick} from "svelte";
  
  export let isActive;
  export let isMobile;
  export let page;
  export let pageIndex;
  export let rotationDirection;
  export let rotationKey;
  
  const dispatch = createEventDispatcher();
  
  let canvas;
  let canvasClasses = 'page-canvas';
  if (isMobile) canvasClasses = 'page-canvas max-w-full';
  let width;
  let height;
  let renderIsComplete = true;
  let rotationAngle = 0;

  // -------------
  // - Functions -
  // -------------

  function measure() {
    if (!canvas) {
      return dispatch("measure", {
        scale: 1,
      });
    }

    dispatch("measure", {
      scale: canvas.clientWidth / width
    });
  }

  function onIsMobileChange() {
    if (isMobile) {
      canvasClasses = 'page-canvas max-w-full';
    } else {
      canvasClasses = 'page-canvas';
    }
  }

  function onRotationKeyChange() {
    if (rotationKey < 1) {
      return null;
    }

    let rotation = 90;

    if (rotationDirection !== 1) {
      rotation = -rotation;
    }

    rotationAngle += rotation;

    render({rotation: rotationAngle});
  }

  // * Need the 'renderIsComplete' flag to prevent a javascript hard-error (RA, Nov 2022)
  // * Running _page.getViewport({ scale: 1, rotation: 0 }) will cause pdfs with rotational metadata to be rotated in the browser, but not in the final product. This will also ensure that the draggable objects will be saved in the correct orientation in the saved pdf. (RA, Sept 2022)
  // * Running _page.getViewport({ scale: 1 }) will 'fix' the rotational pdfs to display unrotated in the browser, but the draggable objects will be rotated differently in the final pdf compared to how they look in the browser. (RA, Sept 2022)
  // * Running _page.getViewport({ scale: 1, rotation: rotation }) will allow you to dynamically rotate the pdf on button-click, but the draggable objects will be rotated differently in the final pdf compared to how they look in the browser. (RA, Sept 2022)
  // * You can use console.log(viewport.rotation) to measure the rotational metadata of the pdf. (RA, Sept 2022)
  // --
  async function render(inputs) {
    if (!renderIsComplete) { return null; }
    renderIsComplete = false;
    let rotation = 0;
    if (inputs && inputs.rotation) {rotation = inputs.rotation;}

    const _page = await page;
    const context = canvas.getContext("2d");

    const initialViewport = await _page.getViewport({ scale: 1 });
    const viewport = await _page.getViewport({ scale: 1, rotate: rotation });

    dispatch("onPageRotationMeasured", {
      pageIndex: pageIndex,
      rotation: viewport.rotation,
      initialRotation: initialViewport.rotation,
    });

    width = viewport.width;
    height = viewport.height;

    dispatch("onPageSizeMeasured", {
      pageIndex: pageIndex,
      width: width,
      height: height,
    });

    _page.render({
      canvasContext: context,
      viewport,
    });

    window.addEventListener("resize", measure);

    tick().then(() => {
      measure();
      renderIsComplete = true;
    });
  }
  
  // ---------------------------
  // - Lifecycle Event Binding -
  // ---------------------------

  onMount(render);
  
  onDestroy(() => {
    window.removeEventListener("resize", measure);
  });

  // ---------------------------------------------
  // - Watchers to react to changes in variables -
  // ---------------------------------------------

  $:isMobile, onIsMobileChange();
  $:rotationKey, onRotationKeyChange();

</script>

<div>
  <canvas
    bind:this={canvas}
    class={canvasClasses}
    class:shadow-outline={isActive}
    height={height}
    style="width: {width}px;"
    width={width}
  />
</div>
